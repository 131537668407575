import axiosInstance from './axiosInstance';

const dataViewPath = '/widget/dataview/drillDown';

export async function fetchDataView(reqQuery, reqBody, JWT) {
  try {
    if (process.env.NODE_ENV === 'development') {
      let id = new URL(`http://test?${reqQuery}`).searchParams.get('id');
      const response = await axiosInstance.get(
        id === '1'
          ? '/app/mock/dataviewdialog.json'
          : '/app/mock/dataviewuserdialog.json'
      );
      return {
        success: true,
        ...response
      };
    }
    const response = await axiosInstance.post(
      `${dataViewPath}?${reqQuery}`,
      reqBody,
      {
        headers: {
          authorization: JWT
        }
      }
    );
    return {
      success: true,
      ...response
    };
  } catch (err) {
    console.error('Error while calling data view drilldown', err);
    return {
      success: false
    };
  }
}

export async function fecthTheme() {
  try {
    window.AP.theming?.initializeTheming();
  } catch (err) {
    console.error('Error while fetching theme', err);
  }
}
