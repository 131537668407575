import React from 'react';
import { Spotlight } from '@atlaskit/onboarding';

export const renderActiveSpotlight = (onclick, Onboarding) => {
  //contains 2,3,4,5 cards for onboarding view
  const variants = [
    [],
    [],
    <Spotlight
      actions={[
        {
          onClick: () => onclick(3),
          text: 'Next'
        },
        {
          onClick: () => onclick(0),
          text: 'Skip'
        }
      ]}
      dialogPlacement="bottom left"
      target="green"
      key="green"
    >
      Get a high level overview with just a glance
    </Spotlight>,
    <Spotlight
      actions={[
        {
          onClick: () => onclick(4),
          text: 'Next'
        },
        {
          onClick: () => onclick(0),
          text: 'Skip'
        }
      ]}
      dialogPlacement="bottom center"
      target="yellow"
      key="yellow"
    >
      Quickly identify useful and stale content
    </Spotlight>,
    <Spotlight
      actions={[
        {
          onClick: () => onclick(5),
          text: 'Next'
        },
        {
          onClick: () => onclick(0),
          text: 'Skip'
        }
      ]}
      dialogPlacement="bottom right"
      target="red"
      key="red"
    >
      Know your audience and check out who is really engaged with your content
    </Spotlight>,
    <Spotlight
      actions={[
        {
          onClick: () => {
            onclick('drawer');
            onclick(6);
          },
          text: 'Open Drawer'
        },
        {
          onClick: () => onclick(0),
          text: 'Finish'
        }
      ]}
      dialogPlacement="bottom right"
      target="Help"
      key="Help"
    >
      Open's up help Drawer
    </Spotlight>
  ];
  return variants[Onboarding];
};
