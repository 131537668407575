import { atom } from 'recoil';

export const initialUserDetails = {};

const UserDetailsAtom = atom({
  default: initialUserDetails,
  key: 'USER_DETAILS_ATOM'
});

export default UserDetailsAtom;
