import React from 'react';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { Home } from '../Home';
import createHashSource from 'hash-source';
import { MainWrapper } from '../../Styles/wrappers';

const Settings = React.lazy(() => import('../../OtherPages/Settings/Settings'));
const PermissionPage = React.lazy(() =>
  import('../../OtherPages/PermissionPage/PermissionPage')
);
const source = createHashSource();
const history = createHistory(source);

const AppContent = () => {
  return (
    <MainWrapper>
      <div className="ac-content">
        <LocationProvider history={history}>
          <Router>
            <Home path="/" />
            <Settings path="/settings" />
            <PermissionPage path="/dashboard/settings" />
          </Router>
        </LocationProvider>
      </div>
    </MainWrapper>
  );
};

export default AppContent;
