/* ChartPlus version1.0.0 2019-10-03 */
import * as d3 from 'd3';
import './chartPlus.css';
const chartPlusFunction = (sampledata, divelement) => {
  console.log('sampledata', sampledata);
  /**
   * @fileOverview Core variables and global functions in chartPlus.
   */

  /**
   * The global chartPlus object
   * @namespace
   * @property {constructor}  chartPlus.chart - Constructor to create a chart instance.
   * @property {array}  chartPlus.charts - Array which contains chart object of all the current chartPlus instances.
   * @property {function}  chartPlus.globalSettings - Function to update the default configurations with custom options and returns the new configuration.
   */
  var chartPlus = {};
  /**
   * Array which contains chart object of all the current chartPlus instances
   * @type {array}
   */
  var charts = [],
    /** @constant */
    DIV = 'div',
    /** @constant */
    PX = 'px',
    /** @constant */
    SVG = 'svg',
    /** @constant */
    NAMESPACE = 'chartPlus-',
    /** @constant */
    FULL = '100%',
    /** @constant */
    TICKSIZE = 30,
    /** @constant */
    LABELSIZE = 16,
    /** @constant */
    LABELPADDING = 7,
    /** @constant */
    FALSE = false,
    /** @type {object} */
    DOCUMENT = document,
    /** @type {object} */
    defaultConfig,
    /** @type {number} */
    idCounter = 0,
    /** @constant */
    maxRectWidth = 25,
    /** @constant */
    minChartWidth = 150,
    /** @constant */
    rectPadding = 2,
    /** @type {function} */
    d3Max = d3.max,
    /** @type {function} */
    d3Min = d3.min,
    /** @type {function} */
    mathAbs = Math.abs,
    /** @type {function} */
    mathMax = Math.max,
    /** @type {function} */
    mathMin = Math.min,
    /** @type {function} */
    mathRound = Math.round,
    /** @type {function} */
    dateFormat = d3.timeFormat('%Y-%m-%d'),
    /** @type {function} */
    numFormat = d3.format('s'),
    /** @constant */
    NaNString = 'n/a',
    /** @type {object} */
    themeConfig;

  /* ------------------------------------------------------------ *
   *                         GLOBAL CONFIGURATIONS
   * ------------------------------------------------------------ */
  /**
   * Default Configuration Object
   * @type {object}
   */
  defaultConfig = {
    // colors: ["#1f77b4", "#766385", "#467d69", "#51605a", "#b79555", "#91c3c1", "#9d3b1b", "#58101a"],
    lang: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E']
    },
    sharedConfig: {},
    granularity: 'day',
    localTime: false,
    style: {
      fontFamily: 'arial',
      fontSize: '10px',
      fontWeight: '100'
    },
    invert: false,
    type: 'line',
    legend: {
      display: true
    },
    xAxis: {
      display: true,
      line: true,
      ticks: true,
      grid: false
    },
    yAxis: {
      display: true,
      line: true,
      ticks: true,
      grid: true
    },
    tooltip: {},
    export: {
      width: 700,
      height: 400
    }
  };
  /**
   * Theme Configuration Object
   * @type {object}
   */
  themeConfig = {
    colors: [
      '#1f77b4',
      '#766385',
      '#467d69',
      '#51605a',
      '#b79555',
      '#91c3c1',
      '#9d3b1b',
      '#58101a'
    ],
    backgroundColor: '#ffffff',
    text: {
      contrastLightColor: '#ffffff',
      contrastDarkColor: '#707070'
    },
    axis: {
      contrastLightColor: '#ffffff',
      contrastDarkColor: '#e9e9e9'
    },
    tooltip: {
      contrastLightColor: '#ffffff',
      contrastDarkColor: '#151515'
    }
  };

  /**
   * This function finds and returns the DOM element using the provided reference
   * @param {string} ref The selector to find the element
   * @param {string} type The selector. id or class
   * @returns {element} The HTML element with the given selector.
   * @throws {Error} It cannot find the element
   */
  function accessDOM(ref, type) {
    var element;
    if (type === 'id') {
      element = DOCUMENT.getElementById(ref);
    }
    if (type === 'class') {
      element = DOCUMENT.getElementsByClassName(ref);
    }
    if (isElement(element)) {
      return element;
    } else {
      error('cant find element', true);
    }
  }

  /**
   * Function to create element with attributes
   * @param {Object} parent element
   * @param {Object} tag HTML tag
   * @param {Object} attributes HTML attributes
   */
  function composeDOM(parent, tag, attr) {
    var args = arguments,
      element;
    element = DOCUMENT.createElement(isString(tag) ? tag : DIV);
    // Set attributes
    if (isObject(attr)) {
      extend(element, attr);
    }
    if (isElement(parent)) {
      parent.appendChild(element);
    }
  }
  /**
   * Remove last occurence of an item from an array
   * @param {Array} array Array fom which items should be removed
   * @param {Mixed} item Item which should be removed
   */
  function drop(array, item) {
    var i = array.length;
    while (i--) {
      if (array[i] === item) {
        array.splice(i, 1);
        break;
      }
    }
  }
  /**
   * Provide error messages
   * @param {number} code  Error Code
   * @param {boolean} stop  Stop flag.
   */
  function error(code, stop) {
    var msg = 'Error - ' + code;
    if (stop) {
      throw msg;
    } else if (window.console) {
      console.log(msg);
    }
  }
  /**
   * Extend method
   * @param {object} destination  Destination object
   * @param {object} source  Source object
   * @returns {object} Destination object
   */
  function extend(destination, source) {
    var n;
    if (!destination) {
      destination = {};
    }
    for (n in source) {
      destination[n] = source[n];
    }
    return destination;
  }

  /**
   * Get width of the given HTML element
   * @param {object} elem  HTML element object
   * @returns {number} Returns width
   */
  function getWidth(elem) {
    var size = parseFloat(
      window.getComputedStyle(elem).getPropertyValue('width')
    );
    return size;
  }
  /**
   * Get height of the given HTML element
   * @param {object} elem  HTML element object
   * @returns {number} Returns height
   */
  function getHeight(elem) {
    var size = parseFloat(
      window.getComputedStyle(elem).getPropertyValue('height')
    );
    return size;
  }
  /**
   * Function to get time in milliseconds for a given date
   * @param {Object} date  Date
   * @returns {number} Time in milliseconds
   */
  function getTime(date) {
    if (isDate(date)) {
      return parseDate(date).getTime();
    } else {
      return 0;
    }
  }
  /**
   * Update the default configurations with custom options and returns the new configuration.
   * @param {object} arg  Custom global configuration object
   * @returns {object} Configuration object
   */

  function globalConfig(arg) {
    if (!isObject(arg)) return defaultConfig;
    defaultConfig = merge(true, defaultConfig, arg);
    return defaultConfig;
  }
  /**
   * Update the default theme with custom options and returns the new configuration.
   * @param {object} arg  Custom global theme object
   * @returns {object} Configuration object
   */

  function globalTheme(arg) {
    if (!isObject(arg)) return themeConfig;
    themeConfig = merge(true, themeConfig, arg);
    return themeConfig;
  }
  /**
   * Update the default color array of chartPlus.
   * @param {array} arg  Custom colors
   */

  function setColors(arg) {
    if (!Array(arg)) return;
    defaultConfig.colors.splice(0, defaultConfig.colors.length);
    defaultConfig.colors.push.apply(defaultConfig.colors, arg);
  }
  /**
   * Check for array
   * @param {array} array array
   * @returns {boolean}
   */
  function isArray(array) {
    return Object.prototype.toString.call(array) === '[object Array]';
  }
  /**
   * Check for Date
   * @param {date} date Date
   * @returns {boolean}
   */
  function isDate(date) {
    return Object.prototype.toString.call(date) === '[object Date]';
  }
  /**
   * Check for DOM Element
   * @param {element} elem DOM Element
   * @returns {boolean}
   */
  function isElement(elem) {
    return typeof HTMLElement === 'object'
      ? elem instanceof HTMLElement //DOM2
      : elem &&
          typeof elem === 'object' &&
          elem !== null &&
          elem.nodeType === 1 &&
          typeof elem.nodeName === 'string';
  }
  /**
   * Check for function
   * @param {function} fn function
   * @returns {boolean}
   */
  function isFunction(fn) {
    return Object.prototype.toString.call(fn) === '[object Function]';
  }
  /**
   * Check for Integer
   * @param {number} n integer
   * @returns {boolean}
   */
  function isInt(n) {
    return isNumber(n) && n % 1 === 0;
  }
  /**
   * Check for DOM Node
   * @param {node} node DOM node
   * @returns {boolean}
   */
  function isNode(node) {
    return typeof Node === 'object'
      ? node instanceof Node
      : node &&
          typeof node === 'object' &&
          typeof node.nodeType === 'number' &&
          typeof node.nodeName === 'string';
  }
  /**
   * Check for number
   * @param {number} num number
   * @returns {boolean}
   */
  function isNumber(num) {
    return (
      !isNaN(num) && Object.prototype.toString.call(num) === '[object Number]'
    );
  }
  /**
   * Check for object
   * @param {object} obj object
   * @returns {boolean}
   */
  function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
  /**
   * Check for string
   * @param {string} str string
   * @returns {boolean}
   */
  function isString(str) {
    return Object.prototype.toString.call(str) === '[object String]';
  }
  /**
   * Check for IE browser
   * @returns {boolean}
   */
  function detectIE() {
    // check if user is using IE browser
    var versionIE;
    var ua = window.navigator.userAgent,
      msie = ua.indexOf('MSIE '), // IE 10 or older
      trident = ua.indexOf('Trident/'), // IE 11
      edge = ua.indexOf('Edge/'); // IE 12

    if (msie > 0) {
      versionIE = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    } else if (trident > 0) {
      var rv = ua.indexOf('rv:');
      versionIE = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    } else if (edge > 0) {
      versionIE = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    if (msie > 0 || trident > 0 || edge > 0) {
      console.info('You are using IE version ' + versionIE);
      return true;
    } else {
      // other browser
      return false;
    }
  }
  /**
   * Merge two object and return a third object
   * @function
   */
  function merge() {
    var i,
      args = arguments,
      result = {},
      makeCopy = function(copy, original) {
        var value, key;

        // An object is replacing a primitive
        if (typeof copy !== 'object') {
          copy = {};
        }

        for (key in original) {
          if (original.hasOwnProperty(key)) {
            value = original[key];

            // recursively call makeCopy if value is an object
            if (
              value &&
              typeof value === 'object' &&
              Object.prototype.toString.call(value) !== '[object Array]' &&
              typeof value.nodeType !== 'number'
            ) {
              copy[key] = makeCopy(copy[key] || {}, value);

              // Copy keys & values if value is not a object
            } else {
              copy[key] = original[key];
            }
          }
        }
        return copy;
      };

    // If first argument is true, copy into the existing object. Used in globalSettings.
    if (args[0] === true) {
      result = args[1];
      args = Array.prototype.slice.call(args, 2);
    }

    //copy each arguments to the result
    for (i = 0; i < args.length; i++) {
      result = makeCopy(result, args[i]);
    }

    return result;
  }

  /**
   * parse the string to a date.
   * @param {string} date Date string
   * @returns {date} Parsed date
   */
  function parseDate(date, format) {
    var d = new Date(date);
    return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    //return dateFormat.parse(date); // Issue in Atlassian Env.
  }
  /**
   * parse the number to a format.
   * @param {number} number Number
   * @returns {number} Formated number
   */

  function numberFormat(number) {
    if (!isNumber(number)) {
      return NaNString;
    } else {
      if (number < 1000) {
        return number;
      } else {
        return numFormat(number);
      }
    }
  }

  /**
   * Create array of numbers form start to end
   * @param {number} start starting number
   * @param {number} end ending number
   * @returns {array}
   */
  function range(start, end) {
    var list = [],
      i;
    if (start <= end) {
      for (i = start; i <= end; i++) {
        list.push(i);
      }
    } else {
      for (i = start; i >= end; i--) {
        list.push(i);
      }
    }
    return list;
  }

  /**
   * Clip string and add ellipsis to SVG text of it overflows maxWidth
   * @param {number} maxWidth maximum width for SVG text
   */
  function wrap(maxWidth, maxStep) {
    return function() {
      var text = d3.select(this),
        words = text
          .text()
          .split(/\s+/)
          .reverse(),
        word,
        tspan,
        textLength = text.node().getComputedTextLength(),
        step = maxStep,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy'));

      if (mathAbs(maxStep - maxWidth) < 5) {
        text.text(''); // Do away with text if there is not enough space
      } else {
        // wrap the text to fit.

        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em');

        /* jshint -W084 */
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > maxWidth - 5) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(word);
          }
        }
      }
    };
  }
  /**
   * Hide SVG text of it overflows maxWidth
   * @param {number} maxWidth maximum width for SVG text
   */
  function hide(maxWidth) {
    return function() {
      var self = d3.select(this),
        textLength = self.node().getComputedTextLength(),
        text = self.text();
      if (textLength > maxWidth) {
        self.text('');
      } else {
        self.text(text);
      }
    };
  }

  /**
   * Function to fing whether the contrast color is black or white
   * @param {string} color in hexcode
   * @returns {string} contrast color value
   */
  function getContrastYIQ(hexcode) {
    var hexcolor = hexcode.substr(1, hexcode.length - 1);
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'dark' : 'light';
  }

  /**
   * Function to get Transformations object (d3.transform missing in v4)
   * @param {string} transform string
   * @returns {object} properties for all transformation definitions
   */
  function getTransformation(transform) {
    if (!!transform) {
      // Create a dummy g for calculation purposes only. This will never
      // be appended to the DOM and will be discarded once this function
      // returns.

      var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

      // Set the transform attribute to the provided string value.
      g.setAttributeNS(null, 'transform', transform);

      // consolidate the SVGTransformList containing all transformations
      // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
      // its SVGMatrix.
      var matrix = g.transform.baseVal.consolidate().matrix;

      // Below calculations are taken and adapted from the private function
      // transform/decompose.js of D3's module d3-interpolate.
      //var {a, b, c, d, e, f} = matrix;   // ES6, if this doesn't work, use below assignment
      var a = matrix.a,
        b = matrix.b,
        c = matrix.c,
        d = matrix.d,
        e = matrix.e,
        f = matrix.f; // ES5
      // var scaleX, scaleY, skewX;
      // if (scaleX = Math.sqrt(a * a + b * b)) a /= scaleX, b /= scaleX;
      // if (skewX = a * c + b * d) c -= a * skewX, d -= b * skewX;
      // if (scaleY = Math.sqrt(c * c + d * d)) c /= scaleY, d /= scaleY, skewX /= scaleY;
      // if (a * d < b * c) a = -a, b = -b, skewX = -skewX, scaleX = -scaleX;
      return {
        translateX: e,
        translateY: f
        // rotate: Math.atan2(b, a) * 180 / Math.PI,
        // skewX: Math.atan(skewX) * 180 / Math.PI,
        // scaleX: scaleX,
        // scaleY: scaleY
      };
    } else {
      return {
        translateX: 0,
        translateY: 0
      };
    }
  }

  /**
   * Function to set default value if undefined
   * @param {object, default value} input with default specified
   * @returns {object} object
   */
  function setDefaultVal(value, defaultValue) {
    return value === undefined ? defaultValue : value;
  }

  function getRound(x, n) {
    return n ? Math.round(x * (n = Math.pow(10, n))) / n : Math.round(x);
  }

  chartPlus.isIE = detectIE();
  chartPlus.isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor);
  chartPlus.isFirefox =
    navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  // Exposing global variables

  extend(chartPlus, {
    chart: Chart,
    charts: charts,
    globalConfig: globalConfig,
    globalTheme: globalTheme,
    setColors: setColors,
    hierarchy: Hierarchy,
    sparkline: Sparkline,
    multiStack: MultiStack,
    table: Table
  });

  // Node/CommonJS - require D3
  if (
    typeof module !== 'undefined' &&
    typeof exports !== 'undefined' &&
    typeof d3 == 'undefined'
  ) {
    d3 = require('d3');
  }

  // Node/CommonJS exports
  if (typeof module !== 'undefined' && typeof exports !== 'undefined') {
    module.exports = chartPlus;
  }
  // The chartPlus namespace
  if (typeof window !== 'undefined') {
    window.chartPlus = chartPlus;
  }

  // (function() {
  //     function IEevent(event, params) {
  //         params = params || { bubbles: false, cancelable: false, detail: undefined };
  //         var evt = document.createEvent('CustomEvent');
  //         evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
  //         return evt;
  //     }

  //     CustomEvent.prototype = window.Event.prototype;

  //     window.IEevent = IEevent;
  // })();
  /**
   * A Constructor for creating, updating and removing X and Y axes.
   * @constructor
   */
  function Axis() {
    this.init.apply(this, arguments);
  }
  Axis.prototype = {
    /**
     * The default configuration for both X and Y axes.
     * @type {object}
     */
    defaultConfig: {
      // color: defaultConfig.style.color || '#707070',
      gridColor: '#e9e9e9',
      lineWidth: '1px',
      tickWidth: '1px',
      title: {
        padding: 3,
        style: {
          'text-anchor': 'middle',
          fontSize: '11px'
        }
      }
    },
    /**
     * Special default configurations for X axis.
     * @type {object}
     */
    xAxisConfig: {
      orient: 'bottom'
    },
    /**
     * Special default configurations for Y axis.
     * @type {object}
     */
    yAxisConfig: {
      orient: 'left',
      style: {
        // 'dominant-baseline': 'hanging'
      }
    },
    /**
     * Special default configurations if axis is aligned to top.
     * @type {object}
     */
    topConfig: {
      orient: 'top',
      title: {
        rotate: 0
      }
    },
    /**
     * Special default configurations if axis is aligned to bottom.
     * @type {object}
     */
    bottomConfig: {
      orient: 'bottom',
      title: {
        rotate: 0
      }
    },
    /**
     * Special default configurations if axis is aligned to right.
     * @type {object}
     */
    rightConfig: {
      orient: 'right',
      title: {
        rotate: 90
      }
    },
    /**
     * Special default configurations if axis is aligned to left.
     * @type {object}
     */
    leftConfig: {
      orient: 'left',
      title: {
        rotate: 270
      }
    },
    /**
     * Function to align axis to top or bottom or left or right
     */
    align: function() {
      var axis = this,
        x,
        y;
      if (axis.config.orient === 'top') {
        x = 0;
        y = d3Min(axis.chart.yRange);
        axis.path.attr('transform', 'translate(' + x + ',' + y + ')');
      } else if (axis.config.orient === 'bottom') {
        x = d3Min(axis.chart.xRange) + axis.path.node().getBBox().width / 2;
        y =
          d3Max(axis.chart.yRange) +
          axis.path.node().getBBox().height +
          axis.title.node().getBBox().height;
        if (axis.config.grid === true) {
          y = y - d3Max(axis.chart.yRange);
        }
        axis.title.attr('transform', 'translate(' + x + ',' + y + ')');
        x = 0;
        y = d3Max(axis.chart.yRange);
        axis.path.attr('transform', 'translate(' + x + ',' + y + ')');
      } else if (axis.config.orient === 'left') {
        x = axis.title.node().getBBox().width;
        y = d3Min(axis.chart.yRange) + axis.path.node().getBBox().height / 2;
        axis.title.attr('transform', 'translate(' + x + ',' + y + ')');
        x =
          axis.title.node().getBBox().width +
          axis.path.node().getBBox().width +
          (axis.title.node().getBBox().width > 0
            ? axis.config.title.padding
            : 0);
        if (axis.config.grid === true) {
          x = x + axis.d3Axis.tickSizeInner();
        }
        y = 0;
        axis.path.attr('transform', 'translate(' + x + ',' + y + ')');
      } else if (axis.config.orient === 'right') {
        x =
          mathAbs(axis.chart.xRange[0] - axis.chart.xRange[1]) -
          axis.path.node().getBBox().width;
        y = 0;
        axis.path.attr('transform', 'translate(' + x + ',' + y + ')');
      }
      axis.gElem.attr(
        'transform',
        'translate(' + (axis.config.x || 0) + ',' + (axis.config.y || 0) + ')'
      );
    },
    /**
     * Function to align ticks for ordinal scale axis
     */
    alignTicks: function() {
      var axis = this;
      if (!!axis.config.isXAxis) {
        // axis.path.selectAll('.tick').attr("transform", function(d, i) {
        //     return "translate(" + (axis.chart.xScale.bandwidth() + axis.chart.xScale.range()[i]) + "," + (0) + ")";
        // });
        axis.path
          .selectAll('text')
          //  .attr('x', -axis.chart.xScale.bandwidth() / 2)
          //   // .each(hide(axis.chart.xScale.bandwidth()));
          .each(wrap(axis.chart.xScale.bandwidth(), axis.chart.xScale.step()));
        //   .style("text-anchor", "end")
        //   .attr("dx", "-.8em")
        //   .attr("dy", ".15em")
        //   .attr("transform", "rotate(-65)");
      } else {
        axis.path.selectAll('text').attr('dy', '0.8em');
        axis.path.select('text').text('');
      }
    },
    /**
     * Function to destroy axis
     */
    destroy: function() {
      var axis = this,
        chart = axis.chart,
        key;

      if (axis.config.isXAxis === true) {
        delete chart.xAxis;
      } else {
        delete chart.yAxis;
      }

      axis.title.selectAll('*').remove();
      axis.title.node().parentNode.removeChild(axis.title.node());
      axis.path.selectAll('*').remove();
      axis.path.node().parentNode.removeChild(axis.path.node());
      axis.gElem.selectAll('*').remove();
      axis.gElem.node().parentNode.removeChild(axis.gElem.node());

      for (key in axis) {
        delete axis[key];
      }
    },
    /**
     * Function to hide axis
     */
    hide: function(config) {
      var axis = this;
      axis.path.selectAll('*').classed(axis.chart.cssPrefix + 'hidden', true);
      axis.title.selectAll('*').classed(axis.chart.cssPrefix + 'hidden', true);
    },
    /**
     * Function to initialise d3 and associated functions required for axis
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {boolean} render  Flag whether to do rendering after initialisation
     */
    init: function(chart, config, render) {
      var axis = this;
      axis.chart = chart;
      var formatS = d3.format('.3s');
      axis.nameSpace = config.isXAxis ? 'xAxis' : 'yAxis';
      axis.config = merge(
        axis.defaultConfig,
        axis[(config.isXAxis ? 'xAxis' : 'yAxis') + 'Config'],
        config
      );
      if (!!config.isXAxis) {
        axis.config.orient =
          config.orient || (!!chart.config.invert ? 'left' : 'bottom');

        axis.d3Axis = d3
          .axisBottom(axis.chart.xScale)
          .ticks(5)
          .tickSizeOuter(0);
      } else {
        axis.config.orient =
          config.orient || (!!chart.config.invert ? 'top' : 'left');

        axis.d3Axis = d3.axisLeft(axis.chart.yScale).ticks(5);
      }
      axis.config = merge(axis.config, axis[axis.config.orient + 'Config']);
      /* ----------------------------------- Title
       if (axis.config.type === 'date') {
            axis.config.title.text = 'Date';
        }
         ----------------------------------- */

      if (axis.config.ticks === true && axis.config.isXAxis === true) {
        if (axis.config.type === 'date') {
          if (axis.chart.config.granularity === 'month') {
            axis.d3Axis.tickFormat(d3.timeFormat('%b %Y'));
          } else if (axis.chart.config.granularity === 'week') {
            axis.d3Axis.tickFormat(d3.timeFormat('Week %V'));
          } else {
            // D3 automatically handles tick formatting for timeline
          }
        } else if (!!axis.chart.config.categoryMap) {
          axis.d3Axis.tickFormat(function(d) {
            return axis.chart.config.categoryMap[d];
          });
        }
      } else if (axis.config.ticks === true) {
        axis.d3Axis.tickValues(
          axis.chart.yScale.ticks().filter(function(d, i) {
            return i % 2 === 0 && d % 1 === 0;
          })
        );
        if (axis.chart.config.percent === true) {
          axis.d3Axis.tickFormat(function(d) {
            return d + '%';
          });
        } else {
          axis.d3Axis.tickFormat(function(d) {
            return formatS(d).replace(/\.0+$/, '');
          });
        }
      } else {
        axis.d3Axis.tickFormat('').tickSizeInner(0);
      }

      if (axis.config.grid === true) {
        axis.d3Axis.tickSizeInner(
          axis.config.isXAxis
            ? -Math.abs(axis.chart.vRange[0] - axis.chart.vRange[1])
            : -Math.abs(axis.chart.hRange[0] - axis.chart.hRange[1])
        );
      }

      axis.setCSS();
      if (render !== false) {
        axis.render();
      }
    },
    /**
     * Function to remove axis
     */
    remove: function() {
      var axis = this;
      axis.destroy();
    },
    /**
     * Function to render axis to the HTML
     */
    render: function() {
      var axis = this;
      axis.firstRender = false;
      axis.gElem = axis.chart.gElem
        .append('g')
        .attr('class', 'axis ' + axis.chart.cssPrefix + axis.nameSpace);

      axis.path = axis.gElem.append('g').call(this.d3Axis);

      axis.path
        .selectAll('path')
        .classed(axis.chart.cssPrefix + axis.nameSpace + '-line', true);
      axis.path
        .selectAll('line')
        .classed(axis.chart.cssPrefix + axis.nameSpace + '-tickLine', true);

      axis.title = axis.gElem
        .append('g')
        .attr('class', axis.chart.cssPrefix + axis.nameSpace + '-title');

      axis.title
        .append('text')
        .attr('transform', 'rotate(' + axis.config.title.rotate + ')')
        .text(axis.config.title.text || '');

      if (axis.config.type !== 'date') {
        axis.alignTicks();
      }
      axis.align();

      axis.path
        .on('mouseover', function() {
          if (
            d3.event.target.tagName == 'text' &&
            d3
              .select(this)
              .text()
              .indexOf('...') > -1
          ) {
            axis.chart.tooltip.show(d3.event.target.__data__);
          }
        })
        .on('mouseout', function(event) {
          axis.chart.tooltip.hide();
        });

      if (axis.config.isXAxis === true) {
        axis.offset =
          axis.path.node().getBBox().width -
          axis.path
            .select('path')
            .node()
            .getBBox().width;
      } else {
        axis.offset =
          axis.path.node().getBBox().height -
          axis.path
            .select('path')
            .node()
            .getBBox().height;
      }
    },
    /**
     * Function to resize axis
     */
    resize: function() {
      var axis = this,
        tickCount;
      axis.d3Axis.scale(
        axis.config.isXAxis ? axis.chart.xScale : axis.chart.yScale
      );

      if (axis.config.grid === true) {
        axis.d3Axis.tickSizeInner(
          axis.config.isXAxis
            ? -Math.abs(axis.chart.vRange[0] - axis.chart.vRange[1])
            : -Math.abs(axis.chart.hRange[0] - axis.chart.hRange[1])
        );
      }

      if (!axis.config.isXAxis) {
        axis.d3Axis.tickValues(
          axis.chart.yScale.ticks().filter(function(d, i) {
            return i % 2 === 0 && d % 1 === 0;
          })
        );
      } else if (
        axis.config.type === 'date' &&
        axis.chart.config.granularity === 'day'
      ) {
        tickCount = mathRound(
          mathAbs(axis.chart.xRange[0] - axis.chart.xRange[1]) / 100
        );
        tickCount = tickCount < 5 ? tickCount : 4;
        axis.d3Axis.ticks(tickCount % 2 === 0 ? tickCount : tickCount - 1);
      }
      axis.path.call(axis.d3Axis);
      axis.path
        .selectAll('path')
        .classed(axis.chart.cssPrefix + axis.nameSpace + '-line', true);
      axis.path
        .selectAll('line')
        .classed(axis.chart.cssPrefix + axis.nameSpace + '-tickLine', true);

      if (axis.config.type !== 'date') {
        axis.alignTicks();
      }
      axis.align();

      if (axis.config.isXAxis === true) {
        axis.offset =
          axis.path.node().getBBox().width -
          axis.path
            .select('path')
            .node()
            .getBBox().width;
      } else {
        axis.offset =
          axis.path.node().getBBox().height -
          axis.path
            .select('path')
            .node()
            .getBBox().height;
      }
    },
    /**
     * Function to create  and append css classes to style tag for the axis
     */
    setCSS: function() {
      var axis = this;
      var cssString = '',
        i;
      cssString =
        cssString + ' .' + axis.chart.cssPrefix + axis.nameSpace + ' {';
      for (i in axis.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          axis.config.style[i] +
          ';';
      }
      cssString = cssString + '}';

      // axis styles
      cssString =
        cssString + ' .' + axis.chart.cssPrefix + axis.nameSpace + '-line {';
      if (!axis.config.line) {
        cssString = cssString + 'stroke:none;';
      }
      cssString = cssString + 'fill:none;shape-rendering: crispEdges;';

      if (axis.config.lineDash) {
        cssString =
          cssString + 'stroke-dasharray:' + axis.config.lineDash + ';';
      }
      if (axis.config.lineWidth) {
        cssString = cssString + 'stroke-width:' + axis.config.lineWidth + ';';
      }
      cssString = cssString + '}';

      // tick line styles
      cssString =
        cssString +
        ' .' +
        axis.chart.cssPrefix +
        axis.nameSpace +
        '-tickLine {';

      cssString = cssString + 'fill:none;shape-rendering: crispEdges;';

      if (axis.config.tickDash) {
        cssString =
          cssString + 'stroke-dasharray:' + axis.config.tickDash + ';';
      }
      if (axis.config.tickWidth) {
        cssString = cssString + 'stroke-width:' + axis.config.tickWidth + ';';
      }
      cssString = cssString + '}';

      // axis title styles
      cssString =
        cssString + ' .' + axis.chart.cssPrefix + axis.nameSpace + '-title {';

      for (i in axis.config.title.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          axis.config.title.style[i] +
          ';';
      }

      cssString = cssString + '}';

      axis.chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to show axis
     */
    show: function() {
      var axis = this;
      axis.path.selectAll('*').classed(axis.chart.cssPrefix + 'hidden', false);
      axis.title.selectAll('*').classed(axis.chart.cssPrefix + 'hidden', false);
    }
  };
  /**
   * A Constructor for instantiating constructors required for each series based on type.
   * @constructor
   */
  function Series() {
    this.init.apply(this, arguments);
  }
  Series.prototype = {
    /**
     * Function to destroy series
     */
    destroy: function() {
      var series = this,
        chart = series.chart,
        key;

      if (isObject(series.component)) {
        series.component.remove();
      }

      drop(chart.series, series);
      drop(chart.config.series, series.config);

      for (key in series) {
        delete series[key];
      }
    },
    /**
     * Function to get the required constructors based in chart type pie
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     */
    getConstructor: function(type, config) {
      //chart.series[idx] = chart.getConstructor(series.type || chart.chartConfig.type, extend(series, { index: idx }), chart);
      var series = this;
      switch (type) {
        case 'area':
          return new Area(series.chart, config, series);
        case 'line':
          return new Line(series.chart, config, series);
        // break;
        case 'bar':
          return new Bar(series.chart, config, series);
        case 'waterfall':
          return new Bar(series.chart, config, series);
        // break;
        case 'pie':
          return new Pie(series.chart, config, series);
        // break;
        case 'sunburst':
          return new Sunburst(series.chart, config, series);
        // break;
        default:
          return new Line(series.chart, config, series);
      }
    },
    /**
     * Function to hide the series
     */
    hide: function(updateChart) {
      var series = this;
      if (isObject(series.chart.tooltip)) {
        series.chart.tooltip.hide(true);
      }
      series.config.isHidden = true;
      if (isObject(series.component)) {
        series.component.hide();
      }
      if (isObject(series.component) && updateChart !== false) {
        if (
          !!series.config.stack ||
          !!series.chart.config.stack ||
          series.config.type === 'bar'
        ) {
          series.chart.dirtyData = true;
        }
        series.chart.yAxis.dirty = true;
        series.chart.updateChart();
      }
    },
    /**
     * Function to initialise constructors and prepare data required for  series
     */
    init: function(chart, config) {
      var series = this;
      series.chart = chart;
      series.config = config;
      series.index = config.index;
      if (
        (isArray(config.data) && config.data.length > 0) ||
        isObject(config.data)
      ) {
        series.prepareData(config.data);
        series.setCSS();
        series.render();
        if (!!series.config.isHidden) {
          series.component.hide();
        }
      }
    },
    /**
     * Function to parse date for timeseries and set the data
     */
    prepareData: function(data) {
      var series = this;

      if (isArray(series.data)) {
        series.data.splice(0, series.data.length);
      } else {
        series.data = [];
      }
      if (isObject(data)) {
        series.data = merge({}, data);
      } else if (isArray(data) && data.length > 0) {
        if (
          series.chart.config.xAxis &&
          series.chart.config.xAxis.type === 'date'
        ) {
          if (isObject(data[0])) {
            series.data = data.map(function(d) {
              return { x: parseDate(d.x), y: d.y, dy: d.dy || 0 };
            });
          } else {
            error(
              'Timeline data with y values alone is not supported right now',
              true
            );
          }
        } else {
          // ordinal data should be provided in {x:,y:} format
          if (!isObject(data[0])) {
            error('ordinal data should be provided in {x:,y:} format', true);
          }
          series.data.push.apply(series.data, data);
        }
      }
    },
    /**
     * Function to redraw series
     */
    redraw: function() {
      var series = this;
      if (isObject(series.component)) {
        series.component.redraw();
      }
    },
    /**
     * Function to remove series
     */
    remove: function(updateChart) {
      var series = this,
        chart = series.chart;

      chart.dirtySeries = true;
      series.destroy();

      if (updateChart === true) {
        chart.updateChart();
      }
    },
    /**
     * Function to render series to HTML
     */
    render: function() {
      var series = this;
      if (
        isObject(series.config.data) ||
        (isArray(series.config.data) && series.config.data.length > 0)
      ) {
        series.component = series.getConstructor(
          series.config.type,
          series.config
        );
      }
    },
    /**
     * Function to resize series
     */
    resize: function() {
      var series = this;
      if (isObject(series.component)) {
        series.component.resize();
      }
    },
    /**
     * Function to create  and append css classes to style tag for the series
     */
    setCSS: function() {
      var series = this;
      var cssString = '';

      if (series.config.color) {
        cssString =
          cssString +
          ' .' +
          series.chart.cssPrefix +
          's' +
          series.index +
          '{stroke:' +
          series.config.color +
          '}' +
          ' .' +
          series.chart.cssPrefix +
          'f' +
          series.index +
          '{fill:' +
          series.config.color +
          '}';
      }
      series.chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to show series
     */
    show: function(updateChart) {
      var series = this;
      if (isObject(series.chart.tooltip)) {
        series.chart.tooltip.hide(true);
      }
      series.config.isHidden = false;
      if (isObject(series.component)) {
        series.component.show();
      }
      if (isObject(series.component) && updateChart !== false) {
        if (
          !!series.config.stack ||
          !!series.chart.config.stack ||
          series.config.type === 'bar'
        ) {
          series.chart.dirtyData = true;
        }
        series.chart.yAxis.dirty = true;
        series.chart.updateChart();
      }
    }
  };

  /**
   * A Constructor for instantiating constructors required for each indicator based on type.
   * @constructor
   */
  function Indicator() {
    this.init.apply(this, arguments);
  }
  Indicator.prototype = {
    defaultStyle: {
      line: {
        stroke: '#cccccc',
        'stroke-width': 2
      },
      band: {
        stroke: 'none',
        fill: '#cccccc'
      },
      general: {
        padding: 3
      }
    },
    /**
     * Function to destroy indicator
     */
    destroy: function() {
      var indicator = this,
        chart = indicator.chart,
        key;
      drop(chart.indicators, indicator);
      if (indicator.config.type === 'line') {
        indicator.component.remove();
      } else {
        indicator.gElem.selectAll('*').remove();
        indicator.gElem.node().parentNode.removeChild(indicator.gElem.node());
      }
      for (key in indicator) {
        delete indicator[key];
      }
    },
    /**
     * Function to initialise constructors and prepare data required for indicators
     */
    init: function(chart, config) {
      var indicator = this;

      indicator.chart = chart;
      indicator.config = config;

      if (
        indicator.config.type === 'xBand' ||
        indicator.config.type === 'yBand'
      ) {
        indicator.config.style = merge(
          indicator.defaultStyle.band,
          config.style
        );
      } else if (
        indicator.config.type === 'xLine' ||
        indicator.config.type === 'yLine' ||
        indicator.config.type === 'line'
      ) {
        indicator.config.style = merge(
          indicator.defaultStyle.line,
          config.style
        );
        if (indicator.config.type === 'line' && !indicator.config.color) {
          indicator.config.color = '#cccccc';
        }
      }

      indicator.index = config.index;

      indicator.setCSS();
      indicator.prepareData();
      indicator.render();
    },
    prepareData: function() {
      var indicator = this,
        value = 0;

      if (isArray(indicator.data) && indicator.config.type !== 'line') {
        indicator.data.splice(0, indicator.data.length);
      } else if (!isArray(indicator.data)) {
        indicator.data = [];
      }

      // .attr("d", function(d) { return "M" + d.join("L") + "Z"; });
      if (indicator.config.type === 'yLine') {
        // set the start and end coordinates' absolute value
        value = indicator.chart.yScale(indicator.config.data[0]);
        if (indicator.chart.config.invert !== true) {
          indicator.data.push([d3Min(indicator.chart.xRange), value]);
          indicator.data.push([d3Max(indicator.chart.xRange), value]);
        } else {
          indicator.data.push([value, d3Min(indicator.chart.xRange)]);
          indicator.data.push([value, d3Min(indicator.chart.xRange)]);
        }
      } else if (indicator.config.type === 'xLine') {
        // set the start and end coordinates' absolute value
        value = indicator.chart.xScale(parseDate(indicator.config.data[0]));
        if (indicator.chart.config.invert !== true) {
          indicator.data.push([value, d3Min(indicator.chart.yRange)]);
          indicator.data.push([value, d3Max(indicator.chart.yRange)]);
        } else {
          indicator.data.push([d3Min(indicator.chart.yRange), value]);
          indicator.data.push([d3Max(indicator.chart.yRange), value]);
        }
      } else if (indicator.config.type === 'yBand') {
        // set the absolute value of the 4 coordinates
        if (indicator.chart.config.invert !== true) {
          value = indicator.chart.yScale(indicator.config.data[0]);
          indicator.data.push([d3Min(indicator.chart.xRange), value]);
          indicator.data.push([d3Max(indicator.chart.xRange), value]);

          value = indicator.chart.yScale(indicator.config.data[1]);
          indicator.data.push([d3Max(indicator.chart.xRange), value]);
          indicator.data.push([d3Min(indicator.chart.xRange), value]);
        } else {
          value = indicator.chart.yScale(indicator.config.data[0]);
          indicator.data.push([value, d3Min(indicator.chart.xRange)]);
          indicator.data.push([value, d3Max(indicator.chart.xRange)]);

          value = indicator.chart.yScale(indicator.config.data[1]);
          indicator.data.push([value, d3Max(indicator.chart.xRange)]);
          indicator.data.push([value, d3Min(indicator.chart.xRange)]);
        }
      } else if (indicator.config.type === 'xBand') {
        // set the absolute value of the 4 coordinates
        if (indicator.chart.config.invert !== true) {
          value = indicator.chart.xScale(
            parseDate(indicator.config.data[0]).setHours(0, 0, 0, 0)
          );
          indicator.data.push([value, d3Min(indicator.chart.yRange)]);
          indicator.data.push([value, d3Max(indicator.chart.yRange)]);

          value = indicator.chart.xScale(
            parseDate(indicator.config.data[1]).setHours(23, 59, 59, 999)
          );
          indicator.data.push([value, d3Max(indicator.chart.yRange)]);
          indicator.data.push([value, d3Min(indicator.chart.yRange)]);
        } else {
          value = indicator.chart.xScale(
            parseDate(indicator.config.data[0]).setHours(0, 0, 0, 0)
          );
          indicator.data.push([d3Min(indicator.chart.yRange), value]);
          indicator.data.push([d3Max(indicator.chart.yRange), value]);

          value = indicator.chart.xScale(
            parseDate(indicator.config.data[1]).setHours(23, 59, 59, 999)
          );
          indicator.data.push([d3Max(indicator.chart.yRange), value]);
          indicator.data.push([d3Min(indicator.chart.yRange), value]);
        }
      } else if (
        indicator.config.type === 'line' &&
        indicator.data.length === 0
      ) {
        if (
          indicator.chart.config.xAxis &&
          indicator.chart.config.xAxis.type === 'date'
        ) {
          indicator.data = indicator.config.data.map(function(d) {
            return { x: parseDate(d.x), y: d.y, dy: d.dy || 0 };
          });
        } else {
          error(
            'Timeline data with y values alone is not supported right now',
            true
          );
        }
      }
    },
    /**
     * Function to remove indicator
     */
    remove: function() {
      var indicator = this;
      indicator.destroy();
    },
    /**
     * Function to render indicator
     */
    render: function() {
      var indicator = this,
        textSize;
      if (indicator.config.type === 'line') {
        indicator.component = new Line(
          indicator.chart,
          merge(indicator.config, { indicator: true }),
          indicator
        );
      } else {
        indicator.gElem = indicator.chart.gElem
          .append('g')
          .attr(
            'class',
            indicator.chart.cssPrefix + 'indicator-' + indicator.index
          );

        indicator.gElem.classed(indicator.chart.cssPrefix + 'clipChart', true);

        indicator.gElem.append('path').attr('d', function(d) {
          return 'M' + indicator.data.join('L') + 'Z';
        });
      }

      if (
        indicator.config.type === 'xLine' ||
        indicator.config.type === 'yLine'
      ) {
        indicator.gElem
          .append('text')
          .text(
            dateFormat(new Date()) === indicator.config.data[0]
              ? 'Today'
              : indicator.config.data[0]
          );

        textSize = indicator.gElem
          .select('text')
          .node()
          .getBBox();

        if (indicator.config.type === 'xLine') {
          indicator.gElem
            .select('text')
            .styles({ 'text-anchor': 'end', stroke: 'none' })
            .attr('transform', 'rotate(-90)')
            .attr('x', d3Min(indicator.chart.yRange));

          if (
            indicator.chart.xScale(parseDate(indicator.config.data[0])) -
              textSize.height -
              indicator.defaultStyle.general.padding <
            d3Min(indicator.chart.xRange)
          ) {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.xScale(parseDate(indicator.config.data[0])) +
                  textSize.height +
                  indicator.defaultStyle.general.padding
              );
          } else {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.xScale(parseDate(indicator.config.data[0])) -
                  indicator.defaultStyle.general.padding
              );
          }
        }

        if (indicator.config.type === 'yLine') {
          indicator.gElem
            .select('text')
            .styles({ 'text-anchor': 'end', stroke: 'none' })
            .attr('x', d3Max(indicator.chart.xRange));
          if (
            indicator.chart.yScale(indicator.config.data[0]) -
              textSize.height -
              indicator.defaultStyle.general.padding >
            d3Min(indicator.chart.yRange)
          ) {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.yScale(indicator.config.data[0]) -
                  indicator.defaultStyle.general.padding
              );
          } else {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.yScale(indicator.config.data[0]) +
                  textSize.height +
                  indicator.defaultStyle.general.padding
              );
          }
        }
      }
    },
    /**
     * Function to resize the indicator
     */
    resize: function() {
      var indicator = this,
        textSize;
      indicator.prepareData();

      if (indicator.config.type === 'line') {
        indicator.component.resize();
      } else {
        indicator.gElem.select('path').attr('d', function(d) {
          return 'M' + indicator.data.join('L') + 'Z';
        });
      }

      if (
        indicator.config.type === 'xLine' ||
        indicator.config.type === 'yLine'
      ) {
        textSize = indicator.gElem
          .select('text')
          .node()
          .getBBox();

        if (indicator.config.type === 'xLine') {
          indicator.gElem
            .select('text')
            .styles({ 'text-anchor': 'end', stroke: 'none' })
            .attr('transform', 'rotate(-90)')
            .attr('x', d3Min(indicator.chart.yRange));

          // if ((indicator.chart.xScale(parseDate(indicator.config.data[0])) + textSize.height + indicator.defaultStyle.general.padding) < d3Max(indicator.chart.xRange)) {
          if (
            indicator.chart.xScale(parseDate(indicator.config.data[0])) -
              textSize.height -
              indicator.defaultStyle.general.padding <
            d3Min(indicator.chart.xRange)
          ) {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.xScale(parseDate(indicator.config.data[0])) +
                  textSize.height +
                  indicator.defaultStyle.general.padding
              );
          } else {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.xScale(parseDate(indicator.config.data[0])) -
                  indicator.defaultStyle.general.padding
              );
          }
        }

        if (indicator.config.type === 'yLine') {
          indicator.gElem
            .select('text')
            .styles({ 'text-anchor': 'end', stroke: 'none' })
            .attr('x', d3Max(indicator.chart.xRange));
          if (
            indicator.chart.yScale(indicator.config.data[0]) -
              textSize.height -
              indicator.defaultStyle.general.padding >
            d3Min(indicator.chart.yRange)
          ) {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.yScale(indicator.config.data[0]) -
                  indicator.defaultStyle.general.padding
              );
          } else {
            indicator.gElem
              .select('text')
              .attr(
                'y',
                indicator.chart.yScale(indicator.config.data[0]) +
                  textSize.height +
                  indicator.defaultStyle.general.padding
              );
          }
        }
      }
    },
    /**
     * Function to create  and append css classes to style tag for the indicator
     */
    setCSS: function() {
      var indicator = this;
      var cssString = '';
      if (isObject(indicator.config.style)) {
        cssString =
          cssString +
          ' .' +
          indicator.chart.cssPrefix +
          'indicator-' +
          indicator.index +
          ' {';
        for (var key in indicator.config.style) {
          cssString = cssString + key + ':' + indicator.config.style[key] + ';';
        }
        cssString = cssString + '}';
      }
      indicator.chart.style
        .node()
        .appendChild(document.createTextNode(cssString));
    }
  };

  /**
   * A Constructor for creating, updating and removing area series.
   * @constructor
   */
  function Area() {
    this.init.apply(this, arguments);
  }
  Area.prototype = {
    /**
     * Default configurations for area.
     * @type {object}
     */
    defaultConfig: {
      style: {
        stroke: 'none',
        shapeRendering: 'auto'
      }
    },
    /**
     * Function to destroy area component
     */
    destroy: function() {
      var area = this,
        chart = area.chart,
        series = area.series,
        key;
      delete series.component;

      area.gElem.selectAll('*').remove();
      area.gElem.node().parentNode.removeChild(area.gElem.node());

      for (key in area) {
        delete area[key];
      }
    },
    /**
     * Function to draw path and path
     */
    drawSeries: function() {
      var area = this;

      area.gElem.selectAll('path').remove();

      area.gElem.datum(area.series.data);
      area.gElem.append('path').attr('d', function(d) {
        return area.d3area(d);
      });

      if (area.series.config.color) {
        area.gElem.select('path').style('fill', area.series.config.color);
      } else {
        area.gElem
          .select('path')
          .style('fill', area.chart.colorScale(area.series.config.name));
      }
    },
    /**
     * Function to hide area series
     */
    hide: function() {
      var area = this;
      area.gElem.classed(area.chart.cssPrefix + 'hidden', true);
    },
    /**
     * Function to initialise d3 and associated functions required for area series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     */
    init: function(chart, config, series) {
      var area = this;
      area.chart = chart;
      area.series = series;
      area.config = merge(area.config, this.defaultConfig);

      if (isObject(this.chart.config.sharedConfig.area)) {
        merge(true, area.config, this.chart.config.sharedConfig.area);
      }

      merge(true, area.config, config);

      area.d3area = d3
        .area()
        .x(function(d, i) {
          return area.chart.xScale(d.x);
        })
        .y0(function(d) {
          return area.chart.yScale(d.y + (d.dy || 0));
          // console.log(d);
          // return area.chart.yScale((d.dy > 0) ? (d.dy) : 0);
        })
        .y1(function(d) {
          return area.chart.yScale(d.dy > 0 ? d.dy : 0);
        });

      if (!!area.config.interpolation) {
        area.d3area.interpolate(area.config.interpolation);
      }

      area.setCSS();
      area.render();
    },
    /**
     * Function to redraw area
     */
    redraw: function() {
      var area = this;
      area.drawSeries();
    },
    /**
     * Function to remove area
     */
    remove: function() {
      var area = this;
      area.destroy();
    },
    /**
     * Function to render path to HTML
     */
    render: function() {
      var area = this;
      area.gElem = area.chart.gElem
        .append('g')
        .attr('class', area.chart.cssPrefix + 'series-' + area.series.index);

      area.gElem.classed(area.chart.cssPrefix + 'clipChart', true);

      area.drawSeries();
    },
    /**
     * Function to resize area
     */
    resize: function() {
      var area = this;
      area.gElem.selectAll('path').attr('d', function(d) {
        return area.d3area(d);
      });
    },
    /**
     * Function to create  and append css classes to style tag for the area series
     */
    setCSS: function() {
      var area = this;
      var cssString = '',
        i,
        j;
      cssString =
        cssString +
        ' .' +
        area.chart.cssPrefix +
        'series-' +
        area.series.index +
        ' {';
      for (i in area.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          area.config.style[i] +
          ';';
      }
      cssString = cssString + '}';

      area.chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to show area path
     */
    show: function() {
      var area = this;
      area.gElem.classed(area.chart.cssPrefix + 'hidden', false);
    }
  };

  /**
   * A Constructor for creating, updating and removing bar component in chart.
   * @constructor
   */
  function Bar() {
    this.init.apply(this, arguments);
  }
  Bar.prototype = {
    /**
     * The default configuration for bar.
     * @type {object}
     */
    defaultConfig: {
      style: {
        fillOpacity: 1
      }
    },
    /**
     * Function to destroy bar
     */
    destroy: function() {
      var bar = this,
        chart = bar.chart,
        series = bar.series,
        key;
      delete series.component;

      bar.gElem.selectAll('*').remove();
      bar.gElem.node().parentNode.removeChild(bar.gElem.node());

      for (key in bar) {
        delete bar[key];
      }
    },
    /**
     * Function to draw the bar series
     */
    drawSeries: function() {
      var bar = this,
        className;

      bar.gElem.selectAll('.chartPlus-bar').remove();
      if (!!bar.config.startDate && !!bar.config.endDate) {
        bar.gElem.datum(
          bar.series.data.filter(function(d) {
            return (
              d.x.getTime() >= bar.config.startDate.getTime() &&
              d.x.getTime() <= bar.config.endDate.getTime()
            );
          })
        );
      } else if (isArray(bar.config.categories)) {
        bar.gElem.datum(
          bar.series.data.filter(function(d) {
            return bar.config.categories.indexOf(d.x) > -1;
          })
        );
      } else {
        bar.gElem.datum(bar.series.data);
      }
      bar.gElem
        .selectAll('.bar')
        .data(bar.gElem.datum())
        .enter()
        .append('rect')
        .attr('class', 'chartPlus-bar')
        .attr('x', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.yScale(d.dy || 0)
            : bar.chart.xScale(d.x) +
                (bar.series.config.group || 0) * bar.chart.rectWidth;
        })
        .attr('y', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.xScale(d.x) +
                (bar.series.config.group || 0) * bar.chart.rectWidth
            : bar.chart.yScale(d.y + (d.dy || 0));
        })
        .attr('width', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.yScale(d.y)
            : bar.chart.rectWidth;
        })
        .attr('height', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.rectWidth
            : mathMax(bar.chart.yRange[0], bar.chart.yRange[1]) -
                bar.chart.yScale(d.y);
        });

      if (bar.series.config.color) {
        bar.gElem.selectAll('rect').style('fill', bar.series.config.color);
      } else {
        bar.gElem
          .selectAll('rect')
          .style('fill', bar.chart.colorScale(bar.series.config.name));
      }
    },
    /**
     * Function to hide bar
     */
    hide: function() {
      var bar = this;
      bar.gElem.classed(bar.chart.cssPrefix + 'hidden', true);
    },
    /**
     * Function to initialise d3 and associated functions required for bar series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {Object} series  Parent series object
     */
    init: function(chart, config, series) {
      var bar = this;
      bar.chart = chart;
      bar.series = series;
      bar.config = merge(bar.config, this.defaultConfig);

      if (isObject(this.chart.config.sharedConfig.bar)) {
        merge(true, bar.config, this.chart.config.sharedConfig.bar);
      }

      merge(true, bar.config, config);
      if (!!bar.config.startDate) {
        bar.config.startDate = parseDate(bar.config.startDate);
      }
      if (!!bar.config.endDate) {
        bar.config.endDate = parseDate(
          !!bar.config.endDate
            ? bar.config.endDate
            : bar.config.data[bar.config.data.length - 1].x
        );
      }
      bar.setCSS();
      bar.render();
    },
    /**
     * Function to remove existing rect elements and redraw bars
     */
    redraw: function() {
      var bar = this;
      bar.drawSeries();
    },
    /**
     * Function to remove bar
     */
    remove: function() {
      var bar = this;
      bar.destroy();
    },
    /**
     * Function to render bar
     */
    render: function(container) {
      var bar = this;
      bar.gElem = bar.chart.gElem
        .append('g')
        .attr('class', NAMESPACE + 'series-' + bar.series.index);

      if (bar.config.tooltip !== false) {
        if (
          !!bar.chart.config.tooltip.actions &&
          bar.chart.config.tooltip.actions.length > 0
        ) {
          bar.gElem.style('cursor', 'pointer');
        }
        bar.gElem
          .on('mouseover', function() {
            if (d3.event.target.tagName == 'rect') {
              bar.chart.tooltip.show(
                d3.event.target.__data__,
                bar.series.config.name || 'Series ' + bar.series.config.index
              );
            }
          })
          .on('click', function() {
            if (d3.event.target.tagName == 'rect') {
              bar.chart.tooltip.show(
                d3.event.target.__data__,
                bar.series.config.name || 'Series ' + bar.series.config.index,
                true
              );
            }
          })
          .on('mouseout', function(event) {
            bar.chart.tooltip.hide();
          });
      }
      bar.gElem.classed(bar.chart.cssPrefix + 'clipChart', true);

      if (isFunction(bar.chart.xScale.bandwidth)) {
        bar.gElem.attr(
          'transform',
          'translate(' +
            ((bar.chart.xScale.bandwidth() - bar.chart.rectWidth) / 2 || 0) +
            ',' +
            0 +
            ')'
        );
      }
      bar.drawSeries();
    },
    /**
     * Function to resize bar
     */
    resize: function() {
      var bar = this;
      if (isFunction(bar.chart.xScale.bandwidth)) {
        bar.gElem.attr(
          'transform',
          'translate(' +
            ((bar.chart.xScale.bandwidth() - bar.chart.rectWidth) / 2 || 0) +
            ',' +
            0 +
            ')'
        );
      }
      bar.gElem
        .selectAll('rect')
        .attr('x', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.yScale(d.dy || 0)
            : bar.chart.xScale(d.x) +
                (bar.series.config.group || 0) * bar.chart.rectWidth;
        })
        .attr('y', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.xScale(d.x) +
                (bar.series.config.group || 0) * bar.chart.rectWidth
            : bar.chart.yScale(d.y + (d.dy || 0));
        })
        .attr('width', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.yScale(d.y)
            : bar.chart.rectWidth;
        })
        .attr('height', function(d, i) {
          return bar.config.invert === true
            ? bar.chart.rectWidth
            : mathMax(bar.chart.yRange[0], bar.chart.yRange[1]) -
                bar.chart.yScale(d.y);
        });
    },
    /**
     * Function to create  and append css classes to style tag for the bars
     */
    setCSS: function() {
      var bar = this;
      var cssString = '',
        i;

      cssString =
        cssString +
        ' .' +
        bar.chart.cssPrefix +
        'series-' +
        bar.series.index +
        ' {';
      for (i in bar.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          bar.config.style[i] +
          ';';
      }
      cssString = cssString + '}';
      bar.chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to show bar
     */
    show: function(updateChart) {
      var bar = this;
      bar.gElem.classed(bar.chart.cssPrefix + 'hidden', false);
    }
  };

  /**
   * A Constructor for creating, updating and removing time range selection slider.
   * @constructor
   */
  function Brush() {
    this.init.apply(this, arguments);
  }
  Brush.prototype = {
    /**
     * The default configuration for brush.
     * @type {object}
     */
    defaultConfig: {
      height: 20,
      padding: 3,
      color: '#1f77b4'
      // color: '#cccccc',
      // style: {
      // 'fillOpacity': 0.5
      // }
    },
    /**
     * Function to destroy brush
     */
    destroy: function() {
      var brush = this,
        chart = brush.chart,
        key;
      brush.gElem.selectAll('*').remove();
      brush.gElem.node().parentNode.removeChild(brush.gElem.node());

      for (key in brush) {
        delete brush[key];
      }
    },
    resize: function(x, y) {
      var brush = this;
      brush.gElem.attr('transform', 'translate(0,' + (!!y ? y : 0) + ')');
      brush.gElem
        .select('rect')
        .attr('x', brush.chart.brushScale.range()[0])
        .attr(
          'width',
          brush.chart.brushScale.range()[1] - brush.chart.brushScale.range()[0]
        );

      brush.container
        .select('.totalData')
        .select('path')
        .attr('d', function(d) {
          return brush.d3Area(d);
        });
      brush.container
        .select('.axis')
        .attr('y1', brush.config.height)
        .attr('x1', brush.chart.brushScale.range()[0])
        .attr('y2', brush.config.height)
        .attr('x2', brush.chart.brushScale.range()[1]);

      brush.container
        .select('.leftRect')
        .attr('x', brush.chart.brushScale(brush.chart.brushScale.domain()[0]))
        .attr(
          'width',
          brush.chart.brushScale(brush.chart.brushDomain[0]) -
            brush.chart.brushScale(brush.chart.brushScale.domain()[0])
        );

      brush.container
        .select('.rightRect')
        .attr('x', brush.chart.brushScale(brush.chart.brushDomain[1]))
        .attr(
          'width',
          brush.chart.brushScale(brush.chart.brushScale.domain()[1]) -
            brush.chart.brushScale(brush.chart.brushDomain[1])
        );

      brush.container
        .select('.d3Brush')
        .call(brush.d3Brush)
        .selectAll('rect')
        .attr('y', 0)
        .style('fill-opacity', 0)
        .attr('height', brush.config.height);

      // Set the selection
      brush.container
        .select('.d3Brush')
        .call(
          brush.d3Brush.move,
          [brush.chart.brushDomain[0], brush.chart.brushDomain[1]].map(
            brush.chart.brushScale
          )
        );

      brush.container
        .select('.handle.handle--e')
        // .select('rect')
        .attr('y', 3)
        .attr('height', brush.config.height - 6)
        .style('fill', '#34a7df')
        .style('fill-opacity', 1)
        .style('visibility', 'visible');

      brush.container
        .select('.handle.handle--w')
        // .select('rect')
        .attr('y', 3)
        .attr('height', brush.config.height - 6)
        .style('fill', '#34a7df')
        .style('fill-opacity', 1)
        .style('visibility', 'visible');
    },
    /**
     * Function to initialise Brush
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {boolean} render  Flag whether to do rendering after initialisation
     */
    init: function(chart, config, render) {
      var brush = this;
      brush.firstRender = true;
      brush.chart = chart;
      brush.config = merge(this.defaultConfig, config);
      brush.data = [];
      var tempData = {},
        yMax = 0;

      chart.config.series.forEach(function(series) {
        series.data.forEach(function(d, i) {
          if (!!tempData[d.x]) {
            tempData[d.x] = tempData[d.x] + d.y;
          } else {
            tempData[d.x] = d.y;
          }
        });
      });
      chart.innerLegends.forEach(function(key) {
        if (isNumber(tempData[key])) {
          if (yMax < tempData[key]) {
            yMax = tempData[key];
          }
          brush.data.push({ x: parseDate(key), y: tempData[key] });
        } else {
          brush.data.push({ x: parseDate(key), y: 0 });
        }
      });

      brush.d3Brush = d3
        .brushX()
        .extent([
          [brush.chart.brushScale.range()[0], 0],
          [brush.chart.brushScale.range()[1], brush.config.height]
        ])
        .on('brush', function() {
          brush.chart.updateOnBrush();
          brush.container
            .select('.leftRect')
            .attr(
              'x',
              brush.chart.brushScale(brush.chart.brushScale.domain()[0])
            )
            .attr(
              'width',
              brush.chart.brushScale(brush.chart.brushDomain[0]) -
                brush.chart.brushScale(brush.chart.brushScale.domain()[0])
            );

          brush.container
            .select('.rightRect')
            .attr('x', brush.chart.brushScale(brush.chart.brushDomain[1]))
            .attr(
              'width',
              brush.chart.brushScale(brush.chart.brushScale.domain()[1]) -
                brush.chart.brushScale(brush.chart.brushDomain[1])
            );

          return;
        });

      brush.yScale = d3
        .scaleLinear()
        .domain([0, yMax])
        .range([brush.config.height, 0]);
      brush.d3Area = d3
        .area()
        .x(function(d, i) {
          return brush.chart.brushScale(d.x);
        })
        .y(brush.config.height)
        .y1(function(d) {
          return brush.yScale(d.y);
        });
      brush.setCSS();
      if (render !== false) {
        brush.render();
      }
    },
    /**
     * Function to append brush to the HTML
     */
    render: function() {
      var brush = this;
      brush.firstRender = false;
      brush.gElem = brush.chart.gElem
        .append('g')
        .attr('class', brush.chart.cssPrefix + 'brush');

      brush.gElem
        .append('rect')
        .attr('fill', 'none')
        .attr(
          'width',
          brush.chart.brushScale.range()[1] - brush.chart.brushScale.range()[0]
        )
        .attr('height', brush.config.height + brush.config.padding * 2)
        .attr('x', brush.chart.brushScale.range()[0])
        .attr('y', 0);

      brush.firstRender = false;

      brush.container = brush.gElem
        .append('g')
        .attr('transform', 'translate(0,' + brush.config.padding + ')');

      brush.container
        .append('line')
        .attr('class', 'axis')
        .style('stroke', '#c7c7c7')
        .style('fill', 'none')
        .style('shape-rendering', 'crispEdges')

        .attr('y1', brush.config.height)
        .attr('x1', brush.chart.brushScale.range()[0])
        .attr('y2', brush.config.height)
        .attr('x2', brush.chart.brushScale.range()[1]);

      brush.container
        .append('g')
        .attr('class', 'totalData')
        .style('fill', brush.config.color)
        .style('fill-opacity', 0.5)
        .style('stroke', 'none')
        .datum(brush.data)
        .append('path')
        .attr('d', function(d) {
          return brush.d3Area(d);
        });

      brush.container
        .append('rect')
        .attr('class', 'leftRect')
        .style('fill', '#cccccc')
        .style('fill-opacity', 0.5)
        .attr('x', brush.chart.brushScale(brush.chart.brushScale.domain()[0]))
        .attr('y', 0)
        .attr(
          'width',
          brush.chart.brushScale(brush.chart.brushDomain[0]) -
            brush.chart.brushScale(brush.chart.brushScale.domain()[0])
        )
        .attr('height', brush.config.height);
      brush.container
        .append('rect')
        .attr('class', 'rightRect')
        .style('fill', '#cccccc')
        .style('fill-opacity', 0.5)
        .attr('x', brush.chart.brushScale(brush.chart.brushDomain[1]))
        .attr('y', 0)
        .attr(
          'width',
          brush.chart.brushScale(brush.chart.brushScale.domain()[1]) -
            brush.chart.brushScale(brush.chart.brushDomain[1])
        )
        .attr('height', brush.config.height);

      brush.container
        .append('g')
        .attr('class', 'd3Brush')
        .call(brush.d3Brush)
        .selectAll('rect')
        .attr('y', 0)
        .style('fill-opacity', 0)
        .attr('height', brush.config.height);

      // Set the selection
      brush.container
        .select('.d3Brush')
        .call(
          brush.d3Brush.move,
          [brush.chart.brushDomain[0], brush.chart.brushDomain[1]].map(
            brush.chart.brushScale
          )
        );

      brush.container
        .select('.handle.handle--e')
        // .select('rect')
        .attr('y', 3)
        .attr('height', brush.config.height - 6)
        .style('fill', '#34a7df')
        .style('fill-opacity', 1)
        .style('visibility', 'visible');

      brush.container
        .select('.handle.handle--w')
        // .select('rect')
        .attr('y', 3)
        .attr('height', brush.config.height - 6)
        .style('fill', '#34a7df')
        .style('fill-opacity', 1)
        .style('visibility', 'visible');
    },
    /**
     * Function to remove brush
     */
    remove: function() {
      var brush = this;
      brush.destroy();
    },
    /**
     * Function to create  and append css classes to style tag for the brush
     */
    setCSS: function() {
      var brush = this;
      var cssString = '',
        i;

      // cssString = cssString + ' .' + brush.chart.cssPrefix + 'brush {';
      // for (i in brush.config.style) {
      //     cssString = cssString + i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() + ':' + brush.config.style[i] + ';';
      // }
      // if (brush.config.color) {
      //     cssString = cssString + 'fill:' + brush.config.color + ';';
      // }
      // cssString = cssString + '}';
      // brush.chart.style.node().appendChild(document.createTextNode(cssString));
    }
  };

  /**
   * A Constructor for creating, updating and removing line series.
   * @constructor
   */
  function Line() {
    this.init.apply(this, arguments);
  }
  Line.prototype = {
    /**
     * Default configurations for line.
     * @type {object}
     */
    defaultConfig: {
      marker: {
        radius: 3,
        display: true,
        symbol: 'circle',
        type: 'small' // small/large/highlight
      },
      style: {
        fill: 'none',
        strokeWidth: '2px',
        shapeRendering: 'auto'
      }
    },

    /**
     * Special default configurations for points on line.
     * @type {object}
     */
    markerCSS: {
      small: {
        strokeWidth: '2px',
        fill: '#ffffff',
        hover: {
          cursor: 'pointer',
          strokeWidth: '7px',
          strokeOpacity: 0.3
        }
      },
      highlight: {
        strokeWidth: '2px',
        fill: '#ffffff',
        hover: {
          strokeWidth: '4px'
        }
      }
    },
    /**
     * Function to destroy line component
     */
    destroy: function() {
      var line = this,
        chart = line.chart,
        parent = line.parent,
        key;
      delete parent.component;

      line.gElem.selectAll('*').remove();
      line.gElem.node().parentNode.removeChild(line.gElem.node());

      for (key in line) {
        delete line[key];
      }
    },
    /**
     * Function to draw path and path
     */
    drawSeries: function() {
      var line = this;

      line.gElem.selectAll('path').remove();
      line.gElem.selectAll('circle').remove();

      line.gElem.datum(line.parent.data);

      line.gElem.append('path').attr('d', function(d) {
        return line.d3Line(d);
      });

      if (line.parent.config.color) {
        line.gElem.select('path').style('stroke', line.parent.config.color);
      } else {
        line.gElem
          .select('path')
          .style('stroke', line.chart.colorScale(line.parent.config.name));
      }

      if (line.config.marker.display !== false) {
        line.gElem
          .selectAll('.marker')
          .data(
            line.gElem.datum().filter(function(d) {
              return d.y !== 0;
            })
          )
          .enter()
          .append('circle')
          .attr(
            'class',
            line.chart.cssPrefix + 'marker ' + line.config.marker.type
          )
          .attr('cx', function(d, i) {
            return line.config.invert === true
              ? line.chart.yScale(d.y)
              : line.chart.xScale(d.x);
          })
          .attr('cy', function(d) {
            return line.config.invert === true
              ? line.chart.xScale(d.x)
              : line.chart.yScale(d.y);
          })
          .attr('r', line.config.marker.radius);

        if (line.parent.config.color) {
          line.gElem
            .selectAll('circle')
            .style('stroke', line.parent.config.color);
        } else {
          line.gElem
            .selectAll('circle')
            .style('stroke', line.chart.colorScale(line.parent.config.name));
        }
      }
    },
    /**
     * Function to hide line series
     */
    hide: function() {
      var line = this;
      line.gElem.classed(line.chart.cssPrefix + 'hidden', true);
    },
    /**
     * Function to initialise d3 and associated functions required for line series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {Object} parent  Parent  object. it can be a series or indicator
     */
    init: function(chart, config, parent) {
      var line = this;
      line.chart = chart;
      line.parent = parent;
      line.config = merge(line.config, this.defaultConfig);

      if (isObject(this.chart.config.sharedConfig.line)) {
        merge(true, line.config, this.chart.config.sharedConfig.line);
      }
      merge(true, line.config, config);

      line.d3Line = d3
        .line()
        .x(function(d, i) {
          return line.chart.config.invert === true
            ? line.chart.yScale(d.y)
            : line.chart.xScale(d.x);
        })
        .y(function(d) {
          return line.chart.config.invert === true
            ? line.chart.xScale(d.x)
            : line.chart.yScale(d.y);
        });
      if (!!line.config.interpolation) {
        line.d3Line.curve(d3.curveStepAfter);
      }

      line.setCSS();
      line.render();
    },
    /**
     * Function to redraw line
     */
    redraw: function() {
      var line = this;
      line.drawSeries();
    },
    /**
     * Function to remove line
     */
    remove: function() {
      var line = this;
      line.destroy();
    },
    /**
     * Function to render path to HTML
     */
    render: function() {
      var line = this;
      line.gElem = line.chart.gElem
        .append('g')
        .attr(
          'class',
          line.chart.cssPrefix +
            (line.config.indicator === true ? 'indicator-' : 'series-') +
            line.parent.index
        );

      if (line.config.tooltip !== false) {
        if (
          !!line.chart.config.tooltip.actions &&
          line.chart.config.tooltip.actions.length > 0
        ) {
          line.gElem.classed('cursorPointer', true);
        }
        line.gElem
          .on('mouseover', function() {
            if (d3.event.target.tagName == 'circle') {
              line.chart.tooltip.show(
                d3.event.target.__data__,
                line.parent.config.name ||
                  (line.config.indicator === true ? 'Indicator ' : 'Series ') +
                    line.parent.config.index
              );
            }
          })
          .on('mouseout', function(event) {
            line.chart.tooltip.hide();
          })
          .on('click', function(event) {
            if (d3.event.target.tagName == 'circle') {
              line.chart.tooltip.show(
                d3.event.target.__data__,
                line.parent.config.name ||
                  (line.config.indicator === true ? 'Indicator ' : 'Series ') +
                    line.parent.config.index,
                true
              );
            }
          });
      }
      line.gElem.classed(line.chart.cssPrefix + 'clipChart', true);

      line.drawSeries();
    },
    /**
     * Function to resize line
     */
    resize: function() {
      var line = this;
      line.gElem.selectAll('path').attr('d', function(d) {
        return line.d3Line(d);
      });

      if (line.config.marker.display !== false) {
        line.gElem
          .selectAll('.' + line.chart.cssPrefix + 'marker')
          .attr('cx', function(d, i) {
            return line.config.invert === true
              ? line.chart.yScale(d.y)
              : line.chart.xScale(d.x);
          })
          .attr('cy', function(d) {
            return line.config.invert === true
              ? line.chart.xScale(d.x)
              : line.chart.yScale(d.y);
          });
      }
    },
    /**
     * Function to create  and append css classes to style tag for the line series
     */
    setCSS: function() {
      var line = this;
      var cssString = '',
        i,
        j,
        parentName;

      parentName = line.config.indicator === true ? 'indicator-' : 'series-';

      cssString =
        cssString +
        ' .' +
        line.chart.cssPrefix +
        parentName +
        line.parent.index +
        ' {';
      for (i in line.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          line.config.style[i] +
          ';';
      }
      cssString = cssString + '}';

      // marker CSS
      cssString =
        cssString +
        ' .' +
        line.chart.cssPrefix +
        parentName +
        line.parent.index +
        ' .' +
        line.chart.cssPrefix +
        'marker' +
        '.' +
        line.config.marker.type +
        ' {';
      for (i in line.markerCSS[line.config.marker.type]) {
        if (!isObject(line.markerCSS[line.config.marker.type][i])) {
          cssString =
            cssString +
            i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
            ':' +
            line.markerCSS[line.config.marker.type][i] +
            ';';
        }
      }
      for (i in line.config.marker.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          line.config.marker.style[i] +
          ';';
      }
      cssString = cssString + '}';

      // hover styles for marker
      for (i in line.markerCSS[line.config.marker.type]) {
        if (isObject(line.markerCSS[line.config.marker.type][i])) {
          cssString =
            cssString +
            ' .' +
            line.chart.cssPrefix +
            parentName +
            line.parent.index +
            ' .' +
            line.chart.cssPrefix +
            'marker' +
            '.' +
            line.config.marker.type +
            ':' +
            i +
            ' {';
          for (j in line.markerCSS[line.config.marker.type][i]) {
            cssString =
              cssString +
              j.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
              ':' +
              line.markerCSS[line.config.marker.type][i][j] +
              ';';
          }
          cssString = cssString + '}';
        }
      }

      line.chart.style.node().appendChild(document.createTextNode(cssString));

      //markerCSS
    },
    /**
     * Function to show line path
     */
    show: function() {
      var line = this;
      line.gElem.classed(line.chart.cssPrefix + 'hidden', false);
    }
  };

  /**
   * A Constructor for creating, updating and removing pie component in chart.
   * @constructor
   */
  function Pie() {
    this.init.apply(this, arguments);
  }
  Pie.prototype = {
    /**
     * The default configuration for pie.
     * @type {object}
     */
    defaultConfig: {
      colorByPoint: true,
      style: {
        stroke: '#ffffff',
        strokeWidth: '0.5px',
        fillOpacity: 1,
        hover: {
          fillOpacity: 0.8
        }
      }
    },
    /**
     * Function to destroy pie
     */
    destroy: function() {
      var pie = this,
        chart = pie.chart,
        series = pie.series,
        key;
      delete series.component;

      pie.gElem.selectAll('*').remove();
      pie.gElem.node().parentNode.removeChild(pie.gElem.node());

      for (key in pie) {
        delete pie[key];
      }
    },
    /**
     * Function to draw the pie series
     */
    drawSeries: function() {
      var pie = this,
        className = '';
      pie.gElem.selectAll('path').remove();
      pie.gElem.datum(pie.series.data);

      pie.arcs = pie.gElem
        .selectAll('.arc')
        .data(pie.layout(pie.gElem.datum()))
        .enter()
        .append('path')
        .attr('class', 'arc')
        .style('fill', function(d, i) {
          return pie.chart.colorScale(d.data.x);
        })
        .attr('d', pie.arc);
    },
    /**
     * Function to initialise d3 and associated functions required for pie series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {Object} series  Parent series object
     */
    init: function(chart, config, series) {
      var pie = this;
      pie.chart = chart;
      pie.series = series;
      pie.config = merge(this.defaultConfig, config);
      pie.arc = d3.arc();
      pie.outerArc = d3.arc();
      pie.layout = d3
        .pie()
        .padAngle(0.01)
        .value(function(d) {
          return d.y;
        });
      if (pie.config.sort !== true) {
        pie.layout.sort(null);
      }
      pie.setCSS();
      pie.render();
    },
    /**
     * Function to remove existing  elements and redraw pie
     */
    redraw: function() {
      var pie = this;
      pie.drawSeries();
    },
    /**
     * Function to remove pie
     */
    remove: function() {
      var pie = this;
      pie.destroy();
    },
    /**
     * Function to render pie
     */
    render: function() {
      var pie = this;
      pie.setCoordinates();
      pie.gElem = pie.chart.gElem
        .append('g')
        .attr(
          'transform',
          'translate(' + pie.coordinates[0] + ',' + pie.coordinates[1] + ')'
        )
        .attr('class', pie.chart.cssPrefix + 'series-' + pie.series.index);
      if (pie.config.tooltip !== false) {
        if (
          !!pie.chart.config.tooltip.actions &&
          pie.chart.config.tooltip.actions.length > 0
        ) {
          pie.gElem.style('cursor', 'pointer');
        }
        pie.gElem
          .on('mouseover', function() {
            if (d3.event.target.tagName == 'path') {
              d3.select(d3.event.target)
                .transition()
                .duration(100)
                .attr('d', pie.outerArc);
              pie.chart.tooltip.show(
                d3.event.target.__data__.data,
                pie.series.config.name || 'Series ' + pie.series.config.index
              );
            }
          })
          .on('click', function() {
            if (d3.event.target.tagName == 'path') {
              d3.select(d3.event.target)
                .transition()
                .duration(100)
                .attr('d', pie.outerArc);
              pie.chart.tooltip.show(
                d3.event.target.__data__.data,
                pie.series.config.name || 'Series ' + pie.series.config.index,
                true
              );
            }
          })
          .on('mouseout', function(event) {
            if (d3.event.target.tagName == 'path') {
              d3.select(d3.event.target)
                .transition()
                .duration(100)
                .attr('d', pie.arc);
            }
            pie.chart.tooltip.hide();
          });
      }
      pie.drawSeries();
    },
    /**
     * Function to resize pie
     */
    resize: function() {
      var pie = this;
      pie.setCoordinates();
      pie.gElem.attr(
        'transform',
        'translate(' + pie.coordinates[0] + ',' + pie.coordinates[1] + ')'
      );

      pie.gElem.selectAll('.arc').attr('d', pie.arc);
    },
    /**
     * Function to create  and append css classes to style tag for the pie
     */
    setCSS: function() {
      var pie = this;
      var cssString = '',
        i,
        j;
      cssString =
        cssString +
        ' .' +
        pie.chart.cssPrefix +
        'series-' +
        pie.series.index +
        ' .arc {';
      for (i in pie.config.style) {
        if (!isObject(pie.config.style[i])) {
          cssString =
            cssString +
            i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
            ':' +
            pie.config.style[i] +
            ';';
        }
      }
      cssString = cssString + '}';

      // hover styles
      for (i in pie.config.style) {
        if (isObject(pie.config.style[i])) {
          cssString =
            cssString +
            ' .' +
            pie.chart.cssPrefix +
            'series-' +
            pie.series.index +
            ' .arc:' +
            i +
            ' {';
          for (j in pie.config.style[i]) {
            cssString =
              cssString +
              j.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
              ':' +
              pie.config.style[i][j] +
              ';';
          }
          cssString = cssString + '}';
        }
      }

      pie.chart.style.node().appendChild(document.createTextNode(cssString));

      //markerCSS
    },
    /**
     * Function to set cordinates of centre of pie
     */
    setCoordinates: function() {
      var pie = this;
      if (
        mathAbs(pie.chart.xRange[0] - pie.chart.xRange[1]) <
        mathAbs(pie.chart.yRange[0] - pie.chart.yRange[1])
      ) {
        pie.OuterRad = mathAbs(pie.chart.xRange[0] - pie.chart.xRange[1]) / 2;
      } else {
        pie.OuterRad = mathAbs(pie.chart.yRange[0] - pie.chart.yRange[1]) / 2;
      }
      pie.innerRad = pie.OuterRad * 0.9;
      pie.outerArc.outerRadius(pie.OuterRad).innerRadius(pie.OuterRad * 0.5);
      pie.arc.outerRadius(pie.innerRad).innerRadius(pie.innerRad * 0.5);
      pie.coordinates = [];
      pie.coordinates[0] =
        mathAbs(pie.chart.xRange[0] - pie.chart.xRange[1]) / 2 +
        d3Min(pie.chart.xRange);
      pie.coordinates[1] =
        mathAbs(pie.chart.yRange[0] - pie.chart.yRange[1]) / 2 +
        d3Min(pie.chart.yRange);
    }
  };

  /**
   * A Constructor for creating, updating and removing sunburst component in chart.
   * @constructor
   */
  function Sunburst() {
    this.init.apply(this, arguments);
  }
  Sunburst.prototype = {
    /**
     * The default configuration for sunburst.
     * @type {object}
     */
    defaultConfig: {
      colorByPoint: true,
      style: {
        stroke: '#ffffff',
        strokeWidth: '0.5px',
        fillOpacity: 1,
        hover: {
          fillOpacity: 0.8
        }
      }
    },
    /**
     * Function to destroy sunburst
     */
    destroy: function() {
      var sunburst = this,
        chart = sunburst.chart,
        series = sunburst.series,
        key;
      delete series.component;

      sunburst.gElem.selectAll('*').remove();
      sunburst.gElem.node().parentNode.removeChild(sunburst.gElem.node());

      for (key in sunburst) {
        delete sunburst[key];
      }
    },
    /**
     * Function to draw the sunburst series
     */
    drawSeries: function() {
      var sunburst = this,
        className = '';

      sunburst.gElem.selectAll('path').remove();

      var radius = Math.min(sunburst.chart.width, sunburst.chart.height) / 3;

      sunburst.partition = d3.partition().size([2 * Math.PI, radius]);

      sunburst.partition(sunburst.root);

      sunburst.arc = d3
        .arc()
        .startAngle(function(d) {
          return d.x0;
        })
        .endAngle(function(d) {
          return d.x1;
        })
        .innerRadius(function(d) {
          return d.y0;
        })
        .outerRadius(function(d) {
          return d.y1;
        });

      sunburst.gElem
        .selectAll('path')
        .data(sunburst.root.descendants())
        .enter()
        .append('path')
        .attr('display', function(d) {
          return d.depth ? null : 'none';
        }) // hide inner ring
        .attr('class', 'arc')
        .style('fill', function(d, i) {
          return sunburst.chart.colorScale(
            (d.children ? d : d.parent).data.name
          );
        })
        .attr('d', sunburst.arc);
    },
    /**
     * Function to initialise d3 and associated functions required for sunburst series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {Object} series  Parent series object
     */
    init: function(chart, config, series) {
      var sunburst = this;
      sunburst.chart = chart;
      sunburst.series = series;
      sunburst.config = merge(this.defaultConfig, config);

      sunburst.root = d3.hierarchy(sunburst.series.data).sum(function(d) {
        return d.value;
      });

      sunburst.setCSS();
      sunburst.render();
    },
    /**
     * Function to remove existing  elements and redraw sunburst
     */
    redraw: function() {
      var sunburst = this;
      sunburst.drawSeries();
    },
    /**
     * Function to remove sunburst
     */
    remove: function() {
      var sunburst = this;
      sunburst.destroy();
    },
    /**
     * Function to render sunburst
     */
    render: function() {
      var sunburst = this;
      sunburst.setCoordinates();
      sunburst.gElem = sunburst.chart.gElem
        .append('g')
        .attr(
          'transform',
          'translate(' +
            sunburst.coordinates[0] +
            ',' +
            sunburst.coordinates[1] +
            ')'
        )
        .attr(
          'class',
          sunburst.chart.cssPrefix + 'series-' + sunburst.series.index
        );
      if (sunburst.config.tooltip !== false) {
        if (
          !!sunburst.chart.config.tooltip.actions &&
          sunburst.chart.config.tooltip.actions.length > 0
        ) {
          sunburst.gElem.style('cursor', 'pointer');
        }
        sunburst.gElem
          .on('mouseover', function() {
            if (d3.event.target.tagName == 'path') {
              sunburst.chart.tooltip.show(
                d3.event.target.__data__,
                sunburst.series.config.name ||
                  'Series ' + sunburst.series.config.index
              );
            }
          })
          .on('click', function() {
            if (d3.event.target.tagName == 'path') {
              sunburst.chart.tooltip.show(
                d3.event.target.__data__,
                sunburst.series.config.name ||
                  'Series ' + sunburst.series.config.index,
                true
              );
            }
          })
          .on('mouseout', function(event) {
            sunburst.chart.tooltip.hide();
          });
      }
      sunburst.drawSeries();
    },
    /**
     * Function to resize sunburst
     */
    resize: function() {
      var sunburst = this;
      sunburst.setCoordinates();
      sunburst.gElem.attr(
        'transform',
        'translate(' +
          sunburst.coordinates[0] +
          ',' +
          sunburst.coordinates[1] +
          ')'
      );

      sunburst.drawSeries();
    },
    /**
     * Function to create  and append css classes to style tag for the sunburst
     */
    setCSS: function() {
      var sunburst = this;
      var cssString = '',
        i,
        j;
      cssString =
        cssString +
        ' .' +
        sunburst.chart.cssPrefix +
        'series-' +
        sunburst.series.index +
        ' .arc {';
      for (i in sunburst.config.style) {
        if (!isObject(sunburst.config.style[i])) {
          cssString =
            cssString +
            i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
            ':' +
            sunburst.config.style[i] +
            ';';
        }
      }
      cssString = cssString + '}';

      // hover styles
      for (i in sunburst.config.style) {
        if (isObject(sunburst.config.style[i])) {
          cssString =
            cssString +
            ' .' +
            sunburst.chart.cssPrefix +
            'series-' +
            sunburst.series.index +
            ' .arc:' +
            i +
            ' {';
          for (j in sunburst.config.style[i]) {
            cssString =
              cssString +
              j.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
              ':' +
              sunburst.config.style[i][j] +
              ';';
          }
          cssString = cssString + '}';
        }
      }

      sunburst.chart.style
        .node()
        .appendChild(document.createTextNode(cssString));

      //markerCSS
    },
    /**
     * Function to set cordinates of centre of sunburst
     */
    setCoordinates: function() {
      var sunburst = this;
      if (
        mathAbs(sunburst.chart.xRange[0] - sunburst.chart.xRange[1]) <
        mathAbs(sunburst.chart.yRange[0] - sunburst.chart.yRange[1])
      ) {
        sunburst.OuterRad =
          mathAbs(sunburst.chart.xRange[0] - sunburst.chart.xRange[1]) / 2;
      } else {
        sunburst.OuterRad =
          mathAbs(sunburst.chart.yRange[0] - sunburst.chart.yRange[1]) / 2;
      }
      sunburst.innerRad = sunburst.OuterRad * 0.9;
      // sunburst.outerArc.outerRadius(sunburst.OuterRad).innerRadius(sunburst.OuterRad * 0.5);
      // sunburst.arc.outerRadius(sunburst.innerRad).innerRadius(sunburst.innerRad * 0.5);

      sunburst.coordinates = [];
      sunburst.coordinates[0] =
        mathAbs(sunburst.chart.xRange[0] - sunburst.chart.xRange[1]) / 2 +
        d3Min(sunburst.chart.xRange);
      sunburst.coordinates[1] =
        mathAbs(sunburst.chart.yRange[0] - sunburst.chart.yRange[1]) / 2 +
        d3Min(sunburst.chart.yRange);
    }
  };

  /**
   * A Constructor for creating, updating and removing tooltip component in chart.
   * @constructor
   */
  function Tooltip() {
    this.init.apply(this, arguments);
  }
  Tooltip.prototype = {
    /**
     * The default configuration for tooltip.
     * @type {object}
     */
    defaultConfig: {
      offsetY: 15,
      dayFormat: '%e %B %Y',
      weekFormat: '%V - %Y',
      monthFormat: '%B %Y'
      // actions: [{
      //     name: 'dataView',
      //     icon: 'fa fa-cloud'
      // }, {
      //     name: 'dataView',
      //     icon: 'fa fa-car'
      // }]
    },
    /**
     * Function to destroy tooltip
     */
    destroy: function() {
      var tooltip = this,
        chart = tooltip.chart,
        key;
      tooltip.elem.remove();

      for (key in tooltip) {
        delete tooltip[key];
      }
      delete chart.tooltip;
      if (!!chart.config) {
        delete chart.config.tooltip;
      }
      if (!!chart.config) {
        delete chart.config.tooltip;
      }
    },
    /**
     * Function to get Ancestor names for hierarchical data
     */
    getAncestors: function(node, key) {
      var path = [];
      var current = node;
      while (current.parent) {
        path.unshift(key !== undefined ? current.data[key] : current);
        current = current.parent;
      }
      return path;
    },
    /**
     * Function to hide the tooltip
     */
    hide: function(forceHide) {
      var tooltip = this;
      if (tooltip.stay === true && forceHide !== true) {
        return;
      }
      if (forceHide === true) {
        if (tooltip.stay === true) {
          tooltip.chart.container.dispatchEvent(tooltip.closeEvent);
        }
        tooltip.stay = false;
        d3.select(tooltip.chart.svg.node())
          .selectAll('.chartPlus-grey-fill')
          .classed('chartPlus-grey-fill', false);
        d3.select(tooltip.chart.svg.node())
          .selectAll('.chartPlus-grey-stroke')
          .classed('chartPlus-grey-stroke', false);
      }
      tooltip.targetEvent = null;
      tooltip.elem.classed('chartPlus-allEvents', false);
      tooltip.elem.classed('chartPlus-tooltip-visible', false);
      tooltip.elem.classed('chartPlus-tooltip-hidden', true);
    },
    /**
     * Function to initialise d3 and associated functions required for tooltip
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     */
    init: function(chart, config) {
      var tooltip = this;
      tooltip.chart = chart;
      tooltip.config = merge(this.defaultConfig, config);
      tooltip.setCSS();
      tooltip.setUp();
    },
    /**
     * Function to remove tooltip
     */
    remove: function() {
      var tooltip = this;
      tooltip.destroy();
    },
    /**
     * Function to reposition tooltip
     */
    reposition: function() {
      var tooltip = this;
      var container = tooltip.chart.container.getBoundingClientRect();
      if (!tooltip.targetEvent) {
        return;
      }
      var targetElem = tooltip.targetEvent.target.getBoundingClientRect();
      tooltip.elem
        .style('left', function() {
          var info = this.getBoundingClientRect(),
            left = targetElem.left - container.left + 10;
          if (
            container.width - (targetElem.left - container.left) <
            info.width + 10
          ) {
            left = targetElem.left - container.left - info.width + 10;
          }
          return left + 'px';
        })
        .style('top', function() {
          var info = this.getBoundingClientRect(),
            top = targetElem.top - container.top + tooltip.config.offsetY;
          if (top + info.height > container.height) {
            top = top - info.height - tooltip.config.offsetY;
          }
          return top + 'px';
        });
    },
    /**
     * Function to create  and append css classes to style tag for the tooltip
     */

    setCSS: function() {
      var tooltip = this;
      var cssString = '',
        i;

      cssString = cssString + ' .' + tooltip.chart.cssPrefix + 'tooltip {';
      for (i in tooltip.chart.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          tooltip.chart.config.style[i] +
          ';';
      }
      if (getContrastYIQ(themeConfig.backgroundColor) === 'dark') {
        cssString =
          cssString +
          'background:' +
          themeConfig.tooltip.contrastDarkColor +
          ';' +
          'color:' +
          themeConfig.text.contrastLightColor +
          ';';
      } else {
        cssString =
          cssString +
          'background:' +
          themeConfig.tooltip.contrastLightColor +
          ';' +
          'color:' +
          themeConfig.text.contrastDarkColor +
          ';';
      }
      for (i in tooltip.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          tooltip.config.style[i] +
          ';';
      }
      cssString = cssString + '}';
      tooltip.chart.style
        .node()
        .appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to create  DOM elements to show tooltip
     */
    setUp: function() {
      var tooltip = this;

      composeDOM(
        d3
          .select(tooltip.chart.container)
          .select('.chartPlus-container')
          .node(),
        DIV,
        {
          className:
            tooltip.chart.cssPrefix +
            'tooltip' +
            ' ' +
            NAMESPACE +
            'tooltip' +
            (tooltip.config.className ? ' ' + tooltip.config.className : ''),
          id: NAMESPACE + 'tooltip'
        }
      );
      tooltip.elem = d3
        .select(accessDOM(NAMESPACE + tooltip.chart.index, 'id'))
        .select('.' + NAMESPACE + 'tooltip');

      if (
        isArray(tooltip.config.actions) &&
        tooltip.config.actions.length > 0
      ) {
        tooltip.config.actions.forEach(function(d, i) {
          if (!chartPlus.isIE) {
            d.event = new Event(d.name);
          } else {
            d.event = new Event(d.name);
          }
        });
        if (!chartPlus.isIE) {
          tooltip.closeEvent = new Event('tooltip-closed');
        } else {
          tooltip.closeEvent = new Event('tooltip-closed');
        }
      }

      if (isString(tooltip.config.dateFormat)) {
        tooltip.dateFormat = d3.timeFormat(tooltip.config.dateFormat);
      } else if (tooltip.chart.config.granularity === 'week') {
        tooltip.dateFormat = function(d) {
          return 'Week ' + d3.timeFormat(tooltip.config.weekFormat)(d);
        };
      } else if (!!tooltip.chart.config.granularity) {
        tooltip.dateFormat = d3.timeFormat(
          tooltip.config[tooltip.chart.config.granularity + 'Format']
        );
      } else {
        tooltip.dateFormat = d3.timeFormat(tooltip.config.dayFormat);
      }
    },
    /**
     * Function to show tooltip
     */
    show: function(d, name, stay) {
      var tooltip = this,
        html = '',
        legendX,
        categoryX,
        container;
      var dayFormat = d3.timeFormat('%Y-%m-%d');
      container = tooltip.chart.container.getBoundingClientRect();

      if (stay !== true && tooltip.stay === true) {
        return;
      }
      if (stay === true && tooltip.stay === true) {
        tooltip.chart.container.dispatchEvent(tooltip.closeEvent);
      }

      tooltip.targetEvent = d3.event;
      if (
        isArray(tooltip.config.actions) &&
        tooltip.config.actions.length > 0
      ) {
        tooltip.stay = !!stay;
        if (tooltip.stay === true) {
          tooltip.chart.series.forEach(function(series, idx) {
            if (series.config.type === 'line') {
              if (isObject(series.component)) {
                d3.select(series.component.gElem.node())
                  .select('path')
                  .classed('chartPlus-grey-stroke', true);
                d3.select(series.component.gElem.node())
                  .selectAll('circle')
                  .classed('chartPlus-grey-fill', true);
              }
            } else {
              if (isObject(series.component)) {
                d3.select(series.component.gElem.node())
                  .selectAll('*')
                  .classed('chartPlus-grey-fill', true);
              }
            }
          });
          d3.select(d3.event.target).classed('chartPlus-grey-fill', false);
          d3.select(d3.event.target).classed('chartPlus-grey-stroke', false);
        }
      }

      if (!!isObject(d)) {
        // dataview and linking support
        // if (isArray(tooltip.config.actions) && tooltip.config.actions.length > 0) {
        //     html = '<div class="chartPlus-tooltip-close"><span>\u274C</span>';
        //                    html = html + '</div>';
        // }

        if (isDate(d.x)) {
          // Support for legend key map
          if (!!tooltip.chart.config.legendMap) {
            legendX = tooltip.chart.config.legendMap[name];
          } else {
            legendX = name;
          }
          console.log(
            'inside tooltip to find date',
            d.x,
            dayFormat(d.x),
            tooltip.dateFormat(d.x)
          );
          tooltip.data = { category: dayFormat(d.x), legend: name };
          html = html + '<div>';
          html = html + '<span>' + tooltip.dateFormat(d.x) + '</span>';
          if (
            isArray(tooltip.config.actions) &&
            tooltip.config.actions.length > 0
          ) {
            html =
              html +
              '<span>&nbsp;&nbsp;</span><span class="chartPlus-tooltip-close">\u274C</span>';
          }
          html = html + '</div>';

          html = html + '<div>';
          html =
            html +
            '<span style="font-weight:bold;">' +
            legendX +
            ' : ' +
            numberFormat(d.y) +
            (tooltip.chart.config.percent === true ? '%' : '') +
            '</span>';
          html = html + '</div>';
        } else if (!!d.value && !!d.parent) {
          tooltip.data = {
            category: tooltip.getAncestors(d, 'name')[0],
            legend: tooltip.getAncestors(d, 'name')[1]
          };

          // Support for Legend and Categeory Key Map
          if (
            !!tooltip.chart.config.categoryMap &&
            !!tooltip.chart.config.legendMap
          ) {
            categoryX = tooltip.chart.config.categoryMap[tooltip.data.category];
            legendX = tooltip.chart.config.legendMap[tooltip.data.legend];

            html = html + '<div>';
            html = html + '<span>' + categoryX;
            if (typeof legendX !== 'undefined') {
              html = html + '/' + legendX + '</span>';
            } else {
              html = html + '</span>';
            }
          } else {
            html = html + '<div>';
            html =
              html +
              '<span>' +
              tooltip.getAncestors(d, 'name').join('/') +
              '</span>';
          }

          if (
            isArray(tooltip.config.actions) &&
            tooltip.config.actions.length > 0
          ) {
            html =
              html +
              '<span>&nbsp;&nbsp;</span><span class="chartPlus-tooltip-close">\u274C</span>';
          }
          html = html + '</div>';

          html = html + '<div>';
          html =
            html +
            '<span style="font-weight:bold;">' +
            name +
            ' : ' +
            numberFormat(d.value) +
            (tooltip.chart.config.percent === true ? '%' : '') +
            '</span>';
          html = html + '</div>';
        } else {
          if (tooltip.chart.config.flip === undefined) {
            tooltip.data = { category: d.x, legend: name };
          } else {
            // Special handling for Pie group by option
            tooltip.data = { category: name, legend: d.x };
          }

          // Support for category key map
          if (!!tooltip.chart.config.categoryMap) {
            categoryX = tooltip.chart.config.categoryMap[tooltip.data.category];
          } else {
            categoryX = tooltip.data.category;
          }
          // Support for legend key map
          if (!!tooltip.chart.config.legendMap) {
            legendX = tooltip.chart.config.legendMap[tooltip.data.legend];
          } else {
            legendX = tooltip.data.legend;
          }

          html = html + '<div>';
          html = html + '<span>' + categoryX + '</span>';
          if (
            isArray(tooltip.config.actions) &&
            tooltip.config.actions.length > 0
          ) {
            html =
              html +
              '<span>&nbsp;&nbsp;</span><span class="chartPlus-tooltip-close">\u274C</span>';
          }
          html = html + '</div>';

          html = html + '<div>';
          html =
            html +
            '<span style="font-weight:bold;">' +
            legendX +
            ' : ' +
            numberFormat(d.y) +
            (tooltip.chart.config.percent === true ? '%' : '') +
            '</span>';
          html = html + '</div>';
        }

        // dataview and linking support
        if (
          isArray(tooltip.config.actions) &&
          tooltip.config.actions.length > 0
        ) {
          html = html + '<div class="chartPlus-tooltip-actions">';
          tooltip.config.actions.forEach(function(action, i) {
            html =
              html +
              '<span style="padding:2px 8px 2px 0;' +
              (action.color ? 'color:' + action.color + ';' : '') +
              '" class="' +
              action.icon +
              '"></span>';
          });
          html = html + '</div>';
        }
      } else if (isArray(d)) {
        // hierarchy number numerator vs denominator

        html = '<div>';
        d.forEach(function(item) {
          html =
            html +
            '<div>' +
            '<span>' +
            item.key +
            '&nbsp;:&nbsp;' +
            '</span>' +
            '<span>' +
            item.value +
            '</span>' +
            '</div>';
        });
        html = html + '</div>';
      } else {
        // plain text tooltip

        // Support for legend key map
        if (!!tooltip.chart.config.legendMap) {
          legendX = tooltip.chart.config.legendMap[d];
        } else {
          legendX = d;
        }

        html = '<span><b>' + legendX + '</b></span>';
      }

      var top, left;
      tooltip.elem
        .html(html)
        .style('left', function() {
          var info = this.getBoundingClientRect(),
            left = d3.event.pageX - container.left + 10;
          // if ((container.width - (d3.event.pageX - container.left)) < (info.width + 10)) {
          if (container.width / 2 < left) {
            left = d3.event.pageX - container.left - info.width + 10;
          }
          return left + 'px';
        })
        .style('top', function() {
          var info = this.getBoundingClientRect(),
            top = d3.event.pageY - container.top + tooltip.config.offsetY;
          if (top + info.height > container.height) {
            top = top - info.height - tooltip.config.offsetY;
          }
          return top + 'px';
        });

      if (
        isArray(tooltip.config.actions) &&
        tooltip.config.actions.length > 0
      ) {
        tooltip.elem
          .select('.chartPlus-tooltip-actions')
          .selectAll('span')
          .datum(function(d, i) {
            return tooltip.config.actions[i];
          });
        tooltip.elem
          .select('.chartPlus-tooltip-actions')
          .on('click', function() {
            var item = d3.event.target.__data__;
            item.data = [0];
            item.event.data = tooltip.data;
            tooltip.chart.container.dispatchEvent(item.event);
          });
        tooltip.elem.select('.chartPlus-tooltip-close').on('click', function() {
          tooltip.hide(true);
        });
      }

      if (d3.event.type === 'click') {
        tooltip.elem.classed('chartPlus-allEvents', true);
      } else {
        tooltip.elem.classed('chartPlus-allEvents', false);
      }
      tooltip.elem.classed('chartPlus-tooltip-visible', true);
      tooltip.elem.classed('chartPlus-tooltip-hidden', false);
    }
  };
  /**
   * A Constructor for creating, updating and removing legends.
   * @constructor
   */
  function Legend() {
    this.init.apply(this, arguments);
  }
  Legend.prototype = {
    /**
     * Function to align legend set to bottom end of svg
     */
    alignbottom: function() {
      var legend = this,
        x,
        y;
      x = 0; //getTransformation(legend.gElem.attr("transform")).translateX;
      y = legend.chart.height - legend.gElem.node().getBBox().height;
      if (legend.config.layout === 'vertical') {
        if (legend.chart.height < legend.gElem.node().getBBox().height) {
          y = 0;
        } else {
          y = legend.chart.height - legend.gElem.node().getBBox().height;
        }
      } else {
        y =
          legend.chart.height -
          (!!legend.navigation
            ? getTransformation(legend.nav.attr('transform')).translateY +
              legend.nav.node().getBBox().height +
              2 * legend.config.navigation.padding
            : legend.gElem.node().getBBox().height);
      }

      legend.gElem.attr('transform', 'translate(' + x + ',' + y + ')');
    },
    /**
     * Function to align legend set to centre of svg
     */
    aligncentre: function() {
      var legend = this,
        x,
        y;
      x = getTransformation(legend.gElem.attr('transform')).translateX;
      y = getTransformation(legend.gElem.attr('transform')).translateY;
      if (legend.config.layout === 'horizontal') {
        x = (legend.chart.width - legend.gElem.node().getBBox().width) / 2;
      } else {
        if (legend.chart.height < legend.gElem.node().getBBox().height) {
          y = 0;
        } else {
          y = (legend.chart.height - legend.gElem.node().getBBox().height) / 2;
        }
      }
      legend.gElem.attr('transform', 'translate(' + x + ',' + y + ')');
    },
    /**
     * Function to align legend set to left end of svg
     */
    alignleft: function() {
      var legend = this,
        x,
        y;
      x = legend.config.padding;
      y = getTransformation(legend.gElem.attr('transform')).translateY;
      legend.gElem.attr('transform', 'translate(' + x + ',' + y + ')');
    },
    /**
     * Function to align legend set to right end of svg
     */
    alignright: function() {
      var legend = this,
        x,
        y;
      x = legend.chart.width - legend.gElem.node().getBBox().width;
      // y = getTransformation(legend.gElem.attr("transform")).translateY;
      y = 0;
      legend.gElem.attr('transform', 'translate(' + x + ',' + y + ')');
    },
    /**
     * Function to align legend set to top end of svg
     */
    aligntop: function() {
      var legend = this,
        x,
        y;
      x = getTransformation(legend.gElem.attr('transform')).translateX;
      y = legend.config.padding;
      legend.gElem.attr('transform', 'translate(' + x + ',' + y + ')');
    },
    /**
     * Function to align legend items horizontally
     */
    alignHorizontally: function() {
      var legend = this,
        x = 0,
        y = 0,
        row = 1,
        maxWidth = legend.chart.width - legend.config.padding,
        translate;

      legend.items.attr('transform', function(d, i) {
        if (
          i > 0 &&
          legend.config.wrap === true &&
          maxWidth < x + this.getBBox().width
        ) {
          row = row + 1;
          x = 0;
          y =
            legend.itemList.node().getBBox().height +
            legend.config.legendSpacing;
          if (!!legend.config.maxRows && row === legend.config.maxRows + 1) {
            legend.maxListHeight = y;
          }
        }
        translate = 'translate(' + x + ',' + y + ')';
        x = x + (this.getBBox().width || 0) + legend.config.legendSpacing;
        return translate;
      });
    },
    /**
     * Function to align legend items vertically
     */
    alignVertically: function() {
      var legend = this,
        y = 0,
        x = 0,
        maxX = 0,
        translate;
      legend.items.attr('transform', function(d, i) {
        if (
          i > 0 &&
          legend.config.wrap === true &&
          legend.maxListHeight <
            y + this.getBBox().height + legend.config.legendSpacing
        ) {
          x = x + maxX + legend.config.legendSpacing;
          y = legend.config.legendSpacing;
        }
        translate = 'translate(' + x + ',' + y + ')';

        y = y + (this.getBBox().height || 0) + legend.config.legendSpacing;
        maxX = maxX < this.getBBox().width ? this.getBBox().width : maxX;

        return translate;
      });
    },
    /**
     * The default configuration for legends.
     * @type {object}
     */
    defaultConfig: {
      align: 'top-right',
      float: false,
      layout: 'vertical', ///horizontal
      padding: 15,
      size: 5,
      maxTextLength: 25,
      maxWidthPercent: 30,
      legendSpacing: 10, // space between each legend items
      textSpacing: 10, // space between rect and text
      wrap: false,
      color: defaultConfig.style.color || '#707070',
      navigation: {
        padding: 3,
        size: 15,
        duration: 800
      },
      style: {
        whiteSpace: 'nowrap',
        fontSize: '11px'
      }
    },
    /**
     * Special default configurations for vertically aligning legend items.
     * @type {object}
     */
    verticalConfig: {
      legendSpacing: 10, // space between each legend items
      textSpacing: 10, // space between rect and text
      wrap: false
    },
    /**
     * Special default configurations for horizontally aligning legend items.
     * @type {object}
     */
    horizontalConfig: {
      legendSpacing: 10,
      textSpacing: 10,
      wrap: true,
      maxRows: 2
    },
    /**
     * Special default configurations for floating legends.
     * @type {object}
     */
    floatConfig: {
      padding: 0
    },
    /**
     * Function to destroy legends
     */
    destroy: function() {
      var legend = this,
        chart = legend.chart,
        key;
      delete chart.legend;
      delete chart.config.legend;
      if (legend.navigation === true) {
        legend.nav.selectAll('*').remove();
        legend.nav.node().parentNode.removeChild(legend.nav.node());
      }
      legend.items.selectAll('*').remove();
      legend.items.node().parentNode.removeChild(legend.items.node());
      legend.content.selectAll('*').remove();
      legend.content.node().parentNode.removeChild(legend.content.node());
      legend.gElem.selectAll('*').remove();
      legend.gElem.node().parentNode.removeChild(legend.gElem.node());
      legend.clippath.selectAll('*').remove();
      legend.clippath.node().parentNode.removeChild(legend.clippath.node());

      for (key in legend) {
        delete legend[key];
      }
    },
    /**
     * Function to prepare data for legends
     */
    getData: function() {
      var legend = this;

      if (legend.config.innerLegend === true) {
        legend.data = legend.chart.innerLegends.map(function(legend, idx) {
          return { name: legend };
        });
      } else {
        legend.data = legend.chart.config.series.map(function(series, idx) {
          return {
            name: series.name || 'Series ' + idx,
            isHidden: series.isHidden
          };
        });
      }
    },
    /**
     * Function to initialise d3 and associated functions required for legends
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {boolean} render  Flag whether to do rendering after initialisation
     */
    init: function(chart, config, render) {
      var legend = this;
      legend.defaultConfig.color = defaultConfig.style.color || '#707070';
      legend.chart = chart;
      legend.config = merge(
        this.defaultConfig,
        config,
        config.float ? this.floatConfig : {}
      );
      //  legend.config = merge(legend.config, this[legend.config.layout + 'Config']);

      legend.getData();
      // set css if any
      legend.setCSS();

      if (render !== false) {
        legend.render();
      }
    },
    /**
     * Function to remove legends
     */
    remove: function() {
      var legend = this;
      legend.destroy();
    },
    /**
     * Function to render legends to HTML
     */
    render: function() {
      var legend = this,
        hiddenLegends = 0,
        className = '',
        legendName,
        index;
      legend.firstRender = false;

      legend.clippath = legend.chart.svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'legendClip-' + legend.chart.index);
      legend.clippath
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 0)
        .attr('height', 0);

      legend.gElem = legend.chart.gElem
        .append('g')
        .attr('class', legend.chart.cssPrefix + 'legend');

      legend.content = legend.gElem.append('g');
      // .attr("transform", "translate(0," + (legend.config.legendSpacing) + ")");

      legend.itemList = legend.content
        .append('g')
        .attr('transform', 'translate(0,0)')
        .on('mouseover', function() {
          if (
            d3.event.target.tagName == 'text' &&
            d3
              .select(this)
              .text()
              .indexOf('...') > -1
          ) {
            legend.chart.tooltip.show(d3.event.target.__data__.name);
          }
        })
        .on('mouseout', function(event) {
          legend.chart.tooltip.hide();
        })
        .on('click', function(event) {
          if (legend.config.innerLegend === true) {
            // check what to do
          } else {
            index = legend.data.indexOf(d3.event.target.__data__);
            if (!!d3.event.target.__data__.isHidden) {
              hiddenLegends = hiddenLegends - 1;
              legend.chart.series[index].show();
            } else {
              hiddenLegends = hiddenLegends + 1;
              legend.chart.series[index].hide();
            }
            d3.event.target.__data__.isHidden = !d3.event.target.__data__
              .isHidden;

            d3.select(d3.event.target.parentNode)
              .select('circle')
              .style('fill', function() {
                if (!!d3.event.target.__data__.isHidden) {
                  return '#cccccc';
                } else if (
                  !legend.chart.config.colorByPoint &&
                  legend.chart.config.series[index].color
                ) {
                  return legend.chart.config.series[index].color;
                } else {
                  return legend.chart.colorScale(d3.event.target.__data__.name);
                }
              });
            d3.select(d3.event.target.parentNode)
              .select('text')
              .style('fill', function(d, i) {
                if (!!d.isHidden) {
                  return '#cccccc';
                } else {
                  return null;
                }
              });
            if (legend.chart.config.percent !== true) {
              if (hiddenLegends === legend.data.length) {
                legend.chart.yAxis.hide();
              } else {
                legend.chart.yAxis.show();
              }
            }
          }
        });

      if (legend.config.innerLegend !== true) {
        legend.content.classed(legend.chart.cssPrefix + 'cursorPointer', true);
      }

      legend.items = legend.itemList
        .selectAll('.legendItem')
        .data(legend.data)
        .enter()
        .append('g');

      legend.items
        .append('circle')
        .attr('class', 'legendIcon')
        .style('fill', function(d, i) {
          if (!!d.isHidden) {
            return '#cccccc';
          } else if (
            !legend.chart.config.colorByPoint &&
            legend.chart.config.series[i].color
          ) {
            return legend.chart.config.series[i].color;
          } else {
            return legend.chart.colorScale(d.name);
          }
        })
        .attr('cx', 5)
        .attr('cy', 5)
        .attr('r', legend.config.size);

      legend.iconSize = legend.items
        .select('circle')
        .node()
        .getBBox();

      legend.items
        .append('text')
        .style('fill', function(d, i) {
          if (!!d.isHidden) {
            return '#cccccc';
          } else {
            return null;
          }
        })
        .attr('x', function(d, i) {
          return legend.iconSize.width + legend.config.textSpacing;
        })
        .attr('y', function(d, i) {
          return legend.iconSize.height;
        })
        .text(function(d, i) {
          // Legends needs to be mapped to keys if categoryMap (Pie) & legendMap(Bar) is provided
          // Support for legend key map
          if (
            (legend.chart.config.type == 'pie' &&
              legend.chart.config.flip === undefined) ||
            legend.chart.config.type == 'sunburst'
          ) {
            if (!!legend.chart.config.categoryMap) {
              legendName = legend.chart.config.categoryMap[d.name];
              legendName = setDefaultVal(legendName, 'unknown');
            } else {
              legendName = d.name;
            }
          } else {
            if (!!legend.chart.config.legendMap) {
              legendName = legend.chart.config.legendMap[d.name];
              legendName = setDefaultVal(legendName, 'unknown');
            } else {
              legendName = d.name;
            }
          }
          if (legendName.length > legend.config.maxTextLength) {
            legendName =
              legendName.slice(0, legend.config.maxTextLength) + '...';
          }
          return legendName;
        });
      var maxItemWidth = 0;

      legend.items.each(function(item) {
        if (maxItemWidth < this.getBBox().width) {
          maxItemWidth = this.getBBox().width;
        }
      });
      if (
        legend.config.layout == 'horizontal' ||
        maxItemWidth >
          (legend.chart.width * legend.config.maxWidthPercent) / 100
      ) {
        legend.config.layout = 'horizontal';
        legend.config.align = 'bottom-left';
        legend.config = merge(legend.config, legend.horizontalConfig);
      } else {
        legend.config.layout = 'vertical';
        legend.config.align = 'top-right';
        if (legend.config.innerLegend === true) {
          legend.config.align = 'centre-right';
        }
        legend.config = merge(legend.config, legend.verticalConfig);
      }

      if (legend.config.layout === 'horizontal') {
        legend.alignHorizontally();
      } else {
        legend.alignVertically();
      }

      if (legend.config.layout === 'vertical') {
        // legend.maxListHeight = (isNumber(legend.config.maxHeight) && (legend.config.maxHeight < legend.chart.height)) ? (legend.config.maxHeight - legend.config.legendSpacing) : (legend.chart.height - legend.config.legendSpacing);
        if (legend.gElem.node().getBBox().height > legend.chart.height) {
          legend.setNavigation();
        }
      } else {
        if (legend.itemList.node().getBBox().height > legend.maxListHeight) {
          legend.setNavigation();
        }
      }

      legend.config.align.split('-').forEach(function(alignment, idx) {
        legend['align' + alignment]();
      });
    },
    /**
     * Function to reposition legends
     */
    reposition: function() {
      var legend = this;
      legend.items.attr('transform', 'translate(0,0)');
      var maxItemWidth = 0;

      legend.items.each(function(item) {
        if (maxItemWidth < this.getBBox().width) {
          maxItemWidth = this.getBBox().width;
        }
      });
      if (
        legend.config.layout == 'horizontal' ||
        maxItemWidth >
          (legend.chart.width * legend.config.maxWidthPercent) / 100
      ) {
        legend.config.layout = 'horizontal';
        legend.config.align = 'bottom-left';
        if (legend.chart.forExport !== true) {
          // Special handling incase of export to pdf , rowsize is 5
          legend.config = merge(legend.config, legend.horizontalConfig);
        }
      } else {
        legend.config.layout = 'vertical';
        legend.config.align = 'top-right';
        if (legend.config.innerLegend === true) {
          legend.config.align = 'centre-right';
        }
        legend.config = merge(legend.config, legend.verticalConfig);
      }

      if (legend.config.layout === 'vertical') {
        legend.alignVertically();
        // legend.maxListHeight = (isNumber(legend.config.maxHeight) && (legend.config.maxHeight < legend.chart.height)) ? (legend.config.maxHeight - legend.config.legendSpacing) : (legend.chart.height - legend.config.legendSpacing);
        if (legend.gElem.node().getBBox().height > legend.chart.height) {
          legend.setNavigation();
        } else if (legend.navigation === true) {
          legend.nav.selectAll('*').remove();
          legend.nav.node().parentNode.removeChild(legend.nav.node());
          legend.navigation = false;
          legend.content.classed(legend.chart.cssPrefix + 'clipLegend', false);
        }
      } else {
        legend.alignHorizontally();
        if (legend.itemList.node().getBBox().height > legend.maxListHeight) {
          legend.setNavigation();
        } else if (legend.navigation === true) {
          legend.nav.selectAll('*').remove();
          legend.nav.node().parentNode.removeChild(legend.nav.node());
          legend.navigation = false;
          legend.content.classed(legend.chart.cssPrefix + 'clipLegend', false);
        }
      }

      legend.config.align.split('-').forEach(function(alignment, idx) {
        legend['align' + alignment]();
      });
    },
    /**
     * Function to resize legends
     */
    resize: function() {
      var legend = this;
      // additional functions while resizing
      legend.reposition();
    },
    /**
     * Function to set navigation arrows for overflowing legends
     */
    setNavigation: function() {
      var legend = this,
        x = 0,
        size = legend.config.navigation.size;
      if (!legend.navigation) {
        legend.navConfig = { currentPage: 1, maxPage: 0 };

        legend.nav = legend.gElem
          .append('g')
          .attr('class', legend.chart.cssPrefix + 'legendNav');

        legend.nav
          .append('g')
          .attr('class', 'navTop')
          .on('click', function() {
            legend.showTopPage();
            d3.select(this)
              .select('path')
              .classed(
                legend.chart.cssPrefix + 'greyed',
                legend.navConfig.currentPage === 1
              )
              .classed(
                legend.chart.cssPrefix + 'cursorPointer',
                legend.navConfig.currentPage !== 1
              );

            legend.nav
              .select('.navBottom')
              .select('path')
              .classed(
                legend.chart.cssPrefix + 'greyed',
                legend.navConfig.currentPage === legend.navConfig.maxPage
              )
              .classed(
                legend.chart.cssPrefix + 'cursorPointer',
                legend.navConfig.currentPage !== legend.navConfig.maxPage
              );
          });

        legend.nav
          .select('.navTop')
          .append('path')
          .attr('transform', 'translate(' + 10 / 2 + ',' + 10 / 2 + ')')
          .attr(
            'd',
            'M6.582,12.141c-0.271,0.268-0.709,0.268-0.978,0c-0.269-0.268-0.272-0.701,0-0.969l3.908-3.83 c0.27-0.268,0.707-0.268,0.979,0l3.908,3.83c0.27,0.267,0.27,0.701,0,0.969c-0.271,0.268-0.709,0.268-0.979,0L10,9L6.582,12.141z'
          )
          .attr('class', legend.chart.cssPrefix + 'greyed');

        legend.nav
          .append('text')
          .text(legend.navConfig.currentPage + '/' + legend.navConfig.maxPage);

        legend.nav
          .append('g')
          .attr('class', 'navBottom')
          .on('click', function() {
            legend.showBottomPage();
            d3.select(this)
              .select('path')
              .classed(
                legend.chart.cssPrefix + 'greyed',
                legend.navConfig.currentPage === legend.navConfig.maxPage
              )
              .classed(
                legend.chart.cssPrefix + 'cursorPointer',
                legend.navConfig.currentPage !== legend.navConfig.maxPage
              );

            legend.nav
              .select('.navTop')
              .select('path')
              .classed(
                legend.chart.cssPrefix + 'greyed',
                legend.navConfig.currentPage === 1
              )
              .classed(
                legend.chart.cssPrefix + 'cursorPointer',
                legend.navConfig.currentPage !== 1
              );
          });

        legend.nav
          .select('.navBottom')
          .append('path')
          .attr('transform', 'translate(' + 10 / 2 + ',' + 10 / 2 + ')')
          .attr(
            'd',
            'M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83 c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z'
          )
          .attr('class', legend.chart.cssPrefix + 'cursorPointer');

        legend.navigation = true;
      }

      legend.nav
        .select('.navTop')
        .attr(
          'transform',
          'translate(' + (x + legend.config.navigation.padding) + ',0)'
        );

      x =
        legend.nav
          .select('.navTop')
          .node()
          .getBBox().width +
        legend.config.navigation.padding +
        20;

      legend.nav.select('text').attr(
        'transform',
        'translate(' +
          (x + legend.config.navigation.padding) +
          ',' +
          legend.nav
            .select('.navTop')
            .select('path')
            .node()
            .getBBox().height +
          ')'
      );
      if (legend.config.layout === 'horizontal') {
        legend.clipHeight = legend.maxListHeight;
      } else {
        legend.clipHeight =
          legend.chart.height -
          legend.nav.node().getBBox().height -
          2 * legend.config.navigation.padding;
        legend.clipHeight =
          Math.floor(
            legend.clipHeight /
              (legend.items.node().getBBox().height +
                legend.config.legendSpacing)
          ) *
          (legend.items.node().getBBox().height + legend.config.legendSpacing);
      }
      legend.navConfig.maxPage = Math.ceil(
        legend.itemList.node().getBBox().height / legend.clipHeight
      );

      legend.nav
        .select('text')
        .text(legend.navConfig.currentPage + '/' + legend.navConfig.maxPage);

      x =
        x +
        legend.nav
          .select('text')
          .node()
          .getBBox().width +
        legend.config.navigation.padding;

      legend.nav
        .select('.navBottom')
        .attr(
          'transform',
          'translate(' + (x + legend.config.navigation.padding) + ',0)'
        );

      legend.nav.attr('transform', 'translate(0,' + legend.clipHeight + ')');

      legend.clippath
        .select('rect')
        // .attr('x', 0)
        .attr('y', 0)
        .attr('width', legend.itemList.node().getBBox().width)
        .attr('height', legend.clipHeight);

      legend.content.classed(legend.chart.cssPrefix + 'clipLegend', true);
    },
    /**
     * Function to create  and append css classes to style tag for the axis
     */
    setCSS: function() {
      var legend = this;
      var cssString = '',
        i;
      if (!!legend.chart.config.style.color) {
        legend.config.color = legend.chart.config.style.color;
      }

      cssString = cssString + ' .' + legend.chart.cssPrefix + 'legend {';
      for (i in legend.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          legend.config.style[i] +
          ';';
      }
      if (legend.config.color) {
        cssString = cssString + 'fill:' + legend.config.color + ';';
      }
      cssString = cssString + '}';
      cssString = cssString + ' .' + legend.chart.cssPrefix + 'legendNav {';
      for (i in legend.config.navigation.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          legend.config.navigation.style[i] +
          ';';
      }
      if (legend.config.color) {
        cssString = cssString + 'fill:' + legend.config.color + ';';
      }
      cssString = cssString + '}';

      cssString = cssString + ' .' + legend.chart.cssPrefix + 'clipLegend {';
      cssString =
        cssString + 'clip-path:url(#legendClip-' + legend.chart.index + ');';
      cssString = cssString + '}';

      legend.chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to show bottom legends for navigation
     */
    showBottomPage: function() {
      var legend = this;

      if (legend.navConfig.currentPage < legend.navConfig.maxPage) {
        legend.navConfig.currentPage = legend.navConfig.currentPage + 1;
        legend.nav
          .select('text')
          .text(legend.navConfig.currentPage + '/' + legend.navConfig.maxPage);
        legend.itemList
          .transition()
          .duration(legend.config.navigation.duration)
          .attr(
            'transform',
            'translate(0,' +
              legend.clipHeight * (1 - legend.navConfig.currentPage) +
              ')'
          );
      }
    },
    /**
     * Function to show top legends for navigation
     */
    showTopPage: function() {
      var legend = this;
      if (legend.navConfig.currentPage > 1) {
        legend.navConfig.currentPage = legend.navConfig.currentPage - 1;
        legend.nav
          .select('text')
          .text(legend.navConfig.currentPage + '/' + legend.navConfig.maxPage);
        legend.itemList
          .transition()
          .duration(legend.config.navigation.duration)
          .attr(
            'transform',
            'translate(0,' +
              legend.clipHeight * (1 - legend.navConfig.currentPage) +
              ')'
          );
      }
    }
  };

  /**
   * A Constructor for creating, updating and removing chart title component in chart.
   * @constructor
   */
  function Title() {
    this.init.apply(this, arguments);
  }
  Title.prototype = {
    /**
     * The default configuration for chart title.
     * @type {object}
     */
    defaultConfig: {
      style: {
        textAnchor: 'middle',
        fontSize: '17px'
      },
      padding: 10
    },
    /**
     * Function to destroy chart title
     */
    destroy: function() {
      var title = this,
        chart = title.chart,
        key;

      title.gElem.selectAll('*').remove();
      title.gElem.node().parentNode.removeChild(title.gElem.node());

      for (key in title) {
        delete title[key];
      }
      delete chart.title;
    },
    /**
     * Function to initialise d3 and associated functions required for chart title series
     * @param {Object} chart  Parent chart object
     * @param {Object} config  configuration object
     * @param {boolean} render  Flag whether to do rendering after initialisation
     */
    init: function(chart, config, render) {
      var title = this;
      title.chart = chart;
      title.config = merge(this.defaultConfig, config);
      title.setCSS();
      if (render !== false) {
        title.render();
      }
    },
    /**
     * Function to remove chart title
     */
    remove: function() {
      var title = this;
      title.destroy();
    },
    /**
     * Function to render chart title
     */
    render: function() {
      var title = this;
      title.firstRender = false;
      title.gElem = title.chart.gElem
        .append('g')
        .attr('class', title.chart.cssPrefix + 'Title');
      title.gElem
        .append('text')
        .attr('x', title.chart.width / 2)
        .text(title.config.text);
      title.gElem.attr(
        'transform',
        'translate(0,' + title.gElem.node().getBBox().height + ')'
      );
      title.height = title.gElem.node().getBBox().height + title.config.padding;
    },
    /**
     * Function to resize chart title
     */
    resize: function() {
      var title = this;
      title.gElem.selectAll('text').attr('x', title.chart.width / 2);
    },
    /**
     * Function to create  and append css classes to style tag for the chart title
     */
    setCSS: function() {
      var title = this;
      var cssString = '',
        i;

      cssString = cssString + ' .' + title.chart.cssPrefix + 'Title {';
      for (i in title.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          title.config.style[i] +
          ';';
      }
      cssString = cssString + '}';
      title.chart.style.node().appendChild(document.createTextNode(cssString));
    }
  };

  /**
   * A constructor for creating chartPlus instances
   * @constructor
   */

  function Hierarchy() {
    this.init.apply(this, arguments);
  }

  Hierarchy.prototype = {
    remove: function() {
      var hierarchy = this;
      d3.select(hierarchy.container)
        .select('.' + NAMESPACE + 'container')
        .remove();
      if (isObject(hierarchy.tooltip)) {
        hierarchy.tooltip.remove();
      }
      drop(charts, hierarchy);

      for (var key in hierarchy) {
        if (key !== 'dataSet') {
          delete hierarchy[key];
        } else {
          hierarchy[key] = null;
        }
      }
    },
    defaultConfig: {
      color: 'steelblue',
      rectHeight: 25,
      rectPadding: 10
    },
    idCount: 0,
    addPath: function(pathArray) {
      var hierarchy = this;
      if (!isArray(pathArray)) return;
      hierarchy.dataSet.path.push.apply(hierarchy.dataSet.path, pathArray);
      hierarchy.resize();
    },
    drill: function(data, direction) {
      var hierarchy = this;
      hierarchy.d3Layout(hierarchy.dataSet.data).forEach(function(node) {
        if (!isNumber(node.id)) {
          node.id = hierarchy.idCount;
          hierarchy.idCount++;
        }
      });
      // hierarchy.d3Layout.sort(null);
      if (direction === 'up' && isObject(data.parent)) {
        hierarchy.d3ScaleY
          .domain([
            0,
            d3Max(
              data.parent.children.map(function(d) {
                return isNumber(d.value) ? d.value : 1;
              })
            )
          ])
          .nice();
      } else if (direction === 'down' && isArray(data.children)) {
        hierarchy.d3ScaleY
          .domain([
            0,
            d3Max(
              data.children.map(function(d) {
                return isNumber(d.value) ? d.value : 1;
              })
            )
          ])
          .nice();
      }

      hierarchy.components.forEach(function(component) {
        switch (component.type) {
          case 'drillPath':
            component.display =
              isArray(hierarchy.dataSet.path) &&
              hierarchy.dataSet.path.length > 0;
            if (!!component.display) {
              hierarchy.updateDrillPath();
            }
            break;
          case 'bar':
            component.display =
              isObject(data) &&
              isArray(data.children) &&
              data.children.length > 0;
            if (!!component.display) {
              hierarchy.updateBar(data, direction);
            }
            break;
        }
      });
    },
    drillDown: function(data) {
      var hierarchy = this;
      hierarchy.ddInProgress = false;
      if (isArray(data.children) && data.children.length > 0) {
        merge(
          true,
          hierarchy.dataSet.path[hierarchy.eventMeta.length - 1].dataRef,
          data
        );
        hierarchy.drill(
          hierarchy.dataSet.path[hierarchy.eventMeta.length - 1].dataRef,
          'down'
        );
      } else {
        console.log('return');
        return;
      }
    },
    /**
     * Function to initialize hierarchy hierarchy and required sub components
     * @param {object} userConfig user configuration
     * @param {boolean} rendered svg is for exporting or not
     */
    init: function(data, config) {
      var hierarchy = this;

      // Set index and push this to the charts array
      this.index = idCounter;
      this.cssPrefix = NAMESPACE + this.index + '-';
      idCounter++;
      charts.push(this);

      hierarchy.dataSet = data;
      hierarchy.config = {};

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 1 - merge userConfig with defaultConfig
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // merge(true, hierarchy.defaultConfig.tooltip, defaultConfig.tooltip);
      hierarchy.config.style = merge(
        hierarchy.defaultConfig.style,
        defaultConfig.style
      );
      hierarchy.config = merge(
        hierarchy.config,
        hierarchy.defaultConfig,
        config
      );

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 3 - Set Color Scale
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      hierarchy.color = d3
        .scaleOrdinal()
        .domain([true, false])
        .range([hierarchy.config.color, '#cccccc']);

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 4 - Set Drilldown path if not given, derive from data
      ////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 5 - Init required d3 component
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      hierarchy.d3ScaleY = d3.scaleLinear();

      // d3.format("s")
      hierarchy.d3Layout = d3
        .hierarchy()
        .sort(null)
        .value(null); // summing up is not required

      hierarchy.symbol = d3.symbol().size(50);
      hierarchy.components = [
        {
          type: 'drillPath',
          offsetHeight: 0
        },
        {
          type: 'bar',
          offsetHeight: 20
          // maxHeight: 300
        }
      ];

      hierarchy.pageConfig = {
        pageHeight: 0,
        currentPage: 0,
        totalPage: 0
      };

      hierarchy.render();
    },
    /**
     * Function to initialise containers for svg components
     */
    initContainer: function() {
      var hierarchy = this;

      if (!hierarchy.config.container) {
        error('invalid Container reference', true);
      }
      if (isString(hierarchy.config.container)) {
        hierarchy.container = DOCUMENT.getElementById(
          hierarchy.config.container
        );
      }
      if (isElement(hierarchy.config.container)) {
        hierarchy.container = hierarchy.config.container;
      }
      if (!isElement(hierarchy.container)) {
        error('No Valid Container available', true);
      }
      hierarchy.SVGContainer = d3
        .select(hierarchy.container)
        .append('div')
        .attr('class', NAMESPACE + 'container')
        .attr('id', NAMESPACE + hierarchy.index)
        .styles({
          width: '100%',
          height: '100%',
          position: 'relative',
          'vertical-align': 'top',
          overflow: 'hidden'
        });

      hierarchy.width = getWidth(hierarchy.container);
      hierarchy.height = getHeight(hierarchy.container);

      hierarchy.d3ScaleY.range([0, hierarchy.width]);

      hierarchy.svg = hierarchy.SVGContainer.append('svg')
        .styles({
          position: 'absolute',
          top: '0',
          left: '0'
        })
        .attr('id', 'svg-' + hierarchy.index)
        .attr('width', '100%')
        .attr('height', '100%');
      if (!chartPlus.isIE) {
        hierarchy.svg
          .attr('viewBox', '0 0 ' + hierarchy.width + ' ' + hierarchy.height)
          .attr('preserveAspectRatio', 'xMinYMin meet');
      }
      hierarchy.style = hierarchy.svg.append('style').attr('type', 'text/css');

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 2 - Set CSS
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      hierarchy.svg
        .append('rect')
        .attr('class', hierarchy.cssPrefix + 'bg')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', '100%'); ////// FROM Config ?? bg - color

      hierarchy.gElem = hierarchy.svg
        .append('g')
        .classed(hierarchy.cssPrefix + 'componentGroup', true);

      hierarchy.setCSS();
    },
    /**
     * Function to render hierarchy
     */
    render: function() {
      var hierarchy = this;

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 6 - Init containers
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      hierarchy.initContainer();
      hierarchy.tooltip = new Tooltip(hierarchy, hierarchy.config.tooltip);
      hierarchy.clippath = hierarchy.svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'hierarchyClip-' + hierarchy.index);
      hierarchy.clippath
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', 0);

      if (!isObject(hierarchy.dataSet.data)) return;

      hierarchy.d3Layout(hierarchy.dataSet.data).forEach(function(node) {
        if (!isNumber(node.id)) {
          node.id = hierarchy.idCount;
          hierarchy.idCount++;
        }
      });
      // hierarchy.d3Layout.sort(null);

      hierarchy.d3ScaleY
        .domain([
          0,
          d3Max(
            hierarchy.dataSet.data.children.map(function(d) {
              return isNumber(d.value) ? d.value : 1;
            })
          )
        ])
        .nice();

      hierarchy.components.forEach(function(component) {
        switch (component.type) {
          case 'drillPath':
            component.elem = hierarchy.gElem.append('g');
            component.display =
              isArray(hierarchy.dataSet.path) &&
              hierarchy.dataSet.path.length > 0;
            if (!!component.display) {
              hierarchy.dataSet.path[0].active = true;
              hierarchy.updateDrillPath();
            }
            break;
          case 'bar':
            component.elem = hierarchy.gElem
              .append('g')
              .attr('clip-path', 'url(#hierarchyClip-' + hierarchy.index + ')');
            component.display =
              isObject(hierarchy.dataSet.data) &&
              isArray(hierarchy.dataSet.data.children) &&
              hierarchy.dataSet.data.children.length > 0;
            if (!!component.display) {
              hierarchy.updateBar(hierarchy.dataSet.data, 'down');
            }
            break;
        }
      });
    },
    resize: function() {
      var hierarchy = this;
      var x0 = hierarchy.d3ScaleY.range()[0];
      var x1 = hierarchy.width - hierarchy.d3ScaleY.range()[1];

      hierarchy.width = getWidth(hierarchy.container);
      hierarchy.height = getHeight(hierarchy.container);

      hierarchy.d3ScaleY.range([x0, hierarchy.width - x1]);

      if (!chartPlus.isIE) {
        hierarchy.svg.attr(
          'viewBox',
          '0 0 ' + hierarchy.width + ' ' + hierarchy.height
        );
      }
      hierarchy.components.forEach(function(component) {
        switch (component.type) {
          case 'drillPath':
            if (!!component.display) {
              hierarchy.updateDrillPath();
            }
            break;
          case 'bar':
            if (!!component.display) {
              hierarchy.resizeBar();
            }
            break;
        }
      });
    },
    resizeBar: function() {
      var hierarchy = this;
      var offsetHeight = 0,
        maxHeight = 0;

      for (var i = 0; i < hierarchy.components.length; i++) {
        if (hierarchy.components[i].type === 'bar') {
          offsetHeight = offsetHeight + hierarchy.components[i].offsetHeight;
          break;
        }
        offsetHeight =
          offsetHeight +
          hierarchy.components[i].offsetHeight +
          hierarchy.components[i].elem.node().getBBox().height;
      }

      var component = hierarchy.components.filter(function(component) {
        return component.type === 'bar';
      })[0];

      component.elem.attr('transform', 'translate(0,' + offsetHeight + ')');

      var dataLength = component.elem.select('.enter').selectAll('g')[0].length;

      if (
        !component.maxHeight ||
        component.maxHeight > hierarchy.height - offsetHeight
      ) {
        maxHeight = hierarchy.height - offsetHeight;
      }
      if (
        maxHeight / dataLength <
        hierarchy.config.rectHeight + hierarchy.config.rectPadding
      ) {
        hierarchy.rectHeight = (maxHeight * 0.7) / dataLength;
        hierarchy.rectPadding = (maxHeight * 0.3) / dataLength;
      } else {
        hierarchy.rectHeight = hierarchy.config.rectHeight;
        hierarchy.rectPadding = hierarchy.config.rectPadding;
      }
      var minHeight =
        component.elem
          .select('.enter')
          .select('.key')
          .node()
          .getBBox().height >
        component.elem
          .select('.enter')
          .select('.value')
          .node()
          .getBBox().height
          ? component.elem
              .select('.enter')
              .select('.key')
              .node()
              .getBBox().height
          : component.elem
              .select('.enter')
              .select('.value')
              .node()
              .getBBox().height;

      if (minHeight > hierarchy.rectHeight) {
        hierarchy.rectHeight = minHeight;
        hierarchy.rectPadding = (minHeight * 30) / 70;
      }
      var tempHeight = hierarchy.pageConfig.pageHeight;
      hierarchy.pageConfig.pageHeight =
        (hierarchy.rectHeight + hierarchy.rectPadding) *
        Math.floor(maxHeight / (hierarchy.rectHeight + hierarchy.rectPadding));
      if (tempHeight !== hierarchy.pageConfig.pageHeight) {
        hierarchy.pageConfig.totalPage = Math.ceil(
          ((hierarchy.rectHeight + hierarchy.rectPadding) * dataLength) /
            maxHeight
        );
        if (hierarchy.pageConfig.currentPage > hierarchy.pageConfig.totalPage) {
          hierarchy.pageConfig.currentPage = 1;
        }
        hierarchy.pageConfig.offsetHeight =
          -1 *
          (hierarchy.pageConfig.currentPage - 1) *
          hierarchy.pageConfig.pageHeight;
        hierarchy.updatePage();
        component.elem
          .select('.enter')
          .attr(
            'transform',
            'translate(0,' + hierarchy.pageConfig.offsetHeight + ')'
          );
      }

      component.elem
        .select('.enter')
        .selectAll('g')
        .each(function(d, i) {
          d3.select(this)
            .attr('transform', function() {
              return (
                'translate(0,' +
                (hierarchy.rectHeight + hierarchy.rectPadding) * i +
                ')'
              );
            })
            .classed('drillDown', function(d) {
              return (
                isArray(hierarchy.dataSet.path) &&
                d.depth < hierarchy.dataSet.path.length
              );
            });
          d3.select(this)
            .select('rect')
            .attr('width', function() {
              if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
                return 1;
              } else {
                return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
              }
            })
            .attr('height', hierarchy.rectHeight)
            .style('fill', function(d) {
              return hierarchy.color(
                isArray(hierarchy.dataSet.path) &&
                  d.depth < hierarchy.dataSet.path.length
              );
            });
          d3.select(this)
            .select('.value')
            .attr('y', hierarchy.rectHeight / 2)
            .attr('x', function() {
              return hierarchy.scaleY(d.value);
            });
          d3.select(this)
            .select('.key')
            .attr('y', hierarchy.rectHeight / 2)
            .attr('x', hierarchy.scaleY(0));
        });
      hierarchy.clippath
        .select('rect')
        .attr(
          'height',
          hierarchy.pageConfig.pageHeight - hierarchy.rectPadding / 2
        );
    },
    scaleY: function(number) {
      var hierarchy = this;
      if (isNumber(number)) {
        return hierarchy.d3ScaleY(number);
      } else {
        return hierarchy.d3ScaleY(0);
      }
    },
    scrollBars: function(direction) {
      var hierarchy = this;

      var component = hierarchy.components.filter(function(component) {
        return component.type === 'bar';
      })[0];
      if (direction === 'up') {
        hierarchy.pageConfig.offsetHeight =
          -1 *
          (hierarchy.pageConfig.currentPage - 2) *
          hierarchy.pageConfig.pageHeight;
        if (hierarchy.pageConfig.offsetHeight > 0) {
          return;
        }
        hierarchy.pageConfig.currentPage = hierarchy.pageConfig.currentPage - 1;
      } else if (direction === 'down') {
        hierarchy.pageConfig.offsetHeight =
          -1 *
          hierarchy.pageConfig.currentPage *
          hierarchy.pageConfig.pageHeight;
        if (
          Math.abs(hierarchy.pageConfig.offsetHeight) >
          component.elem
            .select('.enter')
            .node()
            .getBBox().height
        ) {
          return;
        }
        hierarchy.pageConfig.currentPage = hierarchy.pageConfig.currentPage + 1;
      }

      hierarchy.updatePage();
      component.elem
        .select('.enter')
        .transition()
        .duration(1000)
        .attr(
          'transform',
          'translate(0,' + hierarchy.pageConfig.offsetHeight + ')'
        );
    },
    setCSS: function() {
      var hierarchy = this;
      var cssString = '',
        component,
        key,
        classStart = ' .',
        classOpen = ' {',
        classClose = '} ',
        i;

      // set background class
      cssString =
        cssString +
        classStart +
        hierarchy.cssPrefix +
        'bg' +
        classOpen +
        'fill:' +
        themeConfig.backgroundColor +
        ';' +
        classClose;

      if (getContrastYIQ(themeConfig.backgroundColor) === 'dark') {
        cssString =
          cssString +
          '#svg-' +
          hierarchy.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastDarkColor +
          ';';
      } else {
        cssString =
          cssString +
          '#svg-' +
          hierarchy.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastLightColor +
          ';';
      }

      for (i in hierarchy.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          hierarchy.config.style[i] +
          ';';
      }
      cssString = cssString + classClose;

      hierarchy.style.node().appendChild(document.createTextNode(cssString));
    },
    updateBar: function(data, direction) {
      var hierarchy = this;

      var offsetHeight = 0,
        dataLength = 1,
        maxHeight = 0;

      if (!isArray(data.children)) return;

      for (var i = 0; i < hierarchy.components.length; i++) {
        if (hierarchy.components[i].type === 'bar') {
          offsetHeight = offsetHeight + hierarchy.components[i].offsetHeight;
          break;
        }
        offsetHeight =
          offsetHeight +
          hierarchy.components[i].offsetHeight +
          hierarchy.components[i].elem.node().getBBox().height;
      }

      var component = hierarchy.components.filter(function(component) {
        return component.type === 'bar';
      })[0];

      component.elem.attr('transform', 'translate(0,' + offsetHeight + ')');
      if (direction === 'up') {
        dataLength = data.parent.children.length;
      } else {
        dataLength = data.children.length;
      }

      if (
        !component.maxHeight ||
        component.maxHeight > hierarchy.height - offsetHeight
      ) {
        maxHeight = hierarchy.height - offsetHeight;
      }
      if (
        maxHeight / dataLength <
        hierarchy.config.rectHeight + hierarchy.config.rectPadding
      ) {
        hierarchy.rectHeight = (maxHeight * 0.7) / dataLength;
        hierarchy.rectPadding = (maxHeight * 0.3) / dataLength;
      } else {
        hierarchy.rectHeight = hierarchy.config.rectHeight;
        hierarchy.rectPadding = hierarchy.config.rectPadding;
      }

      if (direction === 'up') {
        hierarchy.events.displayMatrix.data = data.parent;
        hierarchy.container.dispatchEvent(hierarchy.events.displayMatrix);
        up(hierarchy, component.elem, data);

        hierarchy.pageConfig.pageHeight =
          (hierarchy.rectHeight + hierarchy.rectPadding) *
          Math.floor(
            maxHeight / (hierarchy.rectHeight + hierarchy.rectPadding)
          );
        hierarchy.clippath
          .select('rect')
          .attr('y', 0)
          .attr(
            'height',
            hierarchy.pageConfig.pageHeight - hierarchy.rectPadding / 2
          );

        hierarchy.pageConfig.currentPage = 1;
        hierarchy.pageConfig.totalPage = Math.ceil(
          ((hierarchy.rectHeight + hierarchy.rectPadding) * dataLength) /
            maxHeight
        );
        hierarchy.updatePage();
      } else {
        hierarchy.events.displayMatrix.data = data;
        hierarchy.container.dispatchEvent(hierarchy.events.displayMatrix);
        down(hierarchy, component.elem, data);
        hierarchy.pageConfig.pageHeight =
          (hierarchy.rectHeight + hierarchy.rectPadding) *
          Math.floor(
            maxHeight / (hierarchy.rectHeight + hierarchy.rectPadding)
          );
        hierarchy.clippath
          .select('rect')
          .attr('y', 0)
          .attr(
            'height',
            hierarchy.pageConfig.pageHeight - hierarchy.rectPadding / 2
          );

        hierarchy.pageConfig.currentPage = 1;
        hierarchy.pageConfig.totalPage = Math.ceil(
          ((hierarchy.rectHeight + hierarchy.rectPadding) * dataLength) /
            maxHeight
        );
        hierarchy.updatePage();
      }
    },
    updateDrillPath: function() {
      var hierarchy = this;
      var tempWidth = 0,
        tempHeight = 0,
        offsetHeight = 0,
        i;
      var pathElem, pageElem;

      for (i = 0; i < hierarchy.components.length; i++) {
        if (hierarchy.components[i].type === 'drillPath') {
          offsetHeight = offsetHeight + hierarchy.components[i].offsetHeight;
          break;
        }
        offsetHeight =
          offsetHeight +
          hierarchy.components[i].offsetHeight +
          hierarchy.components[i].elem.node().getBBox().height;
      }

      var component = hierarchy.components.filter(function(component) {
        return component.type === 'drillPath';
      })[0];

      component.elem.attr('transform', 'translate(0,' + offsetHeight + ')');

      pathElem = component.elem.select('.pathElem');

      if (!pathElem.node()) {
        pathElem = component.elem.append('g').attr('class', 'pathElem');
      }

      var drillPath = pathElem
        .on('click', function() {
          var d = d3.event.target.__data__;
          if (!d.drillUp) return;

          if (isObject(d.dataRef)) {
            if (isArray(hierarchy.dataSet.path)) {
              hierarchy.dataSet.path.forEach(function(path, idx) {
                path.active = idx < d.dataRef.depth;
                path.drillUp = idx < d.dataRef.depth - 1;
              });
            }
            hierarchy.drill(d.dataRef, 'up');
          }
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          // Functions to drill up
          ////////////////////////////////////////////////////////////////////////////////////////////////////
        })
        .selectAll('.drillPath')
        .data(hierarchy.dataSet.path);

      drillPath
        .enter()
        .append('g')
        .attr('class', 'drillPath')
        .append('text');

      pathElem
        .selectAll('text')
        .append('tspan')
        .attr('class', 'name')
        .style({ 'font-weight': 'bold' });
      pathElem
        .selectAll('text')
        .append('tspan')
        .attr('class', 'icon');

      pathElem.selectAll('text').each(function(d, i) {
        d3.select(this)
          .select('.name')
          .text(d.name);
        if (i < hierarchy.dataSet.path.length - 1) {
          d3.select(this)
            .select('.icon')
            .text('\u2004\u2771\u2004');
        }
        //&#x2771;
        // return ((hierarchy.dataSet.path.indexOf(d) > 0) ? '&nbsp;&#x203a;&nbsp;' : '') + d.name;
      });

      tempHeight = pathElem
        .select('g')
        .node()
        .getBBox().height;

      pathElem
        .selectAll('g')
        .attr('transform', function(d, i) {
          var translate = 'translate(' + tempWidth + ',' + tempHeight + ')';
          tempWidth =
            tempWidth +
            d3
              .select(this)
              .node()
              .getBBox().width;
          return translate;
        })
        .classed('active', function(d, i) {
          d3.select(this)
            .select('.name')
            .classed('drillUp', !!d.drillUp);
          return !!d.active;
        });

      drillPath.exit().remove();

      pageElem = component.elem.select('.pageElem');

      if (!pageElem.node()) {
        pageElem = component.elem
          .append('g')
          .style('opacity', 1e-6)
          .attr('class', 'pageElem');
        hierarchy.symbol.type('triangle-up');
        pageElem
          .append('path')
          .attr('class', 'pageUp')
          .attr('d', hierarchy.symbol)
          // .html('&nbsp;&#9650;&nbsp;')
          .on('click', function() {
            hierarchy.scrollBars('up');
          });
        pageElem
          .append('text')
          .attr('class', 'pageCount')
          .text(
            hierarchy.pageConfig.currentPage +
              '/' +
              hierarchy.pageConfig.totalPage
          );

        hierarchy.symbol.type('triangle-down');
        pageElem
          .append('path')
          .attr('class', 'pageDown')
          .attr('d', hierarchy.symbol)
          // .html('&nbsp;&#9660;&nbsp;')
          .on('click', function() {
            hierarchy.scrollBars('down');
          });
      }

      pageElem.attr(
        'transform',
        'translate(' + pathElem.node().getBBox().width + ',0)'
      );
      tempWidth = 0;

      var navHeight = pageElem.node().getBBox().height;

      tempWidth =
        tempWidth +
        pageElem
          .select('.pageUp')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageUp')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageUp')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageCount')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageCount')
          .node()
          .getBBox().width;
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageDown')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageDown')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');

      // pageElem.selectAll('*').each(function() {

      //     tempWidth = tempWidth + d3.select(this).node().getBBox().width;
      //     console.log(tempWidth, d3.select(this).node().getBBox().width);
      //     d3.select(this).attr('transform', 'translate(' + tempWidth + ',' + (navHeight) + ')');
      //     // tempWidth = tempWidth + d3.select(this).node().getBBox().width;
      // });
    },
    //update text and color of page
    updatePage: function() {
      var hierarchy = this;
      var pathElem,
        pageElem,
        tempWidth = 0;

      var component = hierarchy.components.filter(function(component) {
        return component.type === 'drillPath';
      })[0];

      pageElem = component.elem.select('.pageElem');
      if (hierarchy.pageConfig.totalPage > 1) {
        hierarchy.pageConfig.display = true;
        pageElem.style('opacity', 1);
      } else {
        hierarchy.pageConfig.display = false;
        pageElem.style('opacity', 1e-6);
      }
      pageElem
        .select('.pageUp')
        .classed('pageDisabled', hierarchy.pageConfig.currentPage === 1);
      pageElem
        .select('.pageDown')
        .classed(
          'pageDisabled',
          hierarchy.pageConfig.currentPage === hierarchy.pageConfig.totalPage
        );
      pageElem
        .select('.pageCount')
        .text(
          hierarchy.pageConfig.currentPage +
            '/' +
            hierarchy.pageConfig.totalPage
        );
      var navHeight = pageElem.node().getBBox().height;
      tempWidth = 0;
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageUp')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageUp')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageUp')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageCount')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageCount')
          .node()
          .getBBox().width;
      tempWidth =
        tempWidth +
        pageElem
          .select('.pageDown')
          .node()
          .getBBox().width;
      pageElem
        .select('.pageDown')
        .attr('transform', 'translate(' + tempWidth + ',' + navHeight + ')');

      // pageElem.selectAll('*').each(function() {
      //     // tempWidth = tempWidth + d3.select(this).node().getBBox().width;
      //     console.log(tempWidth, d3.select(this).node().getBBox().width);
      //     d3.select(this).attr('transform', 'translate(' + tempWidth + ',' + (navHeight) + ')');
      //     tempWidth = tempWidth + d3.select(this).node().getBBox().width;
      // });
    }
  };

  function down(context, elem, data) {
    var hierarchy = context,
      index = 0,
      i = 0;
    if (!isArray(data.children)) return;

    var duration = d3.event && d3.event.altKey ? 7500 : 750,
      delay = duration / data.children.length;

    if (isObject(data.parent)) {
      for (i = 0; i < data.parent.children.length; i++) {
        if (data.id === data.parent.children[i].id) {
          index = i;
          break;
        }
      }
    }

    // Mark any currently-displayed bars as exiting.
    var exit = elem.selectAll('.enter').attr('class', 'exit');

    // Entering nodes immediately obscure the clicked-on bar, so hide it.
    exit
      .selectAll('rect')
      .filter(function(p) {
        return p === data;
      })
      .style('opacity', 1e-6);

    hierarchy.d3ScaleY
      .domain([
        0,
        d3.sum(
          data.children.map(function(d) {
            return isNumber(d.value) ? d.value : 1;
          })
        )
      ])
      .nice();

    var tempX = 0,
      translate;

    var enter = drawBars(hierarchy, elem, data)
      .attr('transform', function(d) {
        translate =
          'translate(' +
          tempX +
          ',' +
          (hierarchy.rectHeight + hierarchy.rectPadding) * index +
          ')';
        tempX =
          tempX +
          (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1
            ? 1
            : hierarchy.scaleY(d.value) - hierarchy.scaleY(0));
        return translate;
      })
      .style('opacity', 1);

    enter.selectAll('text').style('opacity', 1e-6);

    enter.select('rect').style('fill', function(d) {
      return hierarchy.color(
        isArray(hierarchy.dataSet.path) &&
          d.depth < hierarchy.dataSet.path.length
      );
    });

    hierarchy.d3ScaleY
      .domain([
        0,
        d3Max(
          data.children.map(function(d) {
            return isNumber(d.value) ? d.value : 1;
          })
        )
      ])
      .nice();

    var enterTransition = enter
      .transition()
      .duration(duration)
      .delay(function(d, i) {
        return i * delay;
      })
      .attr('transform', function(d, i) {
        return (
          'translate(0,' +
          (hierarchy.rectHeight + hierarchy.rectPadding) * i +
          ')'
        );
      });
    enterTransition
      .selectAll('.value')
      .attr('x', function(d) {
        return hierarchy.scaleY(d.value);
      })
      .style('opacity', 1);

    enterTransition
      .select('.key')
      .attr('x', hierarchy.scaleY(0))
      .style('opacity', 1);

    // Transition entering rects to the new x-scale.
    enterTransition
      .select('rect')
      .attr('x', hierarchy.scaleY(0))
      .attr('width', function(d) {
        if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
          return 1;
        } else {
          return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
        }
      })
      .style('fill', function(d) {
        return hierarchy.color(
          isArray(hierarchy.dataSet.path) &&
            d.depth < hierarchy.dataSet.path.length
        );
      });

    // Transition exiting bars to fade out.
    var exitTransition = exit
      .transition()
      .duration(duration)
      .style('opacity', 1e-6)
      .remove();

    // Transition exiting bars to the new x-scale.
    exitTransition
      .selectAll('rect')
      .attr('x', hierarchy.scaleY(0))
      .attr('width', function(d) {
        if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
          return 1;
        } else {
          return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
        }
      });
  }

  function up(context, elem, data) {
    var hierarchy = context,
      index = 0,
      i = 0;

    if (!isObject(data.parent)) return;

    var duration = d3.event && d3.event.altKey ? 7500 : 750,
      delay = duration / data.children.length;
    var end = duration + data.children.length * delay;

    for (i = 0; i < data.parent.children.length; i++) {
      if (data.id === data.parent.children[i].id) {
        index = i;
        break;
      }
    }

    var tempX = 0,
      translate;

    // Mark any currently-displayed bars as exiting.
    var exit = elem.selectAll('.enter').attr('class', 'exit');

    hierarchy.d3ScaleY
      .domain([
        0,
        d3.sum(
          data.children.map(function(d) {
            return isNumber(d.value) ? d.value : 1;
          })
        )
      ])
      .nice();

    // Transition exiting bars to the parent's position.
    var exitTransition = exit
      .selectAll('g')
      .transition()
      .duration(duration)
      .delay(function(d, i) {
        return i * delay;
      })
      .attr('transform', function(d) {
        translate =
          'translate(' +
          tempX +
          ',' +
          (hierarchy.rectHeight + hierarchy.rectPadding) * index +
          ')';
        tempX =
          tempX +
          (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1
            ? 1
            : hierarchy.scaleY(d.value) - hierarchy.scaleY(0));
        return translate;
      });

    // Transition exiting text to fade out.
    exitTransition.selectAll('text').style('opacity', 1e-6);

    // Transition exiting bars to the new x-scale.
    exitTransition
      .selectAll('rect')
      .attr('width', function(d) {
        if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
          return 1;
        } else {
          return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
        }
      })
      .style('fill', hierarchy.color(true))
      .style('opacity', 1e-6);

    // Remove exiting nodes when the last child has finished transitioning.
    exit
      .transition()
      .duration(end)
      .remove();

    hierarchy.d3ScaleY
      .domain([
        0,
        d3Max(
          data.parent.children.map(function(d) {
            return isNumber(d.value) ? d.value : 1;
          })
        )
      ])
      .nice();

    var enter = drawBars(hierarchy, elem, data.parent)
      .attr('transform', function(d, i) {
        return (
          'translate(0,' +
          (hierarchy.rectHeight + hierarchy.rectPadding) * i +
          ')'
        );
      })
      .style('opacity', 1e-6);

    enter.select('rect').style('fill', function(d) {
      return hierarchy.color(
        isArray(hierarchy.dataSet.path) &&
          d.depth < hierarchy.dataSet.path.length
      );
    });

    // Transition entering bars to fade in over the full duration.
    var enterTransition = enter
      .transition()
      .duration(end)
      .style('opacity', 1);

    // Transition entering rects to the new x-scale.
    // When the entering parent rect is done, make it visible!
    enterTransition.select('rect').attr('width', function(d) {
      if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
        return 1;
      } else {
        return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
      }
    });

    enterTransition.selectAll('.value').attr('x', function(d) {
      return hierarchy.scaleY(d.value);
    });
  }

  function drawBars(context, elem, data) {
    var hierarchy = context;
    var bar = elem
      .append('g')
      .attr('class', 'enter')
      .on('click', function() {
        if (!!hierarchy.ddInProgress) return;
        // check if user has clicked on bar value
        if (
          d3.event.target.tagName == 'rect' ||
          d3.event.target.attributes.class.value !== 'value'
        ) {
          var d = d3.event.target.__data__;
          hierarchy.eventMeta = [];
          if (isArray(hierarchy.dataSet.path)) {
            hierarchy.dataSet.path.forEach(function(path, idx) {
              if (d.depth - 1 === idx) {
                path.dataRef = d;
              }
              path.active = idx <= d.depth;
              path.drillUp = idx < d.depth;
              if (!!path.drillUp) {
                hierarchy.eventMeta.push(path.dataRef.key);
              }
            });
          }
          if (isArray(d.children) && d.children.length > 0) {
            hierarchy.drill(d, 'down');
          } else if (d.depth < hierarchy.dataSet.path.length) {
            console.log('fetch data');
            hierarchy.ddInProgress = true;
            hierarchy.events.getDrillDownData.data = hierarchy.eventMeta;
            hierarchy.container.dispatchEvent(
              hierarchy.events.getDrillDownData
            );
          }
        } else {
          return;
        }
      })
      .on('mouseover', function() {
        var d = d3.event.target.__data__;
        var details = [];

        if (d3.event.target.tagName == 'rect') {
          hierarchy.tooltip.show({ x: d.key, y: d.value }, 'Value');
        } else if (
          d3.event.target.attributes.class.value === 'value' &&
          isObject(d.meta)
        ) {
          Object.keys(d.meta)
            .reverse()
            .forEach(function(key) {
              details.push({ key: key, value: d.meta[key] });
            });
          hierarchy.tooltip.show(details);
        }
      })
      .on('mouseout', function() {
        hierarchy.tooltip.hide();
      })
      .selectAll('g')
      .data(data.children)
      .enter()
      .append('g')
      .classed('drillDown', function(d) {
        return (
          isArray(hierarchy.dataSet.path) &&
          d.depth < hierarchy.dataSet.path.length
        );
      });

    bar
      .append('text')
      .attr('class', 'key')
      .attr('dy', '.35em')
      .style('text-anchor', 'start')
      .text(function(d) {
        return d.key + '\u2000';
      });
    var x0 = elem
      .select('.enter')
      .node()
      .getBBox().width;
    bar.append('rect');
    bar
      .append('text')
      .attr('class', 'value')
      .attr('x', x0)
      .attr('dy', '.35em')
      .style('text-anchor', 'start')
      .text(function(d) {
        if (isNumber(d.value)) {
          return '\u2000' + d.value + '\u2004';
        } else {
          return '\u2000' + NaNString + '\u2004';
        }
      });
    var x1 = elem
      .select('.enter')
      .node()
      .getBBox().width;

    hierarchy.d3ScaleY.range([x0, hierarchy.width - (x1 - x0)]);

    var minHeight =
      bar
        .select('.key')
        .node()
        .getBBox().height >
      bar
        .select('.value')
        .node()
        .getBBox().height
        ? bar
            .select('.key')
            .node()
            .getBBox().height
        : bar
            .select('.value')
            .node()
            .getBBox().height;
    if (minHeight > hierarchy.config.rectHeight) {
      hierarchy.config.rectHeight = minHeight;
      hierarchy.config.rectPadding = (minHeight * 30) / 70;
    }
    if (minHeight > hierarchy.rectHeight) {
      hierarchy.rectHeight = minHeight;
      hierarchy.rectPadding = (minHeight * 30) / 70;
    }

    bar
      .select('.key')
      .attr('x', hierarchy.scaleY(0))
      .attr('y', hierarchy.rectHeight / 2)
      .style('text-anchor', 'end');

    bar
      .select('rect')
      .attr('x', hierarchy.scaleY(0))
      .attr('width', function(d) {
        if (hierarchy.scaleY(d.value) - hierarchy.scaleY(0) <= 1) {
          return 1;
        } else {
          return hierarchy.scaleY(d.value) - hierarchy.scaleY(0);
        }
      })
      .attr('height', hierarchy.rectHeight);

    bar
      .select('.value')
      .attr('y', hierarchy.rectHeight / 2)
      .attr('x', function(d) {
        return hierarchy.scaleY(d.value);
      });

    //hierarchy.config.rectHeight + hierarchy.config.rectPadding
    // if (bar.select('.key').node().getBBox().height > (hierarchy.rectHeight + hierarchy.rectPadding)) {
    //     bar.select('.key').style('font-size', (hierarchy.rectHeight + hierarchy.rectPadding) + 'px');
    // }
    // if (bar.select('.value').node().getBBox().height > (hierarchy.rectHeight + hierarchy.rectPadding)) {
    //     bar.select('.value').style('font-size', (hierarchy.rectHeight + hierarchy.rectPadding) + 'px');
    // }

    return bar;
  }

  /**
   * A constructor for creating chartPlus instances
   * @constructor
   */

  function Sparkline() {
    this.init.apply(this, arguments);
  }

  Sparkline.prototype = {
    remove: function() {
      var chart = this;
      d3.select(chart.container)
        .select('.' + NAMESPACE + 'container')
        .remove();
      drop(charts, chart);

      for (var key in chart) {
        delete chart[key];
      }
    },
    defaultConfig: {
      color: '#b3b3b3',
      thickness: 4
    },
    /**
     * Function to initialize chart chart and required sub components
     * @param {object} userConfig user configuration
     * @param {boolean} rendered svg is for exporting or not
     */
    init: function(config) {
      var chart = this;

      // Set index and push this to the charts array
      this.index = idCounter;
      this.cssPrefix = NAMESPACE + this.index + '-';
      idCounter++;
      charts.push(this);

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 1 - merge userConfig with defaultConfig
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      chart.config = merge(chart.defaultConfig, config);
      chart.config.series[0].data.forEach(function(data) {
        data.x = parseDate(data.x);
        data.y = !!data.y ? data.y : 0;
      });

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 5 - Init required d3 component
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      chart.d3ScaleY = d3.scaleLinear();
      chart.d3ScaleX = d3.scaleTime();

      chart.d3Line = d3
        .line()
        .x(function(d, i) {
          return chart.d3ScaleX(d.x);
        })
        .y(function(d) {
          return chart.d3ScaleY(d.y);
        });
      chart.components = [
        {
          type: 'line',
          offsetHeight: 0
        }
      ];

      chart.render();
    },
    /**
     * Function to initialise containers for svg components
     */
    initContainer: function() {
      var chart = this;

      if (!chart.config.container) {
        error('invalid Container reference', true);
      }
      if (isString(chart.config.container)) {
        chart.container = DOCUMENT.getElementById(chart.config.container);
      }
      if (isElement(chart.config.container)) {
        chart.container = chart.config.container;
      }
      if (!isElement(chart.container)) {
        error('No Valid Container available', true);
      }

      composeDOM(chart.container, DIV, {
        className: NAMESPACE + 'container',
        id: NAMESPACE + chart.index
      });

      chart.width = getWidth(chart.container);
      chart.height = getHeight(chart.container);

      chart.d3ScaleY.range([
        chart.height - chart.config.thickness,
        chart.config.thickness
      ]);
      chart.d3ScaleX.range([0, chart.width]);

      chart.svg = d3
        .select(accessDOM(NAMESPACE + chart.index, 'id'))
        .append('svg')
        .styles({
          position: 'absolute',
          top: '0',
          left: '0'
        })
        .attr('id', 'svg-' + chart.index)
        .attr('width', '100%')
        .attr('height', '100%');

      if (!chartPlus.isIE) {
        chart.svg
          .attr('viewBox', '0 0 ' + chart.width + ' ' + chart.height)
          .attr('preserveAspectRatio', 'xMinYMin meet');
      }
      chart.style = chart.svg.append('style').attr('type', 'text/css');

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 2 - Set CSS
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      chart.svg
        .append('rect')
        .attr('class', chart.cssPrefix + 'bg')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '100%')
        .attr('height', '100%'); ////// FROM Config ?? bg - color

      chart.gElem = chart.svg
        .append('g')
        .classed(chart.cssPrefix + 'componentGroup', true);

      chart.setCSS();
    },
    /**
     * Function to render chart
     */
    render: function() {
      var chart = this;

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // 6 - Init containers
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      chart.initContainer();
      chart.tooltip = new Tooltip(chart, chart.config.tooltip);

      // if (!isObject(chart.dataSet.data)) return;

      chart.d3ScaleY
        .domain([
          d3Min(
            chart.config.series[0].data.map(function(d) {
              return d.y;
            })
          ),
          d3Max(
            chart.config.series[0].data.map(function(d) {
              return d.y;
            })
          )
        ])
        .nice();

      chart.d3ScaleX.domain([
        d3.timeDay.offset(parseDate(chart.config.xAxis.startDate), -1),
        d3.timeDay.offset(parseDate(chart.config.xAxis.endDate), +1)
      ]);

      chart.components.forEach(function(component) {
        switch (component.type) {
          case 'line':
            component.elem = chart.gElem.append('g');
            component.display = isArray(chart.config.series[0].data);
            if (!!component.display) {
              chart.updateLine(chart.config.series[0].data);
            }

            break;
        }
      });
    },
    resize: function() {
      var chart = this;
      chart.width = getWidth(chart.container);
      chart.height = getHeight(chart.container);

      chart.d3ScaleY.range([
        chart.height - chart.config.thickness,
        chart.config.thickness
      ]);
      chart.d3ScaleX.range([0, chart.width]);

      if (!chartPlus.isIE) {
        chart.svg.attr('viewBox', '0 0 ' + chart.width + ' ' + chart.height);
      }
      chart.components.forEach(function(component) {
        switch (component.type) {
          case 'line':
            if (!!component.display) {
              chart.resizeLine();
            }

            break;
        }
      });
    },
    resizeLine: function() {
      var chart = this;
      var offsetHeight = 0;

      for (var i = 0; i < chart.components.length; i++) {
        if (chart.components[i].type === 'line') {
          offsetHeight = offsetHeight + chart.components[i].offsetHeight;
          break;
        }
        offsetHeight =
          offsetHeight +
          chart.components[i].offsetHeight +
          chart.components[i].elem.node().getBBox().height;
      }

      var component = chart.components.filter(function(component) {
        return component.type === 'line';
      })[0];

      component.elem.attr('transform', 'translate(0,' + offsetHeight + ')');

      component.elem.select('path').attr('d', function(d) {
        return chart.d3Line(d);
      });
    },
    setCSS: function() {
      var chart = this;
      var cssString = '',
        component,
        key,
        classStart = ' .',
        classOpen = ' {',
        classClose = '} ',
        i;

      // set background class
      cssString =
        cssString +
        classStart +
        chart.cssPrefix +
        'bg' +
        classOpen +
        'fill:' +
        themeConfig.backgroundColor +
        ';' +
        classClose;

      if (getContrastYIQ(themeConfig.backgroundColor) === 'dark') {
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastDarkColor +
          ';';
      } else {
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastLightColor +
          ';';
      }

      for (i in chart.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          chart.config.style[i] +
          ';';
      }
      cssString = cssString + classClose;

      chart.style.node().appendChild(document.createTextNode(cssString));
    },
    updateLine: function() {
      var chart = this;
      var offsetHeight = 0;

      for (var i = 0; i < chart.components.length; i++) {
        if (chart.components[i].type === 'line') {
          offsetHeight = offsetHeight + chart.components[i].offsetHeight;
          break;
        }
        offsetHeight =
          offsetHeight +
          chart.components[i].offsetHeight +
          chart.components[i].elem.node().getBBox().height;
      }

      var component = chart.components.filter(function(component) {
        return component.type === 'line';
      })[0];

      component.elem.attr('transform', 'translate(0,' + offsetHeight + ')');

      component.elem
        .append('path')
        .attr('class', 'sparkline')
        .style('stroke', chart.config.color)
        .style('stroke-width', chart.config.thickness)
        .datum(chart.config.series[0].data)
        .attr('d', function(d) {
          return chart.d3Line(d);
        });
    }
  };

  /**
   * A constructor for creating data multiStack using chartPlus
   * @constructor
   */

  function MultiStack() {
    this.init.apply(this, arguments);
  }
  MultiStack.prototype = {
    defaultConfig: {
      type: 'bar',
      color: '#1f77b4',
      wrap: true,
      maxRows: 5,
      padding: 15,
      legendSpacing: 20, // space between each sparkline items
      textSpacing: 10, // space between legend text sparkline
      sparklineWidth: 30, // space between legend text sparkline
      breakpoint: 350,
      navigation: {
        padding: 3,
        duration: 800,
        style: {
          fontSize: '11px'
        }
      },
      style: {
        fontFamily: 'arial',
        fontSize: '14px',
        fontWeight: '400',
        fill: '#091e42'
      },
      export: {
        width: 700,
        height: 400
      }
    },
    /**
     * Function to initialize data multiStack and required sub components
     * @param {object} userConfig user configuration
     * @param {boolean} rendered svg is for exporting or not
     */
    init: function(userConfig, forExport) {
      var multiStack = this;
      var config;

      // Set index and push this to the charts array
      multiStack.index = idCounter;
      multiStack.cssPrefix = NAMESPACE + multiStack.index + '-';
      idCounter++;
      this.forExport = forExport;
      if (this.forExport !== true) {
        charts.push(multiStack);
      }

      config = merge(this.defaultConfig, userConfig);
      this.config = config;
      this.userConfig = userConfig;

      if (!!config.xAxis && config.xAxis.type === 'date') {
        multiStack.config.type = 'line';
        multiStack.xScale = d3.scaleTime();

        multiStack.timeline = true;
        multiStack.setDomain();
      } else {
        multiStack.xScale = d3.scaleOrdinal();
        multiStack.setDomain();
      }

      multiStack.yScale = d3.scaleLinear();

      multiStack.d3Line = d3
        .line()
        .x(function(d, i) {
          return multiStack.xScale(d.x);
        })
        .y(function(d) {
          return multiStack.yScale(d.y);
        });

      multiStack.getData();

      // set rect width
      multiStack.render();
    },
    /**
     * Function to initialise containers for multiStack components
     */
    initContainer: function() {
      var multiStack = this;
      if (!multiStack.config.container) {
        error('invalid Container reference', true);
      }
      if (isString(multiStack.config.container)) {
        multiStack.container = DOCUMENT.getElementById(
          multiStack.config.container
        );
      }
      if (isElement(multiStack.config.container)) {
        multiStack.container = multiStack.config.container;
      }
      if (!isElement(multiStack.container)) {
        error('No Valid Container available', true);
      }

      composeDOM(multiStack.container, DIV, {
        className: NAMESPACE + 'container',
        id: NAMESPACE + multiStack.index
      });

      multiStack.width = getWidth(multiStack.container);
      multiStack.height = getHeight(multiStack.container);
      console.log(
        'initContainer' +
          'chart.width:' +
          multiStack.width +
          'chart.height:' +
          multiStack.height
      );

      if (multiStack.forExport !== true) {
        multiStack.svg = d3
          .select(accessDOM(NAMESPACE + multiStack.index, 'id'))
          .append('svg')
          .attr('id', 'svg-' + multiStack.index)
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('class', NAMESPACE + 'svgComponents');
      } else {
        // firefox export fix

        multiStack.svg = d3
          .select(accessDOM(NAMESPACE + multiStack.index, 'id'))
          .append('svg')
          .attr('id', 'svg-' + multiStack.index)
          .attr('width', multiStack.width + 'px')
          .attr('height', multiStack.height + 'px')
          .attr('class', NAMESPACE + 'svgComponents');
      }

      multiStack.style = multiStack.svg
        .append('style')
        .attr('type', 'text/css');

      multiStack.setCSS();

      multiStack.gElemRoot = multiStack.svg
        .append('g')
        .style('pointer-events', 'all');
      multiStack.gElemRoot.classed(
        multiStack.cssPrefix + 'componentGroup',
        true
      );
    },
    /**
     * Function to remove multiStack
     */
    remove: function() {
      var multiStack = this;

      multiStack.destroy();
    },
    /**
     * Function to destroy multiStack
     */
    destroy: function() {
      var multiStack = this,
        key;

      if (multiStack.navigation === true) {
        multiStack.nav.selectAll('*').remove();
        multiStack.nav.node().parentNode.removeChild(multiStack.nav.node());
      }

      multiStack.items.selectAll('*').remove();
      multiStack.items.node().parentNode.removeChild(multiStack.items.node());
      multiStack.content.selectAll('*').remove();
      multiStack.content
        .node()
        .parentNode.removeChild(multiStack.content.node());
      multiStack.gElem.selectAll('*').remove();
      multiStack.gElem.node().parentNode.removeChild(multiStack.gElem.node());
      multiStack.clippath.selectAll('*').remove();
      multiStack.clippath
        .node()
        .parentNode.removeChild(multiStack.clippath.node());

      drop(charts, multiStack);

      d3.select(multiStack.container)
        .select('.' + NAMESPACE + 'container')
        .remove();

      for (key in multiStack) {
        delete multiStack[key];
      }
    },
    /**
     * Function to render multiStack
     */
    render: function() {
      var multiStack = this;
      multiStack.initContainer();
      var legendName;

      multiStack.clippath = multiStack.svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'legendClip-' + multiStack.index);
      multiStack.clippath
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 0)
        .attr('height', 0);

      multiStack.gElem = multiStack.gElemRoot
        .append('g')
        .attr('class', multiStack.cssPrefix + 'legend');

      multiStack.content = multiStack.gElem.append('g');

      multiStack.itemList = multiStack.content.append('g');

      multiStack.items = multiStack.itemList
        .selectAll('.sparkLineItem')
        .data(multiStack.data)
        .enter()
        .append('g');

      multiStack.items
        .append('text')
        .attr('x', multiStack.config.padding) //15
        .attr('y', multiStack.config.sparklineWidth)
        .text(function(d, i) {
          if (!!multiStack.config.legendMap) {
            legendName = multiStack.config.legendMap[d.name];
            legendName = setDefaultVal(legendName, 'unknown');
          } else {
            legendName = d.name;
          }

          return legendName;
        });

      var maxItemWidth = 0;

      multiStack.items.each(function(item) {
        if (maxItemWidth < this.getBBox().width) {
          maxItemWidth = this.getBBox().width;
        }
      });

      multiStack.items
        .append('path')
        .attr('d', function(d, i) {
          var range = d3.extent(multiStack.config.series[i].data, function(a) {
            if (multiStack.timeline === true && !isDate(a.x)) {
              a.x = parseDate(a.x);
            }
            return a.y;
          });

          multiStack.yScale
            .domain(range)
            .range([multiStack.config.sparklineWidth, 0]);
          multiStack.xScale.range([maxItemWidth + 20, 100 + maxItemWidth + 20]);
          return multiStack.d3Line(multiStack.config.series[i].data);
        })
        .attr('stroke-width', 2)
        .attr('stroke', multiStack.config.color)
        .attr('fill', 'none');

      if (this.forExport !== true) {
        multiStack.config.layout = 'vertical';
        multiStack.alignVertically();
      } else {
        multiStack.config.layout = 'horizontal';
        multiStack.alignHorizontally();
      }

      if (multiStack.config.layout === 'vertical') {
        // legend.maxListHeight = (isNumber(legend.config.maxHeight) && (legend.config.maxHeight < legend.chart.height)) ? (legend.config.maxHeight - legend.config.legendSpacing) : (legend.chart.height - legend.config.legendSpacing);
        if (multiStack.gElem.node().getBBox().height > multiStack.height) {
          multiStack.setNavigation();
        }
      } else {
        if (
          multiStack.itemList.node().getBBox().height > multiStack.maxListHeight
        ) {
          multiStack.setNavigation();
        }
      }
    },
    resize: function() {
      var multiStack = this;
      multiStack.width = getWidth(multiStack.container);
      multiStack.height = getHeight(multiStack.container);

      multiStack.items.attr('transform', 'translate(0,0)');

      if (multiStack.width > multiStack.config.breakpoint) {
        multiStack.config.layout = 'horizontal';
      } else {
        multiStack.config.layout = 'vertical';
      }

      if (multiStack.config.layout === 'vertical') {
        multiStack.alignVertically();
        if (multiStack.gElem.node().getBBox().height > multiStack.height) {
          multiStack.setNavigation();
        } else if (multiStack.navigation === true) {
          multiStack.nav.selectAll('*').remove();
          multiStack.nav.node().parentNode.removeChild(multiStack.nav.node());
          multiStack.navigation = false;
          multiStack.content.classed(
            multiStack.cssPrefix + 'clipLegend',
            false
          );
        }
      } else {
        multiStack.alignHorizontally();
        if (
          multiStack.itemList.node().getBBox().height > multiStack.maxListHeight
        ) {
          multiStack.setNavigation();
        } else if (multiStack.navigation === true) {
          multiStack.nav.selectAll('*').remove();
          multiStack.nav.node().parentNode.removeChild(multiStack.nav.node());
          multiStack.navigation = false;
          multiStack.content.classed(
            multiStack.cssPrefix + 'clipLegend',
            false
          );
        }
      }
    },
    setDomain: function() {
      var multiStack = this;
      if (multiStack.timeline === true) {
        multiStack.xScale.domain([
          parseDate(multiStack.config.xAxis.startDate),
          parseDate(multiStack.config.xAxis.endDate)
        ]);
      } else {
        multiStack.xScale.domain(multiStack.config.xAxis.categories || []);
      }
    },
    /**
     * Function to align legend items horizontally
     */
    alignHorizontally: function() {
      var multiStack = this,
        x = 0,
        y = 0,
        row = 1,
        maxWidth = multiStack.width - multiStack.config.padding,
        translate;

      multiStack.items.attr('transform', function(d, i) {
        if (
          i > 0 &&
          multiStack.config.wrap === true &&
          maxWidth < x + this.getBBox().width
        ) {
          row = row + 1;
          x = 0;
          y =
            multiStack.itemList.node().getBBox().height +
            multiStack.config.legendSpacing;
          if (
            !!multiStack.config.maxRows &&
            row === multiStack.config.maxRows + 1
          ) {
            multiStack.maxListHeight = y;
          }
        }
        translate = 'translate(' + x + ',' + y + ')';
        x = x + (this.getBBox().width || 0) + multiStack.config.legendSpacing;
        return translate;
      });
    },
    /**
     * Function to align legend items vertically
     */
    alignVertically: function() {
      var multiStack = this,
        y = 0,
        x = 0,
        maxX = 0,
        translate;
      multiStack.items.attr('transform', function(d, i) {
        if (
          i > 0 &&
          multiStack.config.wrap === true &&
          multiStack.maxListHeight <
            y + this.getBBox().height + multiStack.config.sparklineSpacing
        ) {
          x = x + maxX + multiStack.config.legendSpacing;
          y = multiStack.config.legendSpacing;
        }
        translate = 'translate(' + x + ',' + y + ')';

        y = y + (this.getBBox().height || 0) + multiStack.config.legendSpacing;
        maxX = maxX < this.getBBox().width ? this.getBBox().width : maxX;

        return translate;
      });
    },
    /**
     * Function to set navigation arrows for overflowing legends
     */
    setNavigation: function() {
      var multiStack = this,
        x = 0,
        size = multiStack.config.navigation.size;
      if (!multiStack.navigation) {
        multiStack.navConfig = { currentPage: 1, maxPage: 0 };

        multiStack.nav = multiStack.gElem
          .append('g')
          .attr('class', multiStack.cssPrefix + 'legendNav');

        multiStack.nav
          .append('g')
          .attr('class', 'navTop')
          .on('click', function() {
            multiStack.showTopPage();
            d3.select(this)
              .select('path')
              .classed(
                multiStack.cssPrefix + 'greyed',
                multiStack.navConfig.currentPage === 1
              )
              .classed(
                multiStack.cssPrefix + 'cursorPointer',
                multiStack.navConfig.currentPage !== 1
              );

            multiStack.nav
              .select('.navBottom')
              .select('path')
              .classed(
                multiStack.cssPrefix + 'greyed',
                multiStack.navConfig.currentPage ===
                  multiStack.navConfig.maxPage
              )
              .classed(
                multiStack.cssPrefix + 'cursorPointer',
                multiStack.navConfig.currentPage !==
                  multiStack.navConfig.maxPage
              );
          });

        multiStack.nav
          .select('.navTop')
          .append('path')
          //    .attr('transform', 'translate(' + (10 / 2) + ',' + (10 / 2) + ')')
          .attr(
            'd',
            'M6.582,12.141c-0.271,0.268-0.709,0.268-0.978,0c-0.269-0.268-0.272-0.701,0-0.969l3.908-3.83 c0.27-0.268,0.707-0.268,0.979,0l3.908,3.83c0.27,0.267,0.27,0.701,0,0.969c-0.271,0.268-0.709,0.268-0.979,0L10,9L6.582,12.141z'
          )
          .attr('class', multiStack.cssPrefix + 'greyed');

        multiStack.nav
          .append('text')
          .text(
            multiStack.navConfig.currentPage +
              '/' +
              multiStack.navConfig.maxPage
          );

        multiStack.nav
          .append('g')
          .attr('class', 'navBottom')
          .on('click', function() {
            multiStack.showBottomPage();
            d3.select(this)
              .select('path')
              .classed(
                multiStack.cssPrefix + 'greyed',
                multiStack.navConfig.currentPage ===
                  multiStack.navConfig.maxPage
              )
              .classed(
                multiStack.cssPrefix + 'cursorPointer',
                multiStack.navConfig.currentPage !==
                  multiStack.navConfig.maxPage
              );

            multiStack.nav
              .select('.navTop')
              .select('path')
              .classed(
                multiStack.cssPrefix + 'greyed',
                multiStack.navConfig.currentPage === 1
              )
              .classed(
                multiStack.cssPrefix + 'cursorPointer',
                multiStack.navConfig.currentPage !== 1
              );
          });

        multiStack.nav
          .select('.navBottom')
          .append('path')
          //   .attr('transform', 'translate(' + (10 / 2) + ',' + (10 / 2) + ')')
          .attr(
            'd',
            'M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83 c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z'
          )
          .attr('class', multiStack.cssPrefix + 'cursorPointer');

        multiStack.navigation = true;
      }

      multiStack.nav
        .select('.navTop')
        .attr(
          'transform',
          'translate(' + (x + multiStack.config.navigation.padding) + ',0)'
        );

      x =
        multiStack.nav
          .select('.navTop')
          .node()
          .getBBox().width +
        multiStack.config.navigation.padding +
        20;

      multiStack.nav.select('text').attr(
        'transform',
        'translate(' +
          (x + multiStack.config.navigation.padding) +
          ',' +
          multiStack.nav
            .select('.navTop')
            .select('path')
            .node()
            .getBBox().height +
          ')'
      );
      if (multiStack.config.layout === 'horizontal') {
        multiStack.clipHeight = multiStack.maxListHeight;
      } else {
        multiStack.clipHeight =
          multiStack.height -
          multiStack.nav.node().getBBox().height -
          2 * multiStack.config.navigation.padding;
        multiStack.clipHeight =
          Math.floor(
            multiStack.clipHeight /
              (multiStack.items.node().getBBox().height +
                multiStack.config.legendSpacing)
          ) *
          (multiStack.items.node().getBBox().height +
            multiStack.config.legendSpacing);
      }
      multiStack.navConfig.maxPage = Math.ceil(
        multiStack.gElem.node().getBBox().height / multiStack.clipHeight
      );

      multiStack.nav
        .select('text')
        .text(
          multiStack.navConfig.currentPage + '/' + multiStack.navConfig.maxPage
        );

      x =
        x +
        multiStack.nav
          .select('text')
          .node()
          .getBBox().width +
        multiStack.config.navigation.padding;

      multiStack.nav
        .select('.navBottom')
        .attr(
          'transform',
          'translate(' + (x + multiStack.config.navigation.padding) + ',0)'
        );

      multiStack.nav.attr(
        'transform',
        'translate(' +
          (multiStack.width - 55) +
          ',' +
          multiStack.clipHeight +
          ')'
      );

      multiStack.clippath
        .select('rect')
        .attr('x', multiStack.config.padding)
        .attr('y', 0)
        .attr('width', multiStack.itemList.node().getBBox().width)
        .attr('height', multiStack.clipHeight);

      multiStack.content.classed(multiStack.cssPrefix + 'clipLegend', true);
    },
    /**
     * Function to show bottom multiStacks for navigation
     */
    showBottomPage: function() {
      var multiStack = this;

      if (multiStack.navConfig.currentPage < multiStack.navConfig.maxPage) {
        multiStack.navConfig.currentPage = multiStack.navConfig.currentPage + 1;
        multiStack.nav
          .select('text')
          .text(
            multiStack.navConfig.currentPage +
              '/' +
              multiStack.navConfig.maxPage
          );
        multiStack.itemList
          .transition()
          .duration(multiStack.config.navigation.duration)
          .attr(
            'transform',
            'translate(0,' +
              multiStack.clipHeight * (1 - multiStack.navConfig.currentPage) +
              ')'
          );
      }
    },
    /**
     * Function to show top multiStacks for navigation
     */
    showTopPage: function() {
      var multiStack = this;
      if (multiStack.navConfig.currentPage > 1) {
        multiStack.navConfig.currentPage = multiStack.navConfig.currentPage - 1;
        multiStack.nav
          .select('text')
          .text(
            multiStack.navConfig.currentPage +
              '/' +
              multiStack.navConfig.maxPage
          );
        multiStack.itemList
          .transition()
          .duration(multiStack.config.navigation.duration)
          .attr(
            'transform',
            'translate(0,' +
              multiStack.clipHeight * (1 - multiStack.navConfig.currentPage) +
              ')'
          );
      }
    },
    /**
     * Function to create  and append css classes to style tag for the axis
     */
    setCSS: function() {
      var multiStack = this;
      var cssString = '',
        classStart = ' .',
        classOpen = ' {',
        classClose = '} ',
        i;

      cssString =
        cssString +
        classStart +
        multiStack.cssPrefix +
        'componentGroup' +
        classOpen;
      for (i in multiStack.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          multiStack.config.style[i] +
          ';';
      }
      cssString = cssString + classClose;

      cssString = cssString + classStart + multiStack.cssPrefix + 'legendNav {';
      for (i in multiStack.config.navigation.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          multiStack.config.navigation.style[i] +
          ';';
      }

      cssString = cssString + classClose;

      cssString =
        cssString + classStart + multiStack.cssPrefix + 'clipLegend {';
      cssString =
        cssString + 'clip-path:url(#legendClip-' + multiStack.index + ');';
      cssString = cssString + classClose;

      multiStack.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to prepare data for legends
     */
    getData: function() {
      var multiStack = this;

      multiStack.data = multiStack.config.series.map(function(series, idx) {
        return { name: series.name || 'Series ' + idx };
      });
    },

    /**
     * Function to get export xlm string
     * @param {string} name Name for the chart
     */
    getExportString: function(name) {
      var multiStack = this,
        source;
      var element = DOCUMENT.createElement('div');
      element.style.width = multiStack.config.export.width + 'px';
      element.style.height = multiStack.config.export.height + 'px';
      element.id = 'exportChart';
      document.body.appendChild(element);

      if (isObject(multiStack.ExportableChart)) {
        delete multiStack.ExportableChart;
      }

      multiStack.ExportableChart = new chartPlus.multiStack(
        merge(multiStack.userConfig, {
          container: element
        }),
        true
      );

      source = new XMLSerializer().serializeToString(
        multiStack.ExportableChart.svg.node()
      );
      multiStack.config.export.imageWidth = multiStack.ExportableChart.width;
      multiStack.config.export.imageHeight = multiStack.ExportableChart.height;
      // }

      if (
        !source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)
      ) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      document.body.removeChild(element);

      return source;
    },

    /**
     * Function to get png dataUrl from Svg string
     */

    getDataUrl: function(width, height, id, callback) {
      var multiStack = this;
      multiStack.config.export.width = width;
      multiStack.config.export.height = height;

      var svgString = multiStack.getExportString();

      if (isObject(multiStack.ExportableChart)) {
        delete multiStack.ExportableChart;
      }

      var imgsrc =
        'data:image/svg+xml;base64,' +
        btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

      var canvas = document.createElement('canvas');

      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width;
      canvas.style.height = height;

      var context = canvas.getContext('2d');
      context.scale(2, 2);

      var image = new Image();
      image.onload = function() {
        context.clearRect(0, 0, width * 2, height * 2);
        context.drawImage(image, 0, 0, width, height);

        if (!!callback) {
          callback(id, canvas.toDataURL('image/png'));
        }
      };

      image.src = imgsrc;
    }
  };

  /**
   * A constructor for creating data table using chartPlus
   * @constructor
   */

  function Table() {
    this.init.apply(this, arguments);
  }
  Table.prototype = {
    defaultConfig: {},
    /**
     * Function to initialize data table and required sub components
     * @param {object} data The dataset
     * @param {object} config The configuration object
     */
    init: function(data, config) {
      var table = this;

      // Set index and push this to the charts array
      this.index = idCounter;
      this.cssPrefix = NAMESPACE + this.index + '-';
      idCounter++;
      charts.push(this);

      table.dataSet = data;
      table.config = merge(table.defaultConfig, config);
      table.render();
    },
    /**
     * Function to initialise containers for table components
     */
    initContainer: function() {
      var table = this;

      if (!table.config.container) {
        error('invalid Container reference', true);
      }
      if (isString(table.config.container)) {
        table.container = DOCUMENT.getElementById(table.config.container);
      }
      if (isElement(table.config.container)) {
        table.container = table.config.container;
      }
      if (!isElement(table.container)) {
        error('No Valid Container available', true);
      }
      table.elem = d3
        .select(table.container)
        .append('div')
        .attr('class', NAMESPACE + 'container')
        .attr('id', NAMESPACE + table.index)
        .styles({
          width: '100%',
          height: '100%',
          position: 'relative',
          'vertical-align': 'top',
          overflow: 'hidden'
        });
    },
    /**
     * Function to remove table
     */
    remove: function() {
      var table = this,
        key;

      drop(charts, table);

      d3.select(table.container)
        .select('.' + NAMESPACE + 'container')
        .remove();

      for (key in table) {
        delete table[key];
      }
    },
    /**
     * Function to render table
     */
    render: function() {
      var table = this;
      table.initContainer();
      table.elem
        .append('div')
        .attr('class', 'scrollContainer')
        .append('div')
        .attr('class', 'scrollArea')
        .append('table')
        .styles({ width: '100%', 'border-collapse': 'collapse' });
      table.elem
        .select('table')
        .append('thead')
        .attr('class', 'cPlus-tHead')
        .append('tr');

      table.elem
        .select('table')
        .append('tbody')
        .attr('class', 'cPlus-tBody');

      // table.displayMinWidth = { lg: 0, md: 0, sm: 0 };
      // ==============================> TABLE HEAD <============================== \\

      // table.setBreakpoints();
      var headData = [],
        total = 0,
        elem,
        rowElem;

      headData.splice(0, headData.length);
      headData.push(
        table.dataSet.categoryName ? table.dataSet.categoryName : 'Categories'
      );
      if (!!table.dataSet.legendName) {
        headData.push(
          table.dataSet.legendName ? table.dataSet.legendName : 'Legends'
        );
      }
      if (table.config.percent === true) {
        headData.push('Percentage');
      } else {
        headData.push('Count');
      }
      table.updateHead(headData);

      table.elem
        .select('tbody')
        .selectAll('tr')
        .remove();
      table.dataSet.data.forEach(function(data) {
        total = 0;
        elem = table.elem
          .select('tbody')
          .append('tr')
          .classed('mainRow', true);
        elem
          .append('td')
          .append('div')
          .text(data.key);
        if (!!table.dataSet.legendName) {
          elem.append('td').append('div');
        }
        elem
          .append('td')
          .append('div')
          .attr('class', 'total')
          .style('text-align', 'center');
        Object.keys(data.values).forEach(function(key) {
          total = total + +data.values[key];
          if (!!table.dataSet.legendName) {
            rowElem = table.elem
              .select('tbody')
              .append('tr')
              .classed('subRow', true);
            rowElem.append('td').append('div');
            rowElem
              .append('td')
              .append('div')
              .text(key);
            rowElem
              .append('td')
              .append('div')
              .text(getRound(data.values[key], 2))
              .style('text-align', 'center');
          }
        });
        elem.select('.total').text(getRound(total, 2));
      });
      table.resize();
    },
    resize: function() {
      var table = this;
      var widthArray = [];

      table.elem.select('.scrollContainer').style(
        'padding-top',
        getHeight(
          table.elem
            .select('thead')
            .select('tr')
            .node()
        ) + 'px'
      );
      table.elem.select('.scrollArea').style(
        'height',
        getHeight(table.elem.node()) -
          getHeight(
            table.elem
              .select('thead')
              .select('tr')
              .node()
          ) +
          'px'
      );

      table.elem
        .select('thead')
        .selectAll('tr')
        .styles({ left: 0, top: 0, position: 'absolute' });

      widthArray.splice(0, widthArray.length);

      table.elem
        .select('tbody')
        .select('tr')
        .selectAll('td')
        .each(function(d, i) {
          widthArray.push(
            getWidth(
              d3
                .select(this)
                .select('div')
                .node()
            )
          );
        });

      // table.elem.select('tbody').selectAll('tr').each(function() {
      //     d3.select(this).selectAll('div').each(function(d, i) {
      //         d3.select(this).style('width', widthArray[i] + 'px');
      //     });
      // });

      table.elem
        .select('thead')
        .selectAll('th')
        .each(function(d, i) {
          d3.select(this)
            .select('div')
            .style('width', widthArray[i] + 'px');
        });
    },
    updateHead: function(data) {
      var table = this;
      table.elem
        .select('thead')
        .select('tr')
        .selectAll('th')
        .remove();

      table.elem
        .select('thead')
        .select('tr')
        .selectAll('th')
        .data(data)
        .enter()
        .append('th')
        .append('div')
        .text(function(d, i) {
          if (i === data.length - 1) {
            d3.select(this).style('text-align', 'center');
          }
          return d;
        });
    }
  };

  /**
   * A constructor for creating chartPlus instances
   * @constructor
   */

  function Chart() {
    this.init.apply(this, arguments);
  }

  Chart.prototype = {
    /**
     * The additional classes to be appended as part of css
     * @type {object}
     */
    addClasses: {
      // additional classes
      hidden: {
        opacity: 0,
        pointerEvents: 'none',
        visibility: 'hidden'
      },
      cursorPointer: {
        cursor: 'pointer'
      },
      disabled: {
        fill: '#cccccc !important'
      }
    },
    /**
     * Function to destroy chart
     */
    destroy: function() {
      var chart = this,
        key;
      clearTimeout(chart.timer);

      if (isObject(chart.ExportableChart)) {
        chart.ExportableChart.remove();
        console.log('removing export after');
      }

      if (isArray(chart.series)) {
        chart.series.forEach(function(series, idx) {
          series.remove();
        });
      }
      if (isArray(chart.indicators)) {
        chart.indicators.forEach(function(indicator, idx) {
          indicator.remove();
        });
      }
      if (isObject(chart.brush)) {
        chart.brush.remove();
      }

      if (isObject(chart.xAxis)) {
        chart.xAxis.remove();
      }
      if (isObject(chart.yAxis)) {
        chart.yAxis.remove();
      }
      if (isObject(chart.legend)) {
        chart.legend.remove();
      }
      if (isObject(chart.tooltip)) {
        chart.tooltip.remove();
      }
      if (isObject(chart.WFLine)) {
        chart.WFLine.remove();
      }
      if (isObject(chart.totalBars)) {
        chart.totalBars.remove();
      }
      drop(charts, chart);

      d3.select(chart.container)
        .select('.' + NAMESPACE + 'container')
        .remove();

      for (key in chart) {
        delete chart[key];
      }
    },
    /**
     * Export chart to PNG or JPEG using HTML5 canvas methods
     * @param {string} name Name for the exported file
     * @param {string} type Export type
     */
    export: function(name, type) {
      var chart = this,
        source = chart.getExportString(name);

      var image = new Image();
      image.src =
        'data:image/svg+xml;base64,' +
        window.btoa(unescape(encodeURIComponent(source)));
      var canvas = document.createElement('canvas');

      canvas.width = chart.config.export.imageWidth || chart.width;
      canvas.height = chart.config.export.imageHeight || chart.height;
      var context = canvas.getContext('2d');

      image.onload = function() {
        var context = canvas.getContext('2d');
        try {
          context.drawImage(image, 0, 0);
        } catch (err) {
          setTimeout(function() {
            context.drawImage(image, 0, 0);
          }, 1);
        }
        if (!(chartPlus.isIE || chartPlus.isSafari)) {
          var a = document.createElement('a');
          a.target = '_self';
          a.download = (name || 'image') + '.' + (type || 'png');
          a.href = canvas.toDataURL('image/' + (type || 'png'));
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
    },
    /**
     * Function to get export xlm string
     * @param {string} name Name for the chart
     */
    getExportString: function(name) {
      var chart = this,
        source;
      var element = DOCUMENT.createElement('div');
      element.style.width = chart.config.export.width + 'px';
      element.style.height = chart.config.export.height + 'px';
      element.id = 'exportChart';
      document.body.appendChild(element);

      if (isObject(chart.ExportableChart)) {
        delete chart.ExportableChart;
      }

      chart.ExportableChart = new chartPlus.chart(
        merge(chart.userConfig, {
          container: element,
          legend: {
            align: 'bottom-left',
            layout: 'horizontal',
            padding: 45,
            legendSpacing: 15,
            maxTextLength: 150, // avoid clipping
            textSpacing: 10,
            wrap: true,
            size: 5,
            maxRows: 7,
            style: {
              fontSize: '12px'
            }
          },
          title: {
            display: false,
            text: name
          },
          xAxis: {
            offset: 15
          }
        }),
        true
      );

      // if (mathAbs(chart.ExportableChart.vRange[0] - chart.ExportableChart.vRange[1]) < chart.config.export.height) {
      //     element.style.height = ((2 * chart.config.export.height) - mathAbs(chart.ExportableChart.vRange[0] - chart.ExportableChart.vRange[1])) + 'px';
      //     chart.ExportableChart.resize();
      //     source = new XMLSerializer().serializeToString(chart.ExportableChart.svg.node());
      //     chart.config.export.imageWidth = chart.ExportableChart.width;
      //     chart.config.export.imageHeight = chart.ExportableChart.height;
      //
      // } else {
      source = new XMLSerializer().serializeToString(
        chart.ExportableChart.svg.node()
      );
      chart.config.export.imageWidth = chart.ExportableChart.width;
      chart.config.export.imageHeight = chart.ExportableChart.height;
      // }

      if (
        !source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)
      ) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      document.body.removeChild(element);

      return source;
    },

    /**
     * Function to get png dataUrl from Svg string
     */

    getDataUrl: function(width, height, id, callback) {
      var chart = this;
      chart.config.export.width = width;
      chart.config.export.height = height;

      var svgString = chart.getExportString();

      if (isObject(chart.ExportableChart)) {
        delete chart.ExportableChart;
      }

      var imgsrc =
        'data:image/svg+xml;base64,' +
        btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

      var canvas = document.createElement('canvas');

      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width;
      canvas.style.height = height;

      var context = canvas.getContext('2d');
      context.scale(2, 2);

      var image = new Image();
      image.onload = function() {
        context.clearRect(0, 0, width * 2, height * 2);
        context.drawImage(image, 0, 0, width, height);

        if (!!callback) {
          callback(id, canvas.toDataURL('image/png'));
        }
      };

      image.src = imgsrc;
    },
    /**
     * Function to initialize chart and required sub components
     * @param {object} userConfig user configuration
     * @param {boolean} rendered svg is for exporting or not
     */
    init: function(userConfig, forExport) {
      var chart = this;
      var config,
        Series = userConfig.series;
      userConfig.series = null;
      config = merge(defaultConfig, userConfig);
      config.series = userConfig.series = Series;

      this.config = config;
      this.userConfig = userConfig;

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      // CHECK IF A NESTED CHART OBJECT IS REALLY REQUIRED
      ////////////////////////////////////////////////////////////////////////////////////////////////////

      // Set index and push this to the charts array
      this.index = idCounter;
      this.cssPrefix = NAMESPACE + this.index + '-';
      idCounter++;
      this.forExport = forExport;
      if (this.forExport !== true) {
        charts.push(this);
      }

      // Set CSS Styles

      // if (isArray(this.userConfig.colors)) {
      //     setCSS(this.userConfig.colors, this.index);
      // }

      if (isArray(chart.config.series)) {
        for (var i = 0; i < chart.config.series.length; i++) {
          if (!chart.config.series[i].type) {
            chart.config.series[i].type = chart.config.type;
          }
          if (!chart.config.series[i].name) {
            chart.config.series[i].name = 'Series ' + i;
          }
          if (chart.config.series[i].type === 'waterfall') {
            if (!chart.config.series[i].stack) {
              chart.config.series[i].stack = true;
            }
          }
          if (chart.config.series[i].invert === true) {
            chart.config.invert = true;
          } else if (
            isObject(chart.config.sharedConfig) &&
            isObject(chart.config.sharedConfig[chart.config.series[i].type]) &&
            chart.config.sharedConfig[chart.config.series[i].type].invert ===
              true
          ) {
            chart.config.invert = true;
          }
          if (
            chart.config.series[i].type === 'pie' ||
            chart.config.series[i].type === 'sunburst'
          ) {
            chart.config.colorByPoint = true;
            chart.config.series[i].colorByPoint = true;
            if (!chart.userConfig.legend || !chart.userConfig.legend.align) {
              chart.config.legend.layout = 'vertical';
              chart.config.legend.align = 'centre-right';
            }
            chart.config.legend.innerLegend = true;
          }
        }
      }
      chart.setColorScale(); // sets inner legends

      if (isObject(chart.config.xAxis) && chart.config.xAxis.type === 'date') {
        chart.xScale = d3.scaleTime();
        chart.brushScale = d3.scaleTime();

        chart.setDateDomain(
          chart.config.xAxis.startDate,
          chart.config.xAxis.endDate
        );
      } else {
        chart.xScale = d3.scaleBand();
        chart.setOrdinalDomain();
      }
      chart.yScale = d3.scaleLinear();

      chart.setGroupProps();

      chart.initContainer();
      chart.setCSS();

      chart.render();
    },
    /**
     * Function to initialise containers for svg components
     */
    initContainer: function() {
      var chart = this;
      if (!chart.config.container) {
        error('invalid Container reference', true);
      }
      if (isString(chart.userConfig.container)) {
        chart.container = DOCUMENT.getElementById(chart.config.container);
      }
      if (isElement(chart.config.container)) {
        chart.container = chart.config.container;
      }
      if (!isElement(chart.container)) {
        error('No Valid Container available', true);
      }

      composeDOM(chart.container, DIV, {
        className: NAMESPACE + 'container',
        id: NAMESPACE + chart.index
      });

      chart.width = getWidth(chart.container);
      chart.height = getHeight(chart.container);
      console.log(
        'initContainer' +
          'chart.width:' +
          chart.width +
          'chart.height:' +
          chart.height
      );

      if (chart.forExport !== true) {
        chart.svg = d3
          .select(accessDOM(NAMESPACE + chart.index, 'id'))
          .append('svg')
          .attr('id', 'svg-' + chart.index)
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('class', NAMESPACE + 'svgComponents');
      } else {
        // firefox export fix

        chart.svg = d3
          .select(accessDOM(NAMESPACE + chart.index, 'id'))
          .append('svg')
          .attr('id', 'svg-' + chart.index)
          .attr('width', chart.width + 'px')
          .attr('height', chart.height + 'px')
          .attr('class', NAMESPACE + 'svgComponents');
      }

      if (!chartPlus.isIE) {
        chart.svg
          .attr('viewBox', '0 0 ' + chart.width + ' ' + chart.height)
          .attr('preserveAspectRatio', 'xMinYMin meet');
      }

      chart.style = chart.svg.append('style').attr('type', 'text/css');

      chart.svg
        .append('rect')
        // .attr('class', 'bgFill')
        .attr('class', chart.cssPrefix + 'bg')
        .attr('x', 0)
        .attr('y', 0)
        /*VIEW-779*/
        .attr('width', '100%')
        .attr('height', '100%');

      // chart clippath
      chart.clippath = chart.svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'chartClip-' + chart.index);
      chart.clippath
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 0)
        .attr('height', 0);

      chart.gElem = chart.svg.append('g');
      chart.gElem.classed(chart.cssPrefix + 'componentGroup', true);

      chart.setAxisRange();
    },
    /**
     * Function to remove chart
     */
    remove: function() {
      var chart = this;

      chart.destroy();
    },
    /**
     * Function to render chart
     */
    render: function() {
      var chart = this,
        x = 0,
        y = 0;
      chart.tooltip = new Tooltip(chart, chart.config.tooltip);

      if (
        isObject(chart.config.title) &&
        chart.config.title.display !== false
      ) {
        chart.title = new Title(chart, chart.config.title);
        if (chart.title.firstRender === false) {
          chart.setAxisRange();
        }
      }
      if (chart.config.legend.display !== false) {
        chart.legend = new Legend(
          chart,
          chart.config.legend,
          !chart.config.legend.float
        );
        if (chart.legend.firstRender === false) {
          chart.setAxisRange();
        }
      }
      if (!!chart.config.xAxis && chart.config.xAxis.type === 'date') {
        chart.brush = new Brush(chart);
        if (chart.brush.firstRender === false) {
          chart.setAxisRange();
        }
        chart.brush.resize(chart.hRange[0], chart.vRange[0]);
      }
      chart.config.series.forEach(function(series, idx) {
        if (series.type !== 'pie' && series.type !== 'sunburst') {
          if (chart.config.yAxis.display !== false && !isObject(chart.yAxis)) {
            chart.config.yAxis.isXAxis = false;
            chart.yAxis = new Axis(chart, chart.config.yAxis);
          }
          if (chart.config.xAxis.display !== false && !isObject(chart.xAxis)) {
            chart.config.xAxis.isXAxis = true;
            chart.xAxis = new Axis(chart, chart.config.xAxis);
          }
        }
      });
      chart.setAxisRange();
      if (isObject(chart.brush)) {
        if (chart.config.invert === true) {
          if (isObject(chart.yAxis) && chart.yAxis.firstRender === false) {
            chart.brush.resize(
              chart.hRange[0],
              chart.yAxis.gElem.node().getBBox().height +
                (chart.yAxis.config.grid === true ? 0 : chart.vRange[0])
            );
          } else {
            chart.brush.resize(chart.hRange[0], chart.vRange[0]);
          }
        } else {
          if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
            if (
              chart.xAxis.gElem.node().getBBox().height >
              mathAbs(chart.vRange[0] - chart.vRange[1])
            ) {
              chart.brush.resize(
                chart.hRange[0],
                chart.vRange[1] + chart.xAxis.gElem.node().getBBox().height
              );
            } else {
              chart.brush.resize(
                chart.hRange[0],
                chart.vRange[0] + chart.xAxis.gElem.node().getBBox().height
              );
            }
          } else {
            chart.brush.resize(chart.hRange[0], chart.vRange[0]);
          }
        }
      }

      if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
        chart.xAxis.resize();
      }
      if (isObject(chart.yAxis) && chart.yAxis.firstRender === false) {
        chart.yAxis.resize();
      }
      /* ----------------------------------------------------------------------------
                 render indicators which should go behind
        ...............................................................................*/
      if (isArray(chart.config.indicators)) {
        if (!chart.indicators) {
          chart.indicators = [];
        }
        chart.config.indicators.forEach(function(indicator, idx) {
          if (indicator.overlay === false) {
            indicator.index = idx;
            chart.indicators[idx] = new Indicator(chart, indicator);
          }
        });
      }
      /* ----------------------------------------------------------------------------
                 render indicators which should go behind
        ...............................................................................*/
      if (chart.hasWFChart) {
        chart.WFLine = new Series(chart, {
          type: 'line',
          data: chart.wfLineData,
          index: -1,
          name: 'overlay',
          marker: {
            display: false
          },
          interpolation: 'step-after',
          color: '#cccccc',
          style: {
            strokeWidth: '1px',
            shapeRendering: 'crispEdges'
          }
        });
      }
      if (chart.config.percent === true) {
        var config = {
          type: 'bar',
          index: -1,
          color: '#f1f1f1',
          name: 'Max percent',
          tooltip: false
        };
        if (chart.config.xAxis.type === 'date') {
          config.data = chart.innerLegends.map(function(d) {
            return { x: d, y: 100 };
          });
        } else {
          config.data = chart.config.xAxis.categories.map(function(d) {
            return { x: d, y: 100 };
          });
        }

        chart.totalBars = new Series(chart, config);
      }
      chart.series = [];
      chart.config.series.forEach(function(series, idx) {
        series.index = idx;
        chart.series[idx] = new Series(chart, series);
      });

      /* ----------------------------------------------------------------------------
                 render indicators which should come in front
        ...............................................................................*/
      if (isArray(chart.config.indicators)) {
        if (!chart.indicators) {
          chart.indicators = [];
        }
        chart.config.indicators.forEach(function(indicator, idx) {
          if (indicator.overlay !== false) {
            indicator.index = idx;
            chart.indicators[idx] = new Indicator(chart, indicator);
          }
        });
      }
      /* ----------------------------------------------------------------------------
                 render indicators which should come in front
        ...............................................................................*/

      if (
        chart.config.legend.display !== false &&
        chart.legend.config.float === true
      ) {
        chart.legend.render();
      }
      // resizing chart to accomodate size changes due to css of app
      chart.timer = setTimeout(function() {
        if (chart.forExport !== true) {
          chart.resize();
        }
      }, 50);
    },
    /**
     * Function to reset data - used for stack charts to reset dy
     */
    resetData: function() {
      var chart = this;
      chart.config.series.forEach(function(series, idx) {
        if (
          series.type === 'bar' ||
          series.type === 'waterfall' ||
          series.type === 'area'
        ) {
          series.data.forEach(function(d, i) {
            delete d.dy;
          });
        }
      });
    },
    /**
     * Function to resize chart
     */
    resize: function() {
      var chart = this;
      chart.width = getWidth(chart.container);
      chart.height = getHeight(chart.container);

      if (!chartPlus.isIE) {
        chart.svg.attr('viewBox', '0 0 ' + chart.width + ' ' + chart.height);
      }
      if (isObject(chart.tooltip)) {
        chart.tooltip.reposition();
      }
      if (
        !!chart.config.title &&
        chart.config.title.display !== false &&
        chart.legend.firstRender === false
      ) {
        chart.title.resize();
      }
      if (
        chart.config.legend.display !== false &&
        chart.legend.firstRender === false
      ) {
        chart.legend.resize();
      }
      chart.setAxisRange();

      if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
        chart.xAxis.resize();
      }
      if (isObject(chart.yAxis) && chart.yAxis.firstRender === false) {
        chart.yAxis.resize();
      }

      if (isObject(chart.brush)) {
        if (chart.config.invert === true) {
          if (isObject(chart.yAxis) && chart.yAxis.firstRender === false) {
            if (chart.yAxis.config.grid === true) {
              chart.brush.resize(
                chart.hRange[0],
                chart.yAxis.gElem.node().getBBox().height > 0
                  ? chart.yAxis.gElem.node().getBBox().height
                  : chart.vRange[0]
              );
            } else {
              chart.brush.resize(
                chart.hRange[0],
                chart.yAxis.gElem.node().getBBox().height + chart.vRange[0]
              );
            }
          } else {
            chart.brush.resize(chart.hRange[0], chart.vRange[0]);
          }
        } else {
          if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
            if (
              chart.xAxis.gElem.node().getBBox().height >
              mathAbs(chart.vRange[0] - chart.vRange[1])
            ) {
              chart.brush.resize(
                chart.hRange[0],
                chart.vRange[1] + chart.xAxis.gElem.node().getBBox().height
              );
            } else {
              chart.brush.resize(
                chart.hRange[0],
                chart.vRange[0] + chart.xAxis.gElem.node().getBBox().height
              );
            }
          } else {
            chart.brush.resize(chart.hRange[0], chart.vRange[0]);
          }
        }
      }
      if (chart.hasWFChart) {
        chart.WFLine.resize();
      }
      if (chart.config.percent === true) {
        chart.totalBars.resize();
      }
      chart.series.forEach(function(series, idx) {
        series.resize();
      });
      if (isArray(chart.config.indicators)) {
        chart.config.indicators.forEach(function(indicator, idx) {
          chart.indicators[idx].resize();
        });
      }
    },
    /**
     * Function to set X and Y axes range based on other components
     */
    setAxisRange: function() {
      var chart = this,
        hRange = [0, chart.width], // (axis in SVG is inverted)
        vRange = [chart.height, 0],
        i;
      if (isObject(chart.title) && chart.title.firstRender === false) {
        vRange = [chart.height, chart.title.height];
      }
      if (
        isObject(chart.legend) &&
        chart.legend.config.float !== true &&
        chart.legend.firstRender === false
      ) {
        if (chart.legend.config.layout === 'horizontal') {
          var legHeight =
            chart.height -
            getTransformation(chart.legend.gElem.attr('transform')).translateY;
          //chart.legend.config.padding
          if (chart.legend.config.align.indexOf('bottom') !== -1) {
            vRange = [
              chart.height - legHeight - chart.legend.config.padding,
              vRange[1]
            ];
          } else {
            vRange = [
              chart.height,
              legHeight + chart.legend.config.padding + vRange[1]
            ];
          }
          hRange = [0, chart.width];
        } else {
          if (chart.legend.config.align.indexOf('left') !== -1) {
            hRange = [
              chart.legend.gElem.node().getBBox().width +
                chart.legend.config.padding,
              chart.width
            ];
          } else {
            hRange = [
              0,
              chart.width -
                chart.legend.gElem.node().getBBox().width -
                chart.legend.config.padding
            ];
          }
          vRange = [chart.height, vRange[1]];
        }
      }
      if (isObject(chart.brush)) {
        vRange[0] = vRange[0] - chart.brush.gElem.node().getBBox().height;
      }

      if (isObject(chart.yAxis) && chart.yAxis.firstRender === false) {
        if (chart.config.invert === true) {
          if (chart.yAxis.config.orient === 'bottom') {
            vRange[1] = vRange[1] + chart.yAxis.gElem.node().getBBox().height;
          } else {
            vRange[0] = vRange[0] - chart.yAxis.gElem.node().getBBox().height;
          }
        } else {
          if (chart.yAxis.config.orient === 'right') {
            hRange[1] = hRange[1] - chart.yAxis.gElem.node().getBBox().width;
          } else {
            hRange[0] = hRange[0] + chart.yAxis.gElem.node().getBBox().width;
            if (chart.yAxis.config.grid === true) {
              hRange[0] =
                hRange[0] +
                (chart.yAxis.gElem.node().getBBox().height > 0
                  ? chart.yAxis.d3Axis.tickSizeInner()
                  : 0);
            }
          }
          // if (!isNumber(chart.yAxis.offset)) {
          //     chart.yAxis.offset = mathAbs(vRange[0] - vRange[1] - chart.yAxis.gElem.node().getBBox().height);
          // }
          // need to check use of offset
          // vRange[0] = vRange[0] - (chart.yAxis.offset || 0);
        }
      }
      if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
        if (chart.config.invert === true) {
          if (chart.xAxis.config.orient === 'right') {
            hRange[1] = hRange[1] - chart.xAxis.gElem.node().getBBox().width;
          } else {
            hRange[0] = hRange[0] + chart.xAxis.gElem.node().getBBox().width;
          }
        } else {
          if (chart.xAxis.config.orient === 'top') {
            vRange[1] = vRange[1] + chart.xAxis.gElem.node().getBBox().height;
          } else {
            vRange[0] = vRange[0] - chart.xAxis.gElem.node().getBBox().height;
            if (chart.xAxis.config.grid === true) {
              vRange[0] =
                vRange[0] -
                (chart.xAxis.gElem.node().getBBox().height > 0
                  ? chart.xAxis.d3Axis.tickSizeInner()
                  : 0);
            }
          }
          // if (!isNumber(chart.xAxis.offset)) {
          //     chart.xAxis.offset = mathAbs(hRange[1] - chart.xAxis.gElem.node().getBBox().width);
          // }
          hRange[1] =
            hRange[1] -
            (chart.xAxis.config.offset || 0) -
            (chart.xAxis.offset || 0);
        }
      }

      // if(chart.forExport) {
      //     vRange = [300, 0];
      // }

      chart.hRange = hRange;
      chart.vRange = vRange;
      if (chart.config.invert === true) {
        chart.xRange = vRange;
        chart.yRange = hRange;
      } else {
        chart.xRange = hRange;
        chart.yRange = vRange;
      }
      if (isFunction(chart.xScale.bandwidth)) {
        chart.xScale
          .range(chart.xRange)
          .paddingInner([0.1])
          .paddingOuter([0.2])
          .align([0.5]);
      } else {
        chart.xScale.range(chart.xRange);
        chart.brushScale.range(chart.xRange);
      }
      chart.yScale.range(chart.yRange);
      for (i = 0; i < chart.config.series.length; i++) {
        if (
          chart.config.series[i].type !== 'pie' &&
          chart.config.series[i].type !== 'sunburst'
        ) {
          chart.setRectWidth();
          break;
        }
      }

      // update clippath size
      chart.clippath
        .select('rect')
        .attr('x', d3Min(chart.hRange) + 1)
        .attr('y', d3Min(chart.vRange))
        .attr('width', mathAbs(chart.hRange[0] - chart.hRange[1]) - 1)
        .attr('height', mathAbs(chart.vRange[0] - chart.vRange[1]));
    },
    /**
     * Function to set color scale based on each series
     */
    setColorScale: function() {
      var chart = this;
      chart.innerLegends = [];
      chart.config.series.forEach(function(series, idx) {
        if (isArray(series.data)) {
          series.data.forEach(function(d, i) {
            if (chart.innerLegends.indexOf(d.x) === -1) {
              chart.innerLegends.push(d.x);
            }
          });
        } else if (series.type === 'sunburst') {
          if (series.data.children) {
            series.data.children.forEach(function(d, i) {
              if (chart.innerLegends.indexOf(d.name) === -1) {
                chart.innerLegends.push(d.name);
              }
            });
          }
        }
      });
      if (isArray(chart.config.xAxis.categories)) {
        chart.innerLegends.forEach(function(d, i) {
          if (chart.config.xAxis.categories.indexOf(d) === -1) {
            chart.config.xAxis.categories.push(d);
          }
        });
        chart.innerLegends.splice(0, chart.innerLegends.length);
        chart.innerLegends = chart.config.xAxis.categories.slice(
          0,
          chart.config.xAxis.categories.length
        );
      }
      if (!!chart.config.xAxis && chart.config.xAxis.type === 'date') {
        chart.innerLegends.sort();
      }

      chart.colorScale = d3
        .scaleOrdinal()
        .range(
          isArray(this.userConfig.colors)
            ? this.userConfig.colors
            : themeConfig.colors
        );

      if (chart.config.colorByPoint === true) {
        chart.colorScale.domain(chart.innerLegends);
      } else {
        chart.colorScale.domain(
          chart.config.series.map(function(d, idx) {
            return d.name;
          })
        );
      }
    },
    /**
     * Function to create  and append css classes to style tag for the chart
     */
    setCSS: function() {
      var chart = this;

      var cssString = '',
        component,
        key,
        classStart = ' .',
        classOpen = ' {',
        classClose = '} ',
        i,
        className;

      // set background class
      cssString =
        cssString +
        classStart +
        chart.cssPrefix +
        'bg' +
        classOpen +
        'fill:' +
        themeConfig.backgroundColor +
        ';' +
        classClose;

      // Special handling for pdf export
      if (chart.forExport) {
        themeConfig.text.contrastDarkColor = '#091e42';
        themeConfig.axis.contrastDarkColor = '#c1c7d0';
      } else {
        // reset back
        themeConfig.text.contrastDarkColor = '#707070';
        themeConfig.axis.contrastDarkColor = '#e9e9e9';
      }

      if (getContrastYIQ(themeConfig.backgroundColor) === 'dark') {
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastDarkColor +
          ';' +
          classClose;
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' .axis line, .axis path' +
          classOpen +
          'stroke:' +
          themeConfig.axis.contrastDarkColor +
          ';' +
          classClose;
      } else {
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' text' +
          classOpen +
          'fill:' +
          themeConfig.text.contrastLightColor +
          ';' +
          classClose;
        cssString =
          cssString +
          '#svg-' +
          chart.index +
          ' .axis line, .axis path' +
          classOpen +
          'stroke:' +
          themeConfig.axis.contrastLightColor +
          ';' +
          classClose;
      }

      // additional classes
      for (className in chart.addClasses) {
        if (isObject(chart.addClasses[className])) {
          cssString =
            cssString + classStart + chart.cssPrefix + className + classOpen;
          for (i in chart.addClasses[className]) {
            cssString =
              cssString +
              i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
              ':' +
              chart.addClasses[className][i] +
              ';';
          }
          cssString = cssString + classClose;
        }
      }

      cssString =
        cssString + classStart + chart.cssPrefix + 'componentGroup' + classOpen;
      for (i in chart.config.style) {
        cssString =
          cssString +
          i.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() +
          ':' +
          chart.config.style[i] +
          ';';
      }
      cssString = cssString + classClose;

      cssString =
        cssString + classStart + chart.cssPrefix + 'clipChart' + classOpen;
      cssString = cssString + 'clip-path:url(#chartClip-' + chart.index + ');';
      cssString = cssString + classClose;

      chart.style.node().appendChild(document.createTextNode(cssString));
    },
    /**
     * Function to set date domain of x axis for timeseries chart
     */
    setDateDomain: function(startDate, endDate) {
      var chart = this;
      if (chart.config.granularity === 'month') {
        chart.xScale.domain([
          d3.timeDay.offset(parseDate(startDate), -45),
          d3.timeDay.offset(parseDate(endDate), +45)
        ]);
      } else if (chart.config.granularity === 'week') {
        chart.xScale.domain([
          d3.timeDay.offset(parseDate(startDate), -10),
          d3.timeDay.offset(parseDate(endDate), +10)
        ]);
      } else {
        chart.xScale.domain([
          d3.timeDay.offset(parseDate(startDate), -1),
          d3.timeDay.offset(parseDate(endDate), +1)
        ]);
      }

      chart.brushScale.domain(chart.xScale.domain());
      chart.brushDomain = [parseDate(startDate), parseDate(endDate)];
    },
    /**
     * Function to calculate position of stack and group bars
     */
    setGroupProps: function() {
      var chart = this;
      var groups = 0,
        stackIDs = [],
        groupIDs = [],
        stackNames = [];

      if (!!chart.config.stack) {
        stackNames.push(chart.config.stack);
        stackIDs[0] = [];
      }

      chart.config.series.forEach(function(config, idx) {
        if (config.type === 'waterfall') {
          chart.hasWFChart = true;
        }
        if (
          config.isHidden !== true &&
          (config.type === 'bar' ||
            config.type === 'waterfall' ||
            config.type === 'area')
        ) {
          if (!!config.stack) {
            if (stackNames.indexOf(config.stack) === -1) {
              stackNames.push(config.stack);
              stackIDs.push([idx]);
              config.group = stackIDs.length - 1;
            } else {
              stackIDs[stackNames.indexOf(config.stack)].push(idx);
              config.group = stackNames.indexOf(config.stack);
            }
          } else if (!!chart.config.stack) {
            stackIDs[stackNames.indexOf(chart.config.stack)].push(idx);
            config.group = stackNames.indexOf(chart.config.stack);
          } else {
            config.group = groups;
            groups = groups + 1;
            groupIDs.push(idx);
          }
        }
      });

      groups = groups + stackNames.length;
      chart.groups = groups;
      chart.groupIDs = groupIDs;
      chart.stackNames = stackNames;
      chart.stackIDs = stackIDs;
      if (isArray(chart.stackNames) && chart.stackNames.length > 0) {
        chart.setStackData();
      }
      chart.updateYDomain();
    },
    /**
     * Function to set date domain of x axis for summary chart
     */
    setOrdinalDomain: function() {
      var chart = this;
      if (!isArray(chart.config.xAxis.categories)) {
        chart.config.xAxis.categories = [];
      }
      chart.innerLegends.forEach(function(legend, i) {
        if (chart.config.xAxis.categories.indexOf(legend) === -1) {
          chart.config.xAxis.categories.push(legend);
        }
      });

      chart.xScale.domain(chart.config.xAxis.categories);
    },
    /**
     * Function to calculate rect width for bar chart
     */

    setRectWidth: function() {
      var chart = this,
        rectWidth,
        startDate,
        endDate,
        count;
      if (chart.config.xAxis.type === 'date') {
        if (
          chart.xScale.domain()[0].getTime() > 0 &&
          chart.xScale.domain()[1].getTime() > 1
        ) {
          startDate = chart.xScale.domain()[0];
          endDate = chart.xScale.domain()[1];
        } else {
          // if no domain is set in d3 brush
          startDate = parseDate(chart.config.xAxis.startDate);
          endDate = parseDate(chart.config.xAxis.endDate);
        }

        if (chart.config.granularity === 'month') {
          count =
            (endDate.getTime() - startDate.getTime()) /
            (24 * 60 * 60 * 1000 * 30);
          chart.months = count;
        } else if (chart.config.granularity === 'week') {
          count =
            (endDate.getTime() - startDate.getTime()) /
            (24 * 60 * 60 * 1000 * 7);
          chart.weeks = count;
        } else {
          count =
            (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
        }

        count = count + 3;
        rectWidth = mathAbs(chart.xRange[0] - chart.xRange[1]) / count;
      } else {
        rectWidth =
          mathAbs(chart.xRange[0] - chart.xRange[1]) /
          chart.config.xAxis.categories.length;
      }
      chart.bandwidth = rectWidth;
      if (chart.groups === 0) {
        chart.rectWidth = rectWidth - rectPadding;
      } else {
        chart.rectWidth = rectWidth / (chart.groups || 1) - rectPadding;
      }
      chart.rectWidth =
        chart.rectWidth > maxRectWidth ? maxRectWidth : chart.rectWidth;
      chart.rectWidth = chart.rectWidth <= 1 ? 1 : chart.rectWidth;
    },
    /**
     * Function to calculate dy for stack chart based on associated group and stacks in each group
     */
    setStackData: function() {
      var chart = this;
      var keys = [],
        index,
        ids,
        i,
        j,
        k,
        innerLegendTotals = {},
        total = 0,
        tempData = {};
      chart.wfLineData = [];

      for (ids in chart.stackIDs) {
        innerLegendTotals = {};
        for (i = 0; i < chart.stackIDs[ids].length; i++) {
          for (
            j = 0;
            j < chart.config.series[chart.stackIDs[ids][i]].data.length;
            j++
          ) {
            index = chart.innerLegends.indexOf(
              chart.config.series[chart.stackIDs[ids][i]].data[j].x
            );
            chart.config.series[chart.stackIDs[ids][i]].data[j].dy =
              innerLegendTotals[
                chart.config.series[chart.stackIDs[ids][i]].data[j].x
              ] || 0;
            innerLegendTotals[
              chart.config.series[chart.stackIDs[ids][i]].data[j].x
            ] =
              (innerLegendTotals[
                chart.config.series[chart.stackIDs[ids][i]].data[j].x
              ] || 0) + chart.config.series[chart.stackIDs[ids][i]].data[j].y;
          }
        }
        //Set WATERFALL data
        if (chart.hasWFChart === true) {
          for (i = 0; i < chart.stackIDs[ids].length; i++) {
            if (
              chart.config.series[chart.stackIDs[ids][i]].type === 'waterfall'
            ) {
              for (
                j = 0;
                j < chart.config.series[chart.stackIDs[ids][i]].data.length;
                j++
              ) {
                index = chart.innerLegends.indexOf(
                  chart.config.series[chart.stackIDs[ids][i]].data[j].x
                );
                if (index > 0) {
                  for (k = index - 1; k >= 0; k--) {
                    chart.config.series[chart.stackIDs[ids][i]].data[j].dy =
                      (chart.config.series[chart.stackIDs[ids][i]].data[j].dy ||
                        0) + (innerLegendTotals[chart.innerLegends[k]] || 0);
                  }
                }
                if (
                  !!tempData[
                    chart.config.series[chart.stackIDs[ids][i]].data[j].x
                  ]
                ) {
                  tempData[
                    chart.config.series[chart.stackIDs[ids][i]].data[j].x
                  ] =
                    tempData[
                      chart.config.series[chart.stackIDs[ids][i]].data[j].x
                    ] + chart.config.series[chart.stackIDs[ids][i]].data[j].y;
                } else {
                  tempData[
                    chart.config.series[chart.stackIDs[ids][i]].data[j].x
                  ] = chart.config.series[chart.stackIDs[ids][i]].data[j].y;
                }
              }
            }
          }
        }
      }
      if (chart.hasWFChart === true) {
        chart.innerLegends.forEach(function(key, i) {
          if (isNumber(tempData[key])) {
            total = total + tempData[key];
          }
          chart.wfLineData.push({ x: key, y: total });
        });
      }
    },
    /**
     * Function to update chart
     */

    updateChart: function() {
      var chart = this;
      if (chart.dirtySeries === true) {
        chart.resetData();
        chart.setGroupProps();
        chart.setRectWidth();
        chart.series.forEach(function(series, idx) {
          series.prepareData(series.config.data);
          if (!series.config.isHidden) {
            series.config.dirty = true;
          }
        });
        chart.yAxis.dirty = true;
        chart.dirtySeries = false;
      }
      if (chart.dirtyData === true) {
        chart.resetData();
        chart.setGroupProps();
        chart.setRectWidth();
        chart.series.forEach(function(series, idx) {
          series.prepareData(series.config.data);
          if (!series.config.isHidden) {
            series.config.dirty = true;
          }
        });
        chart.dirtyData = false;
      }
      if (chart.yAxis.dirty === true) {
        chart.updateYDomain();
        chart.yAxis.resize();
        chart.updateSeries();
        chart.yAxis.dirty = false;
      }
    },
    /**
     * Function to update series alone
     */
    updateSeries: function() {
      var chart = this;
      if (chart.hasWFChart) {
        chart.WFLine.remove();
        chart.WFLine = new Series(chart, {
          type: 'line',
          data: chart.wfLineData,
          index: -1,
          name: 'overlay',
          marker: {
            display: false
          },
          interpolation: 'step-after',
          color: '#cccccc',
          style: {
            strokeWidth: '1px',
            shapeRendering: 'crispEdges'
          }
        });
      }
      chart.series.forEach(function(series, idx) {
        if (series.config.dirty === true) {
          series.redraw();
          series.config.dirty = false;
        } else if (!series.config.isHidden) {
          series.resize();
        }
      });
      if (isArray(chart.config.indicators)) {
        chart.config.indicators.forEach(function(indicator, idx) {
          chart.indicators[idx].resize();
        });
      }
    },
    /**
     * Function to update the domain for y scale
     */
    updateYDomain: function() {
      var chart = this,
        dataMin = 0,
        dataMax = 0;
      chart.yDomain = [0, 1];

      chart.config.series.forEach(function(series, idx) {
        if (
          series.type !== 'pie' &&
          series.type !== 'sunburst' &&
          !series.isHidden
        ) {
          dataMin = d3Min(
            series.data.map(function(d) {
              return d.y;
            })
          );
          dataMax = d3Max(
            series.data.map(function(d) {
              return d.y + (d.dy || 0);
            })
          );
          chart.yDomain[0] = mathMin(0, dataMin || 0, chart.yDomain[0]);
          chart.yDomain[1] = mathMax(dataMax || 0, chart.yDomain[1]);
        }
      });
      if (isArray(chart.config.indicators)) {
        chart.config.indicators.forEach(function(indicator, idx) {
          if (indicator.type === 'yLine' || indicator.type === 'yBand') {
            indicator.data.forEach(function(data, id) {
              if (data > chart.yDomain[1]) {
                chart.yDomain[1] = data;
              }
              if (data < chart.yDomain[0]) {
                chart.yDomain[0] = data;
              }
            });
          } else if (indicator.type === 'line') {
            dataMin = d3Min(
              indicator.data.map(function(d) {
                return d.y;
              })
            );
            dataMax = d3Max(
              indicator.data.map(function(d) {
                return d.y;
              })
            );
            chart.yDomain[0] = mathMin(0, dataMin || 0, chart.yDomain[0]);
            chart.yDomain[1] = mathMax(dataMax || 0, chart.yDomain[1]);
          } else if (indicator.type === 'point') {
            if (indicator.data[0].y > chart.yDomain[1]) {
              chart.yDomain[1] = indicator.data[0].y;
            }
            if (indicator.data[0].y < chart.yDomain[0]) {
              chart.yDomain[0] = indicator.data[0].y;
            }
          }
        });
      }
      if (chart.config.percent === true && chart.yDomain[1] <= 100) {
        chart.yDomain[1] = 100;
      } else {
        chart.yDomain[1] = chart.yDomain[1] * 1.1;
      }
      chart.yScale.domain(chart.yDomain);
    },

    /**
     * Function to zoom timeline chart
     */
    updateOnBrush: function() {
      var chart = this;
      if (isObject(chart.tooltip)) {
        chart.tooltip.hide(true);
      }
      if (d3.event.selection === null) {
        chart.brushDomain = chart.brushScale.domain();
      } else {
        chart.brushDomain = d3.event.selection.map(chart.brushScale.invert);
      }
      chart.xScale.domain([chart.brushDomain[0], chart.brushDomain[1]]);
      chart.setRectWidth();
      if (isObject(chart.xAxis) && chart.xAxis.firstRender === false) {
        chart.xAxis.resize();
      }

      if (chart.hasWFChart) {
        chart.WFLine.resize();
      }
      if (chart.config.percent === true) {
        chart.totalBars.resize();
      }
      if (chart.series) {
        chart.series.forEach(function(series, idx) {
          series.resize();
        });
      }
      if (isArray(chart.config.indicators)) {
        chart.config.indicators.forEach(function(indicator, idx) {
          chart.indicators[idx].resize();
        });
      }
    }
  };
  chartPlus.version = '1.0.0';

  console.log('chartplus whole', chartPlus);
  sampledata.container = divelement;
  var object = new chartPlus.chart(sampledata);
  console.log('object', object);
  return object;
};
export default chartPlusFunction;
