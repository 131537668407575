import userMocks from '../Mock/getUserDataMock.json';

// GET AP call for user details
export async function getUserDetails(userId) {
  if (process.env.NODE_ENV === 'development') {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(userMocks.data[userId]);
      }, 1000);
    });
  } else {
    const resp = await window.AP.request(`/rest/api/user?accountId=${userId}`);
    const data = await JSON.parse(resp.body);
    return data;
  }
}
