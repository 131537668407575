import moment from 'moment';

//use only dev instance

// export const sampledata1 = {
//   keys: ['2019-09-21', '2019-10-05'],
//   legends: ['created', 'viewed'],
//   data: [
//     { key: '2019-09-23', values: { viewed: '1.00' } },
//     { key: '2019-09-24', values: { created: '1.00', viewed: '5.00' } },
//     { key: '2019-09-25', values: { viewed: '2.00' } },
//     { key: '2019-09-26', values: { viewed: '1.00' } },
//     { key: '2019-09-27', values: { viewed: '1.00' } },
//     { key: '2019-09-30', values: { viewed: '1.00' } },
//     { key: '2019-10-01', values: { viewed: '1.00' } }
//   ],
//   type: '',
//   yMagnitude: 'Issue Count'
// };
export const mockfilterresponse = {
  data: {
    '1': {
      viewPointData: {
        keys: ['09/13/2021', '09/27/2021'],
        legends: ['created', 'viewed'],
        data: [
          {
            key: '2021-09-27',
            values: {
              created: '2.00',
              viewed: '4.00'
            }
          },
          {
            key: '2021-09-25',
            values: {
              viewed: '2.00'
            }
          },
          {
            key: '2021-09-24',
            values: {
              created: '1.00',
              viewed: '1.00'
            }
          }
        ],
        type: '',
        yMagnitude: 'Issue Count'
      },
      indicatorData: null
    },
    '2': {
      viewPointData: {
        header: [
          {
            id: '0',
            label: 'Pages',
            type: 'string',
            hide: false,
            drillDown: false
          },
          {
            id: '1',
            label: 'Views',
            type: 'string',
            hide: false,
            drillDown: false
          }
        ],
        data: [
          {
            '0': 'testspace',
            '1': '2  views'
          },
          {
            '0': 'Page test',
            '1': '1  views'
          },
          {
            '0': 'Test',
            '1': '1  views'
          }
        ],
        jiralinks: null
      },
      indicatorData: null
    },
    '3': {
      viewPointData: {
        header: [
          {
            id: '0',
            label: 'User Image',
            type: 'avatar',
            hide: false,
            drillDown: false
          },
          {
            id: '1',
            label: 'Users',
            type: 'string',
            hide: false,
            drillDown: false
          }
        ],
        data: [
          {
            '0':
              'https://rawtests.atlassian.net/wiki/aa-avatar/5f4f468332360700381c48fc',
            '1': 'Rawn Riju'
          },
          {
            '0': 'https://test.com',
            '1': 'Person2'
          },
          {
            '0': 'https://test2.com',
            '1': 'Person3'
          }
        ],
        jiralinks: null
      },
      indicatorData: null
    },
    '4': {
      viewPointData: {
        header: [
          {
            id: '0',
            label: 'User Image',
            type: 'avatar',
            hide: false,
            drillDown: false
          },
          {
            id: '1',
            label: 'Users',
            type: 'string',
            hide: false,
            drillDown: false
          }
        ],
        data: [
          {
            '0':
              'https://rawtests.atlassian.net/wiki/aa-avatar/5f4f468332360700381c48fc',
            '1': 'Rawn Riju'
          },
          {
            '0': 'https://test.com',
            '1': 'Person 2'
          }
        ],
        jiralinks: null
      },
      indicatorData: null
    }
  },
  status: 200,
  statusText: '',
  headers: {
    'access-control-allow-origin': '*',
    authorization:
      'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzI3NDQ4MzUsImlhdCI6MTYzMjczNzYzNSwiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiJURVNUU1BBQ0UifQ.cfYrwSghB5R4-ghO51dUgJ06LqupsqsMif2cmS3PzZY',
    'content-length': '1208',
    'content-type': 'application/json',
    date: 'Mon, 27 Sep 2021 10:13:55 GMT',
    vary: 'Accept-Encoding',
    'x-content-type-options': 'nosniff',
    'x-xss-protection': '1; mode=block'
  },
  request: {}
};
export const mockfilterresponse2 = {
  data: {
    '5': {
      viewPointData: {
        header: [
          {
            id: '0',
            label: 'ID',
            type: 'string',
            hide: true,
            drillDown: false
          },
          {
            id: '1',
            label: 'Title',
            type: 'string',
            hide: false,
            drillDown: false
          },
          {
            id: '2',
            label: 'Creator',
            type: 'string',
            hide: false,
            drillDown: false
          },
          {
            id: '3',
            label: 'Created',
            type: 'Date',
            hide: false,
            drillDown: false
          },
          {
            id: '4',
            label: 'Last Modified',
            type: 'Date',
            hide: false,
            drillDown: false
          },
          {
            id: '5',
            label: 'Last Viewed',
            type: 'Date',
            hide: false,
            drillDown: false
          },
          {
            id: '6',
            label: 'Users Viewed',
            type: 'int',
            hide: false,
            drillDown: true
          },
          {
            id: '7',
            label: 'Total Views',
            type: 'int',
            hide: false,
            drillDown: true
          }
        ],
        data: [
          {
            '0': '33011',
            '1': 'testspace',
            '2': 'Anonymous',
            '3': '2020-09-22T06:36:29Z',
            '4': '',
            '5': '2021-09-27T05:10:57Z',
            '6': '1',
            '7': '2'
          },
          {
            '0': '362872853',
            '1': 'Page test',
            '2': 'Rawn Riju',
            '3': '2021-09-27T05:12:48Z',
            '4': '',
            '5': '2021-09-27T05:13:23Z',
            '6': '1',
            '7': '1'
          },
          {
            '0': '363003905',
            '1': 'Test',
            '2': 'Rawn Riju',
            '3': '2021-09-27T05:12:28Z',
            '4': '',
            '5': '2021-09-27T05:12:31Z',
            '6': '1',
            '7': '1'
          }
        ],
        jiralinks: null
      },
      indicatorData: null
    }
  },
  status: 200,
  statusText: '',
  headers: {
    'access-control-allow-origin': '*',
    authorization:
      'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzI3NDc4OTAsImlhdCI6MTYzMjc0MDY5MCwiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiJURVNUU1BBQ0UifQ.WkNghmU1sNuseps05CASOxFbZKilJZLWoTQjTPrpzDY',
    'content-length': '1061',
    'content-type': 'application/json',
    date: 'Mon, 27 Sep 2021 11:04:50 GMT',
    vary: 'Accept-Encoding',
    'x-content-type-options': 'nosniff',
    'x-xss-protection': '1; mode=block'
  },
  config: {
    url: '/dashboard/pagefilter?viewID=2',
    method: 'post',
    data:
      '{"selected":{"-2":{"label":"Date Range","type":"range","value":["09/13/2021","09/27/2021","Custom"]},"-26":{"label":"Content","type":"single","value":["All"]}}}',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=utf-8',
      authorization:
        'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzI3NDc4ODcsImlhdCI6MTYzMjc0MDY4NywiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiJURVNUU1BBQ0UifQ.1PddhDxen61Hy9SHgF9damTDHSJfyaCoYlz076H87og'
    },
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1
  },
  request: {}
};
export const mockfilterresponse3 = {
  data: {
    '6': {
      viewPointData: {
        header: [
          {
            id: '0',
            label: 'ID',
            type: 'string',
            hide: true,
            drillDown: false
          },
          {
            id: '1',
            label: 'User Image',
            type: 'avatar',
            hide: false,
            drillDown: false
          },
          {
            id: '2',
            label: 'User',
            type: 'string',
            hide: false,
            drillDown: false
          },
          {
            id: '3',
            label: 'Created',
            type: 'int',
            hide: false,
            drillDown: true
          },
          {
            id: '4',
            label: 'Updated',
            type: 'int',
            hide: false,
            drillDown: true
          },
          {
            id: '5',
            label: 'Viewed',
            type: 'int',
            hide: false,
            drillDown: true
          }
        ],
        data: [
          {
            '0': '5f4f468332360700381c48fc',
            '1':
              'https://rawtests.atlassian.net/wiki/aa-avatar/5f4f468332360700381c48fc',
            '2': 'Rawn Riju',
            '3': '2',
            '4': '0',
            '5': '3'
          }
        ],
        jiralinks: null
      },
      indicatorData: null
    }
  },
  status: 200,
  statusText: '',
  headers: {
    'access-control-allow-origin': '*',
    authorization:
      'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzI3NDc5NTgsImlhdCI6MTYzMjc0MDc1OCwiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiJURVNUU1BBQ0UifQ.cqh2cdHtagUqkofTAHudXoAjSDF5nra0yCv92iWKGTY',
    'content-length': '671',
    'content-type': 'application/json',
    date: 'Mon, 27 Sep 2021 11:05:58 GMT',
    vary: 'Accept-Encoding',
    'x-content-type-options': 'nosniff',
    'x-xss-protection': '1; mode=block'
  },
  config: {
    url: '/dashboard/pagefilter?viewID=3',
    method: 'post',
    data:
      '{"selected":{"-2":{"label":"Date Range","type":"range","value":["09/13/2021","09/27/2021","Custom"]},"-26":{"label":"Content","type":"single","value":["All"]}}}',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=utf-8',
      authorization:
        'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzI3NDc4OTAsImlhdCI6MTYzMjc0MDY5MCwiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiJURVNUU1BBQ0UifQ.WkNghmU1sNuseps05CASOxFbZKilJZLWoTQjTPrpzDY'
    },
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1
  },
  request: {}
};

export const sampledata2 = {
  keys: ['2019-09-21', '2019-10-05'],
  legends: ['created', 'viewed'],
  data: [
    { key: '2019-09-23', values: { viewed: '1.00' } },
    { key: '2019-09-24', values: { created: '1.00', viewed: '5.00' } },
    { key: '2019-09-25', values: { viewed: '2.00' } },
    { key: '2019-09-26', values: { viewed: '1.00' } },
    { key: '2019-09-27', values: { viewed: '1.00' } },
    { key: '2019-09-30', values: { viewed: '1.00' } },
    { key: '2019-10-01', values: { viewed: '1.00' } }
  ],
  type: '',
  yMagnitude: 'Issue Count'
};

export const mockGeneralgetdata = {
  data: {
    segments: [
      {
        label: 'OVERVIEW FILTER',
        config: ['-27', '-2', '-26', '-3', '-28']
      }
    ],
    config: {
      '-2': {
        label: 'Date Range',
        type: 'range',
        value: [
          {
            label: 'None',
            type: 'none',
            value: []
          },
          {
            label: 'Custom',
            type: 'custom',
            value: []
          },
          {
            label: 'Today',
            type: 'predefined',
            value: []
          },
          {
            label: 'Yesterday',
            type: 'predefined',
            value: []
          },
          {
            label: 'Last week',
            type: 'predefined',
            value: []
          },
          {
            label: 'Last 14 days',
            type: 'predefined',
            value: []
          },
          {
            label: 'Last 30 days',
            type: 'predefined',
            value: []
          },
          {
            label: 'Last 3 months',
            type: 'predefined',
            value: []
          },
          {
            label: 'Last 6 months',
            type: 'predefined',
            value: []
          }
        ]
      },
      '-26': {
        label: 'Content',
        type: 'single',
        value: ['Pages', 'All'],
        required: true
      },
      '-27': {
        label: 'Spaces',
        type: 'multilist',
        value: [
          'All Spaces',
          'P1',
          'P2',
          'SR2',
          'SER',
          'TES',
          'TESTSPACE',
          'TEST'
        ]
      },
      '-28': {
        label: 'Display',
        type: 'single',
        value: ['Total', 'Unique'],
        required: true
      },
      '-3': {
        label: 'Group By',
        type: 'single',
        value: ['Day', 'Week', 'Month'],
        required: true
      }
    },
    savedFilters: [],
    selected: {
      '-2': {
        label: 'Date Range',
        type: 'range',
        value: ['2021-10-05', '2021-10-18', '', '']
      },
      '-26': {
        label: 'Content',
        type: 'single',
        value: ['All']
      },
      '-27': {
        label: 'Spaces',
        type: 'multilist',
        value: ['All Spaces']
      },
      '-28': {
        label: 'Display',
        type: 'single',
        value: ['Total']
      },
      '-3': {
        label: 'Group By',
        type: 'single',
        value: ['Day']
      }
    }
  },
  status: 200,
  statusText: '',
  headers: {
    'access-control-allow-origin': '*',
    authorization:
      'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzQ1NDYzNTcsImlhdCI6MTYzNDUzOTE1NywiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiIifQ.WH6DJ976WWDaOy015VkDlC8gc9rZckrmh_5cPJYRcFA',
    'content-length': '1344',
    'content-type': 'application/json',
    date: 'Mon, 18 Oct 2021 06:39:17 GMT',
    vary: 'Accept-Encoding',
    'x-content-type-options': 'nosniff',
    'x-xss-protection': '1; mode=block'
  },
  config: {
    url: '/dashboard/pagefilter?viewID=1',
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      authorization:
        'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzQ1NDYzNTYsImlhdCI6MTYzNDUzOTE1NiwiaXNzIjoiVklFVzI2IiwibmFtZSI6IiIsInJvbGUiOiIiLCJ1c2VyRW1haWwiOiIiLCJzdGF0dXMiOiIiLCJhZG1pbkVtYWlsIjoiIiwiZmlyc3RMb2dpbiI6ZmFsc2UsImlkIjoiIiwiYWNjSUQiOiIxIiwic3BhY2VLZXkiOiIifQ.cJLRdQgG6vnETnpeCMrARecGEXnu-Ch5aYd2AT4Kx7w'
    },
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1
  },
  request: {}
};

export const mockSpacegetdata = {};

/*export const Fakepageview = [
  {
    '0': '488837',
    '1': 'Jenkins Plugin',
    '2': 'Ajay Emmanuel',
    '3': moment('2019-08-19T15:17:13Z').fromNow(),
    '4': 'sad',
    '5': 'as',
    '6': '1',
    '7': '1'
  },
  {
    '0': '14352385',
    '1': 'MultiSelect',
    '2': 'Ajay Emmanuel',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': 'erte',
    '5': 'ert',
    '6': '333',
    '7': '4442'
  },
  {
    '0': '14352385',
    '1': 'MultiSelect',
    '2': 'Ajay Emmanuel',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': 'ert',
    '5': 'ert',
    '6': '211',
    '7': '3343'
  },
  {
    '0': '14352385',
    '1': 'MultiSelect',
    '2': 'Ajay Emmanuel',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': 'ert',
    '5': 'ghj',
    '6': '1',
    '7': '23'
  },
  {
    '0': '14352385',
    '1': 'MultiSelect',
    '2': 'Ajay Emmanuel',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': 'ghjg',
    '5': 'sdf',
    '6': '123',
    '7': '23'
  },
  {
    '0': '14352385',
    '1': 'MultiSelect',
    '2': 'Ajay Emmanuel',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': 'fdf',
    '5': 'sadd',
    '6': '123',
    '7': '123'
  }
];

export const Fakeuserview = [
  {
    '0': '123123',
    '1': 'Emmanuel',
    '2': 'GHOSH',
    '3': moment('2019-08-19T15:17:13Z').fromNow(),
    '4': '211'
  },
  {
    '0': '123123',
    '1': 'Ajay Ghosh',
    '2': 'GHOSH',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': '331'
  },
  {
    '0': '123123',
    '1': 'Akhil',
    '2': 'GHOSH',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': '1'
  },
  {
    '0': '123123',
    '1': 'Ajay Emmanuel',
    '2': 'GHOSH',
    '3': moment('2017-03-25T15:17:13Z').fromNow(),
    '4': '1'
  }
];




export const fakeMostPopularContent = [
  { 0: 'ample pages', 1: '6 views' },
  { 0: 'Sample pages', 1: '9 views' },
  { 0: 'Sample pages', 1: '9 views' }
];

export const fakeMostActiveContri = [
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ghosh'
  },
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ajay Ghosh'
  },
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ajay Ghosh'
  }
];

export const fakeMostActiveReader = [
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ajay Ghosh'
  },
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ajay Ghosh'
  },
  {
    0: 'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    1: 'Ajay Ghosh'
  }
];

;*/
export const fakeChartData = [
  {
    id: 'Created',
    data: [
      { x: '2018-01-01', y: 7 },
      { x: '2018-01-09', y: 5 },
      { x: '2018-01-22', y: 11 },
      { x: '2018-02-04', y: 9 },
      { x: '2018-02-15', y: 19 },
      { x: '2018-03-06', y: 31 },
      { x: '2018-03-21', y: 13 },
      { x: '2018-03-31', y: 5 }
    ]
  },
  {
    id: 'Viewed',
    data: [
      { x: '2018-01-04', y: 14 },
      { x: '2018-01-14', y: 14 },
      { x: '2018-02-19', y: 23 },
      { x: '2018-02-28', y: 11 },
      { x: '2018-03-11', y: 10 },
      { x: '2018-03-15', y: 12 },
      { x: '2018-03-25', y: 9 },
      { x: '2018-04-11', y: 7 }
    ]
  }
];
export const fakeUserDialog = [
  {
    '0': 'Sample pages',
    '1': moment('2017-03-25T15:17:13Z').fromNow()
  },
  {
    '0': 'Sample pages',
    '1': moment('2019-08-19T15:17:13Z').fromNow()
  }
];
export const fakedatachart = [
  { key: '2019-07-24', values: { created: '1.00' } },
  { key: '2019-08-02', values: { viewed: '2.00' } },
  { key: '2019-08-05', values: { viewed: '4.00' } },
  { key: '2019-08-06', values: { viewed: '1.00' } },
  { key: '2019-08-21', values: { viewed: '4.00' } },
  { key: '2019-08-27', values: { viewed: '5.00' } },
  { key: '2019-08-28', values: { viewed: '1.00' } },
  { key: '2019-08-29', values: { viewed: '2.00' } },
  { key: '2019-08-30', values: { viewed: '1.00' } },
  { key: '2019-09-02', values: { viewed: '1.00' } }
];
export const fakePageDialog = [
  {
    '1':
      'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    '2': 'GHOSH',
    '3': moment('2017-03-25T15:17:13Z').fromNow()
  },
  {
    '1':
      'https://actio-ajayghosh.atlassian.net/wiki/aa-avatar/5d36a5acc2db730c59b26ccb',
    '2': 'GHOSH',
    '3': moment('2019-08-19T15:17:13Z').fromNow()
  }
];
