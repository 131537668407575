import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { token } from '@atlaskit/tokens';

export const MostPopularContent = props => {
  // console.log('props', props);
  let rowarray = [];
  //creation of table syntax
  props.table_data.data.map((item, index) => {
    let cells = [
      {
        key: item['0'],
        content: <div style={{ padding: '10px' }}>{item['0']}</div>,
        isSortable: true,
        shouldTruncate: true
      },
      { key: item['1'], content: item['1'] }
    ];
    rowarray.push({ cells: cells, isSortable: true, key: index });
    return 0;
  });
  //-------------------

  //head properties
  const head = {
    cells: [
      {
        key: 'Name',
        content: 'Name',
        isSortable: true
      },
      {
        key: 'Views',
        content: 'Views',
        isSortable: true
      }
    ]
  };

  return (
    <div>
      <h6 style={{ color: token('color.text'), marginTop: '25px' }}>
        Most Popular Contents
      </h6>
      <DynamicTable
        head={head}
        rows={rowarray}
        isLoading={props.table_data.loading}
        emptyView={
          <h6 style={{ color: token('color.text') }}>
            No matching records found
          </h6>
        }
      />
    </div>
  );
};
