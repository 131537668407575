import React, { useEffect, useState } from 'react';
import Filterview from '../FilterComponent/FilterView';
import styles from './Pageviews.module.css';
import { PageviewSearch } from './PageviewSearch';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import { getUserDetails } from '../ApCalls';
import { token } from '@atlaskit/tokens';

export function Pageview(props) {
  const [viewData] = useRecoilState(ViewDataAtom);
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  const [jsmAgentInstalled, setJsmAgentInstalled] = useState('');
  const PageViewTable = viewData.page_view_table;
  let users = [];

  useEffect(() => {
    users.forEach(user => {
      if (user !== '0') {
        fetchUserDetails(user);
      }
    });
  }, [PageViewTable.loading]);

  async function fetchUserDetails(userId) {
    const resp = await getUserDetails(userId);
    const baseURL = resp?._links?.base.slice(0, -5);
    const pathURL = resp?.profilePicture?.path;
    const imageURL = baseURL + pathURL;
    const displayName = resp?.displayName;
    // userDetails[userId] = imageURL
    setUserDetails(prev => ({
      [userId]: {
        imageURL: imageURL,
        displayName: displayName
      },
      ...prev
    }));
  }
  PageViewTable.data.forEach(data => {
    let flag = true;
    users.forEach(val => {
      if (val === data[viewData.page_view_table.userHeaderId]) {
        flag = false;
      }
    });
    if (flag) {
      users.push(data[viewData.page_view_table.userHeaderId]);
    }
  });

  return (
    <div className={styles.whole}>
      <Filterview setJsmAgentInstalled={setJsmAgentInstalled} />
      <div className={styles.components}>
        <h6
          className={styles.components}
          style={{ color: token('color.text'), textTransform: 'capitalize' }}
        >
          PAGE VIEWS
        </h6>

        <PageviewSearch
          tabledata={PageViewTable}
          jsmAgentInstalled={jsmAgentInstalled}
        />
      </div>
    </div>
  );
}
