import { datadogRum } from '@datadog/browser-rum';

export default function initDatadog() {
  datadogRum.init({
    applicationId: '5535159a-cf30-4a50-93c4-f69604470877',
    clientToken: 'pub0ce23621cb24dd3006f9e21467b8510a',
    site: 'datadoghq.com',
    service: 'pageview-insights',
    version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    useCrossSiteSessionCookie: true
  });

  datadogRum.startSessionReplayRecording();
}
