import React from 'react';
import Textfield from '@atlaskit/textfield';
import DynamicTable from '@atlaskit/dynamic-table';
import SearchIcon from '@atlaskit/icon/glyph/editor/search';
import Button from '@atlaskit/button';
import { dialog_xlsx_array_create } from '../Functional/Download';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import {
  createUserdialogSyntax,
  createPageDialogSyntax
} from '../Functional/TableSyntax';
import { useRecoilState } from 'recoil';
import UserDetailsAtom from '../Recoil/Atoms/UserDetailsAtom';
import { token } from '@atlaskit/tokens';

export const Dialogscreen = props => {
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);

  // console.log('loading', props);
  let DialogTableSyntax;
  //create dialog box based on the tab(pageview or user)
  if (props.id === 1) {
    DialogTableSyntax = createPageDialogSyntax(
      props.rows,
      userDetails,
      props.dialogData.id,
      props.Sourcevalue,
      props.baseURL
    );
  } else {
    DialogTableSyntax = createUserdialogSyntax(
      props.rows,
      props.Sourcevalue,
      props.baseURL,
      props.colId
    );
  }
  //console.log('DATA', DialogTableSyntax, props.rows);
  return (
    <div style={{ padding: '15px', margin: '15px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '30px',
          alignItems: 'center'
        }}
      >
        <Textfield
          name="search"
          placeholder="Contains text"
          width="xlarge"
          elemBeforeInput={
            <div style={{ paddingLeft: '6px', lineHeight: '100%' }}>
              <SearchIcon />
            </div>
          }
          defaultValue={props.search}
          onChange={e => props.searchfunction(e.target.value)}
        />
        <Button
          style={{ padding: '3px' }}
          onClick={() => {
            dialog_xlsx_array_create(
              props.rows,
              props.id,
              props.table.head.cells[1].content,
              userDetails,
              props.dialogData.id
            );
          }}
          spacing="compact"
          appearance="subtle"
        >
          <ArrowDownIcon />
        </Button>
      </div>
      <h3 style={{ color: token('color.text'), margin: '15px 0 10px 0' }}>
        Users
      </h3>
      <DynamicTable
        // caption={'Users'}
        head={props.table.head}
        rows={DialogTableSyntax}
        rowsPerPage={10}
        page={1}
        isLoading={props.loading}
        emptyView={
          <h6 style={{ color: token('color.text') }}>
            No matching records found
          </h6>
        }
      />
    </div>
  );
};
