//Pageview
export const ID = '0';
export const NAME = '1';
export const CREATOR = '2';
export const CREATED = '3';
export const LASTMODIFIED = '4';
export const LASTVIEWED = '5';
export const USERVIEWED = '6';
export const TOTALVIEWS = '7';
export const JSMUSERTNAME = '6';
export const USERTPORTALID = '7';
export const USERTPORTALNAME = '8';
export const REQUESTS = '8';
export const USERTREQUESTTYPEID = '9';
export const USERTREQUESTTYPENAME = '10';
export const DaterangeCode = '-2';
export const ContentCode = '-26';
export const GroupbyCode = '-3';
export const DisplayCode = '-28';
export const PAGEVIEWTABLEHEADING = {
  head: {
    cells: [
      {
        key: 'Title',
        content: 'Title',
        isSortable: true
      },
      {
        key: 'Creator',
        content: 'Creator',
        isSortable: true
      },
      {
        key: 'Created',
        content: 'Created',
        isSortable: true
      },
      {
        key: 'Last Modified',
        content: 'Last Modified',
        isSortable: true
      },
      {
        key: 'Last Viewed',
        content: 'Last Viewed',
        isSortable: true
      },
      {
        key: 'Users Viewed',
        content: 'Users Viewed',
        isSortable: true
      },
      {
        key: 'Total Views',
        content: 'Total Views',
        isSortable: true
      }
    ]
  }
};

export const PAGEVIEWJSMTABLEHEADING = {
  head: {
    cells: [
      {
        key: 'Title',
        content: 'Title',
        isSortable: true
      },
      {
        key: 'Creator',
        content: 'Creator',
        isSortable: true
      },

      {
        key: 'Last Viewed',
        content: 'Last Viewed',
        isSortable: true
      },
      {
        key: 'Requests',
        content: 'Requests',
        isSortable: true
      },
      {
        key: 'Users Viewed',
        content: 'Users Viewed',
        isSortable: true
      },
      {
        key: 'Total Views',
        content: 'Total Views',
        isSortable: true
      }
    ]
  }
};

//dialog
export const DIALOGID = '0';
export const DIALOGUSER = '1';
export const DIALOGVIEWED = '2';

export const USERAVATAR = '1';
export const USERT = '2';
export const USERTCREATED = '3';
export const USERTUPDATED = '4';
export const USERTVIEWED = '5';
export const USERTDIALOGPAGE = '0';
export const USERTDIALOGLAST = '1';
export const USERNAME = '3';
export const USERTREQTYPEID = '4';
export const USERTREQTYPENAME = '5';
export const USERTREQKEY = '6';
export const USERREQUESTS = '7';
export const USERDIALOGREQID = '2';
export const USERDIALOGREQTYPE = '3';
export const USERDIALOGREQKEY = '4';
export const USERREQID = '1';
export const USERREQTYPE = '2';
export const USERREQKEY = '3';

export const GroupbyList = ['Day', 'Week', 'Month'];
export const DisplayList = ['Total', 'Unique'];
export const ContentList = ['All', 'Pages'];
export const SourceList = ['JSM Customer Portal', 'Confluence'];
