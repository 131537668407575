import React, { useEffect, useState } from 'react';
import Textfield from '@atlaskit/textfield';
import { PageViewTable } from './PageviewTable';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { Dialogscreen } from '../Dialogscreen';
import { createPagetableSyntax } from '../../Functional/TableSyntax';
import SearchIcon from '@atlaskit/icon/glyph/editor/search';
import { pageview_xlsx_array_create } from '../../Functional/Download';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import {
  PageViewFilterfunction,
  PageViewDialogFilterfunction
} from '../../Functional/FilterFunctions';
import Axios from 'axios';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import AccountAtom from '../../Recoil/Atoms/AccountAtom';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
import { fetchDataView } from '../../API/dataViewApis';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { getUserDetails } from '../ApCalls';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import jwtDecode from 'jwt-decode';
import SectionMessage, {
  SectionMessageAction
} from '@atlaskit/section-message';
//import { PageviewDialogtransform } from '../../Functional/Transfrom';

export const PageviewSearch = props => {
  const [searchkeyword, setSearchKeyword] = useState('');
  const [ModalState, setModalState] = useState('');
  const [DialogSearchKeyword, setDialogSearchKeyword] = useState('');
  const [header, setheader] = useState('');
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);

  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const [accountData, setAccountData] = useRecoilState(AccountAtom);
  const [filterData] = useRecoilState(FilterAtom);
  const [showDataFlag, setShowDataFlag] = useState(false);
  const [dialogData, setDialogData] = useState({
    users: [],
    id: ''
  });

  const PageDialog = viewData.page_dialog;
  const JWT = accountData.JWT;
  const StartDate = filterData.start_date;
  const EndDate = filterData.end_date;
  const Contentvalue = filterData.content_value;
  const Sourcevalue = filterData.source_value;
  const DialogLoading = viewData.is_dialog_loading;

  // useeffect to fetch user details of the users in the dialog modal
  useEffect(() => {
    dialogData.users.forEach(user => {
      if (user !== '0' && Sourcevalue !== 'JSM Customer Portal') {
        fetchUserDetails(user);
      }
    });
  }, [DialogLoading]);
  const decoded = jwtDecode(JWT);
  let jwtBaseUrl = decoded?.baseURL;
  let baseURL = jwtBaseUrl?.slice(0, -5);
  async function fetchUserDetails(userId) {
    const resp = await getUserDetails(userId);
    const baseURL = resp?._links?.base.slice(0, -5);
    const pathURL = resp?.profilePicture?.path;
    const imageURL = baseURL + pathURL;
    const displayName = resp?.displayName;
    setUserDetails(prev => ({
      [userId]: {
        imageURL: imageURL,
        displayName: displayName
      },
      ...prev
    }));
  }

  //dialogsearch state setting function
  const dialogSearch = value => {
    setDialogSearchKeyword(value);
  };
  //dialg search function with orginal data and search keyword as parameter
  let dialogFilterTableValue = PageViewDialogFilterfunction(
    PageDialog,
    DialogSearchKeyword,
    userDetails
  );
  //console.log('DRILLDOWN', dialogFilterTableValue);
  //making api request
  const buttonClick = async (value, id) => {
    setViewData(prev => ({
      ...prev,
      is_dialog_loading: true
    }));
    setModalState(1);
    let reqpayload = {
      selected: {
        '-2': {
          label: 'Date Range',
          type: 'range',
          value: [StartDate, EndDate, 'Custom']
        },
        '-26': {
          label: 'Content',
          type: 'single',
          value: [Contentvalue]
        },

        '-29': {
          label: 'Source',
          type: 'single',
          value: [Sourcevalue]
        }
      }
    };
    let reqquery = 'columnID=' + id + '&id=5&rowID=' + value;
    const response = await fetchDataView(reqquery, reqpayload, JWT);
    if (response.success) {
      setAccountData(prev => ({
        ...prev,
        is_authenticated: true,
        JWT: response.headers['authorization']
      }));

      //let tranfromed = PageviewDialogtransform(res.data.data);
      //Dispatch({type:"DIALOGHEAD",payload:{0:id,1:"Page"}})
      let headerId = '';
      response.data.header.forEach(header => {
        if (header.type === 'user') {
          headerId = header.id;
          setDialogData(prev => {
            return {
              ...prev,
              id: header.id
            };
          });
        }
      });
      let array = [];
      response.data.data.forEach(data => {
        let flag = true;
        array.forEach(elem => {
          if (elem === data[headerId]) {
            flag = false;
          }
        });
        if (flag) {
          array.push(data[headerId]);
        }
      });

      setDialogData(prev => {
        return {
          ...prev,
          users: [...array]
        };
      });
      setViewData(prev => ({
        ...prev,
        page_dialog: response.data.data,
        is_dialog_loading: false
      }));

      //different header creation for different dialog box tables
      if (id == 6) {
        setheader({
          head: {
            cells: [
              {
                key: 'USER',
                content: 'User'
              },
              {
                key: 'LAST VIEWED',
                content: 'Last Viewed'
              }
            ]
          }
        });
      } else if (id === 7 && Sourcevalue === 'Confluence') {
        setheader({
          head: {
            cells: [
              {
                key: 'USER',
                content: 'User'
              },
              {
                key: 'VIEWED',
                content: 'Viewed'
              }
            ]
          }
        });
      } else if (id === 7 && Sourcevalue === 'JSM Customer Portal') {
        setheader({
          head: {
            cells: [
              {
                key: 'USER',
                content: 'User'
              },
              {
                key: 'VIEWED',
                content: 'Viewed'
              },
              {
                key: 'REQUESTTYPE',
                content: 'Request Type'
              },
              {
                key: 'REQUESTKEY',
                content: 'Request Key'
              }
            ]
          }
        });
      } else if (id === 8) {
        setheader({
          head: {
            cells: [
              {
                key: 'USER',
                content: 'User'
              },
              {
                key: 'LAST VIEWED',
                content: 'Last Viewed'
              },
              {
                key: 'REQUESTTYPE',
                content: 'Request Type'
              },
              {
                key: 'REQUESTKEY',
                content: 'Request Key'
              }
            ]
          }
        });
      }
    }
  };
  //modal close function
  const closeClick = value => {
    // console.log(value);
    setModalState('');
  };

  const Dialog_actions = [{ text: 'Close', onClick: closeClick }];

  //Pageview search function that takes table and search keyword
  let FilteredTableValue = PageViewFilterfunction(
    props.tabledata.data,
    searchkeyword
  );
  // console.log('props in pageview', { FilteredTableValue });
  //create table syntax function that takes table data and button click function for opening corresponding dialog box

  let TableSyntaxValue = createPagetableSyntax(
    FilteredTableValue,
    buttonClick,
    userDetails,
    viewData.page_view_table.userHeaderId,
    Sourcevalue
  );

  // useEffect(() => {
  //   if (
  //     Sourcevalue === 'JSM Customer Portal' &&
  //     props.jsmAgentInstalled === true
  //   ) {

  //     setShowDataFlag(true);
  //     console.log("inside PV 1st")
  //   } else if (Sourcevalue === 'Confluence') {

  //     setShowDataFlag(true);

  //   }else if(Sourcevalue === 'JSM Customer Portal' &&
  //   props.jsmAgentInstalled === false) {
  //     setShowDataFlag(false);
  //   }

  // }, [])

  // console.log("DATA FLAG", showDataFlag)

  const clearSearch = () => setSearchKeyword('');

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Textfield
          name="search"
          placeholder="Contains text"
          width="xlarge"
          autoFocus={true}
          elemAfterInput={
            <div
              onClick={clearSearch}
              style={{
                padding: '0 6px',
                lineHeight: '100%',
                cursor: 'pointer'
              }}
            >
              {searchkeyword.trim() && <CrossCircleIcon primaryColor="grey" />}
            </div>
          }
          elemBeforeInput={
            <div style={{ paddingLeft: '6px', lineHeight: '100%' }}>
              <SearchIcon />
            </div>
          }
          value={searchkeyword}
          onChange={e => setSearchKeyword(e.target.value)}
        />
        <Button
          onClick={() => {
            pageview_xlsx_array_create(FilteredTableValue, userDetails);
          }}
          appearance="default"
          iconBefore={<ArrowDownIcon />}
        ></Button>
      </div>
      {((Sourcevalue === 'JSM Customer Portal' &&
        props.jsmAgentInstalled === true) ||
        Sourcevalue === 'Confluence') && (
        <>
          <div style={{ padding: '10px 0px' }}>
            <h6 style={{ textTransform: 'capitalize', color: '#919aaa' }}>
              {TableSyntaxValue.length} Records
            </h6>
          </div>
          {/* modal fucntion */}
          <ModalTransition>
            {ModalState && (
              <Modal
                actions={Dialog_actions}
                onClose={closeClick}
                heading="Drill-down Data"
              >
                <Dialogscreen
                  table={header}
                  rows={dialogFilterTableValue}
                  search={DialogSearchKeyword}
                  searchfunction={dialogSearch}
                  id={1}
                  loading={DialogLoading}
                  dialogData={dialogData}
                  Sourcevalue={Sourcevalue}
                  baseURL={baseURL}
                />
              </Modal>
            )}
          </ModalTransition>
          <PageViewTable
            values={TableSyntaxValue}
            loading={props.tabledata.loading}
            // showDataFlag={showDataFlag}
          />
        </>
      )}
      {Sourcevalue === 'JSM Customer Portal' &&
        props.jsmAgentInstalled === false && (
          <div style={{ paddingTop: '40px' }}>
            <SectionMessage
              title="Missing Companion App"
              actions={[
                <SectionMessageAction href="#">
                  Install App
                </SectionMessageAction>
              ]}
            >
              <p>
                To access page-views for the JSM knowledge-base articles, you
                must install a JSM companion App available on the Atlassian
                Marketplace for free.
              </p>
            </SectionMessage>
          </div>
        )}
    </div>
  );
};
