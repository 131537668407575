import { atom } from 'recoil';

export const initialViewDataState = {
  tab_index: 0,
  chart_flag: 0,
  onboarding: 0,
  drawer: false,
  page_view_table: { data: [], loading: true },
  user_view_table: { data: [], loading: true },
  user_dialog: [],
  page_dialog: [],
  active_contributores: { data: [], loading: true },
  active_reader: { data: [], loading: true },
  popular_content: { data: [], loading: true },
  timeline_chart: { data: [], loading: true },
  overview_loading: false,
  is_dialog_loading: true,
  space_setting: false,
  spaces_list: []
};

const ViewDataAtom = atom({
  default: initialViewDataState,
  key: 'VIEW_DATA_ATOM'
});

export default ViewDataAtom;
