import React, { Fragment } from 'react';
import { Modal } from '@atlaskit/onboarding';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';

export const First = () => {
  //first large onboarding screen that include the image
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const Onboarding = viewData.onboarding;

  return (
    <Fragment>
      {Onboarding === 1 && (
        <Modal
          actions={[
            {
              onClick: () => setViewData(prev => ({ ...prev, onboarding: 2 })),
              text: 'Next'
            },
            {
              onClick: () => setViewData(prev => ({ ...prev, onboarding: 0 })),
              text: 'Later'
            }
          ]}
          heading="Get Started with Insights"
          key="welcome"
        >
          <img
            alt="overview"
            src="/app/view26overview.png"
            style={{ maxHeight: '200px' }}
          />
          <p>Going forward you can view activity on any space, page or blog </p>
        </Modal>
      )}
    </Fragment>
  );
};
