import {
  ID,
  NAME,
  CREATOR,
  CREATED,
  LASTMODIFIED,
  LASTVIEWED,
  USERVIEWED,
  TOTALVIEWS,
  DIALOGID,
  DIALOGUSER,
  DIALOGVIEWED,
  USERT,
  USERTCREATED,
  USERTUPDATED,
  USERTVIEWED,
  USERTDIALOGLAST,
  USERTDIALOGPAGE,
  USERAVATAR,
  JSMUSERTNAME,
  USERNAME,
  USERTREQTYPEID,
  USERTREQTYPENAME,
  USERTREQKEY,
  REQUESTS,
  USERREQUESTS,
  USERDIALOGREQID,
  USERDIALOGREQTYPE,
  USERDIALOGREQKEY,
  USERREQID,
  USERREQTYPE,
  USERREQKEY
} from './Strings';

//search function for pageview table
export const PageViewFilterfunction = (obj, filterValue) => {
  let raw_array = [];
  let regex = new RegExp(filterValue, 'i');
  raw_array = [];

  raw_array = Object.keys(obj).reduce((acc, val) => {
    if (
      obj[val][REQUESTS] &&
      (obj[val][NAME].match(regex) || obj[val][CREATOR].match(regex))
    ) {
      acc.push([
        obj[val][NAME],
        obj[val][CREATOR],
        obj[val][CREATED],
        obj[val][LASTVIEWED],
        obj[val][USERVIEWED],
        obj[val][TOTALVIEWS],
        obj[val][ID],
        obj[val][REQUESTS]
      ]);
    } else if (obj[val][NAME].match(regex) || obj[val][CREATOR].match(regex)) {
      acc.push([
        obj[val][NAME],
        obj[val][CREATOR],
        obj[val][CREATED],
        obj[val][LASTMODIFIED],
        obj[val][LASTVIEWED],
        obj[val][USERVIEWED],
        obj[val][TOTALVIEWS],
        obj[val][ID]
      ]);
    }
    return acc;
  }, []);

  return raw_array;
};

//search function foe pageview dailog box
export const PageViewDialogFilterfunction = (obj, filterValue, userDetails) => {
  let raw = [];
  raw.length = 0;
  let regex = new RegExp(filterValue, 'i');
  Object.keys(obj).reduce(
    (acc, val) => {
      if (obj[val][USERTREQTYPEID] && obj[val][USERNAME].match(regex)) {
        raw.push([
          obj[val][DIALOGID],
          obj[val][DIALOGUSER],
          obj[val][DIALOGVIEWED],
          obj[val][USERNAME],
          obj[val][USERTREQTYPEID],
          obj[val][USERTREQTYPENAME],
          obj[val][USERTREQKEY]
        ]);
      } else if (userDetails[obj[val][DIALOGUSER]]?.displayName.match(regex)) {
        raw.push([
          obj[val][DIALOGID],
          obj[val][DIALOGUSER],
          obj[val][DIALOGVIEWED],
          obj[val][USERNAME]
        ]);
      }
    },
    { as: 0 }
  );
  return raw;
};

//search function for user table
export const UserFilterfunction = (obj, filterValue, userDetails) => {
  let raw_array = [];
  let regex = new RegExp(filterValue, 'i');
  Object.keys(obj).reduce(
    (acc, val) => {
      if (
        obj[val][USERTCREATED] &&
        userDetails[obj[val][USERT]]?.displayName.match(regex)
      ) {
        raw_array.push([
          obj[val][ID],
          obj[val][USERAVATAR],
          obj[val][USERT],
          obj[val][USERTCREATED],
          obj[val][USERTUPDATED],
          obj[val][USERTVIEWED]
        ]);
      } else if (
        obj[val][JSMUSERTNAME] &&
        userDetails[obj[val][USERT]]?.displayName.match(regex)
      ) {
        raw_array.push([
          obj[val][ID],
          obj[val][USERAVATAR],
          obj[val][USERT],
          obj[val][USERTVIEWED],
          obj[val][JSMUSERTNAME],
          obj[val][USERREQUESTS]
        ]);
      }
    },
    { None: 0 }
  );
  return raw_array;
};

//search function for user dialog box
export const UserDialogFilterfunction = (obj, filterValue) => {
  let raw = [];
  let regex = new RegExp(filterValue, 'i');
  Object.keys(obj).reduce(
    (acc, val) => {
      if (
        obj[val]['0'] &&
        obj[val][USERDIALOGREQKEY] &&
        obj[val][USERTDIALOGLAST] &&
        obj[val][USERTDIALOGPAGE].match(regex)
      ) {
        raw.push([
          obj[val][USERTDIALOGPAGE],
          obj[val][USERTDIALOGLAST],
          obj[val][USERDIALOGREQID],
          obj[val][USERDIALOGREQTYPE],
          obj[val][USERDIALOGREQKEY]
        ]);
      } else if (
        obj[val]['0'] &&
        obj[val][USERREQKEY] &&
        obj[val][USERTDIALOGPAGE].match(regex)
      ) {
        raw.push([
          obj[val][USERTDIALOGPAGE],
          obj[val][USERREQID],
          obj[val][USERREQTYPE],
          obj[val][USERREQKEY]
        ]);
      } else if (
        obj[val]['0'] &&
        obj[val][USERTDIALOGLAST] &&
        obj[val][USERTDIALOGPAGE].match(regex)
      ) {
        raw.push([obj[val][USERTDIALOGPAGE], obj[val][USERTDIALOGLAST]]);
      }
    },
    { as: 0 }
  );
  return raw;
};
