import React, { useState, useRef } from 'react';
// Components
import Overview from './Overview/Overview';
import { Pageview } from './Pageview/Pageview';
import { User } from './User/User';
import Tabs from '@atlaskit/tabs';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { First } from './Onboarding/First';
import Button from '@atlaskit/button';
import EditorHelpIcon from '@atlaskit/icon/glyph/editor/help';
import { useRecoilState, useSetRecoilState } from 'recoil';
import FilterAtom from '../Recoil/Atoms/FilterAtom';
import ViewDataAtom from '../Recoil/Atoms/ViewDataAtom';
import { AppContentWrapper } from '../Styles/wrappers';
import { DrawerComponent } from './Onboarding/Drawer';
import { token } from '@atlaskit/tokens';

export const Home = () => {
  const [filterData] = useRecoilState(FilterAtom);
  const setViewData = useSetRecoilState(ViewDataAtom);
  const firstLoadFlag = useRef(true);

  const Contentvalue = filterData.content_value;
  const Displayvalue = filterData.display_value;
  const Groupbyvalue = filterData.group_by_value;

  const [tabIndex, setTabIndex] = useState(0);

  //tabs creation
  const tabs = [
    {
      label: (
        <SpotlightTarget name="green">
          <h5 style={{ marginBottom: '7px', color: token('color.text') }}>
            Overview
          </h5>
        </SpotlightTarget>
      ),
      heading: (
        <SpotlightTarget name="green">
          <h5 style={{ marginBottom: '7px', color: token('color.text') }}>
            Overview
          </h5>
        </SpotlightTarget>
      ),
      content: <Overview firstLoadFlag={firstLoadFlag} />,
      tooltip: 'Overview'
    },
    {
      label: (
        <SpotlightTarget name="yellow">
          <h5 style={{ color: token('color.text') }}>Pageviews</h5>
        </SpotlightTarget>
      ),
      heading: <h5 style={{ color: token('color.text') }}>Pageviews</h5>,
      content: (
        <Pageview
          Contentvalue={Contentvalue}
          Displayvalue={Displayvalue}
          Groupbyvalue={Groupbyvalue}
        />
      ),
      tooltip: 'Pageviews'
    },
    {
      label: (
        <SpotlightTarget name="red">
          <h5 style={{ color: token('color.text') }}>Users</h5>
        </SpotlightTarget>
      ),
      heading: <h5 style={{ color: token('color.text') }}>Users</h5>,
      content: <User />,
      tooltip: 'Users '
    }
  ];

  const openDrawer = () => {
    setViewData(prev => ({
      ...prev,
      drawer: true
    }));
  };

  async function onTabSelect(_, tabIndex) {
    await setViewData(prev => ({
      ...prev,
      tab_index: tabIndex,
      chart_flag: prev.chart_flag + 1
    }));
    setTabIndex(tabIndex);
  }

  return (
    <AppContentWrapper>
      <div className="header">
        <h1> Insights </h1>
        <div>
          <SpotlightTarget name="Help">
            <Button
              appearance="default"
              onClick={openDrawer}
              iconBefore={<EditorHelpIcon />}
            />
          </SpotlightTarget>
        </div>
      </div>
      <First />
      <div className="content">
        <Tabs tabs={tabs} selected={tabIndex} onSelect={onTabSelect} />
      </div>
      <DrawerComponent />
    </AppContentWrapper>
  );
};
