import React, { Suspense, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import { SpotlightManager, SpotlightTransition } from '@atlaskit/onboarding';
import { renderActiveSpotlight } from './Components/Onboarding/SpotCards';
import ErrorBoundary from './Components/ErrorHandling/ErrorBoundary';
import AppContent from './Components/App/AppContent';
import { useRecoilState } from 'recoil';
import ViewDataAtom from './Recoil/Atoms/ViewDataAtom';
import { useThemeObserver, setGlobalTheme } from '@atlaskit/tokens';
import { fecthTheme } from './API/dataViewApis';
const spinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};

function App() {
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const Onboarding = viewData.onboarding;

  const SpotcardCount = value => {
    if (value === 'drawer') {
      setViewData(prev => ({ ...prev, drawer: true, onboarding: value }));
    } else {
      setViewData(prev => ({ ...prev, onboarding: value }));
    }
  };

  useEffect(() => {
    const getTheme = async () => {
      const res = await fecthTheme();
    };
    getTheme();
  }, []);
  const theme = useThemeObserver();

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div style={spinnerStyle}>
            <Spinner size="large" />
          </div>
        }
      >
        <SpotlightManager>
          <AppContent />
          <SpotlightTransition>
            {renderActiveSpotlight(SpotcardCount, Onboarding)}
          </SpotlightTransition>
        </SpotlightManager>
      </Suspense>
    </ErrorBoundary>
  );
}
export default App;
