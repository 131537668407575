import styled from 'styled-components';
import { borderRadius, colors, gridSize, math, themed } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
export const ContentTabWrapper = styled.div`
  align-items: center;
  background-color: ${themed({ light: colors.N20, dark: colors.DN10 })};
  border-radius: ${borderRadius}px;
  color: ${colors.subtleText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 4em;
  font-weight: 500;
  justify-content: center;
  margin-bottom: ${gridSize}px;
  margin-top: ${math.multiply(gridSize, 2)}px;
  padding: ${math.multiply(gridSize, 4)}px;
`;

export const AppContentWrapper = styled.div`
  color: ${token('color.text')};
  padding: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  h1 {
    color: ${token('color.text')};
  }
  .content {
    margin-left: 5px;
  }
`;

export const FilterWrapper = styled.div`
  .Whole {
    display: flex;
    flex-direction: row;
    margin: 1px;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 7px;
  }
  .paragraph {
    align-self: center;
    color: ${token('color.text')};
    text-transform: uppercase;
    font-size: 12px;
  }
  .dropdown {
    margin: 5px;
    min-width: 200px;
    max-width: 150px;
    color: ${token('color.text')};
  }
  .components {
    margin: 5px;
    min-width: 50px;
    max-width: 150px;
    color: ${token('color.text')};
  }
  .datecomponents {
    margin: 5px;
    min-width: 118px;
    max-width: 150px;
  }
  .visible {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }
  .invisible {
    display: flex;
    flex-direction: row;
    display: none;
    flex: 1;
    padding-top: 5px;
  }
`;

export const AnalyzeWidgetWrapper = styled.div`
  .table-icon {
    background-image: url('/app/images/cells.svg');
    background-repeat: no-repeat;
    width: auto;
    height: 10px;
    display: block;
    margin: 8px 0 4px 3px;
    background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
  .chartPlus-tooltip {
    background: ${token('color.background.accent.gray.subtlest')} !important;
    color: ${token('color.text')} !important;
  }

  rect {
    fill: ${token('elevation.surface')};
  }
  .axis path {
    stroke: ${token('color.background.accent.gray.subtler')} !important;
  }
  .axis line {
    stroke: ${token('color.background.accent.gray.subtler')} !important;
  }
  text {
    fill: ${token('color.text')};
  }
`;

export const MainWrapper = styled.div`
  .ac-content {
    background: ${token('elevation.surface')};
    color: ${token('color.text')};
    min-height: 100vh;
    clear: both;
  }
`;

export const DialogBoxWrapper = styled.div`
  background: ${token('elevation.surface')};
  color: ${token('color.text')};
`;

export const SettingWrapper = styled.div`
  h3,
  p {
    color: ${token('color.text')};
  }
`;
