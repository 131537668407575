import { atom } from 'recoil';

const AccountAtom = atom({
  key: 'Account',
  default: {
    JWT: '',
    is_authenticated: false
  }
});

export default AccountAtom;
