import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '@atlaskit/css-reset';
import { RecoilRoot } from 'recoil';
import initDatadog from './Analytics/datadog';

initDatadog();

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,

  document.getElementById('root')
);
