import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Avatar from '@atlaskit/avatar';
import AvatarItem from '@atlaskit/avatar';
import { useRecoilState } from 'recoil';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import { token } from '@atlaskit/tokens';

export const MostActiveContibutor = props => {
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  // console.log('props', props);
  let rowarray = [];
  //creation of table syntax
  props.table_data.data.map((item, index) => {
    let id = item[props.table_data.userHeaderId];
    let cells = [
      {
        key: item['1'],
        content: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              appearance="circle"
              src={userDetails[id]?.imageURL}
              size="small"
              name={userDetails[id]?.displayName}
            />
            <span style={{ marginLeft: '8px' }}>
              {userDetails[id]?.displayName}
            </span>
          </div>
        ),
        isSortable: true
      }
    ];
    rowarray.push({ cells: cells, key: index });
    return 0;
  });

  //head properties
  const head = {
    cells: [
      {
        key: 'Name',
        content: 'Name',
        isSortable: true
      }
    ]
  };

  return (
    <div>
      <h6 style={{ color: token('color.text'), marginTop: '25px' }}>
        Most Active Contributors
      </h6>
      <DynamicTable
        head={head}
        rows={rowarray}
        isLoading={props.table_data.loading}
        emptyView={
          <h6 style={{ color: token('color.text') }}>
            No matching records found
          </h6>
        }
      />
    </div>
  );
};
