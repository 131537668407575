import React from 'react';
import Button from '@atlaskit/button';

class ErrorBoundary extends React.Component {
  //this component catches all the error when component catches.
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.log(`App crashed with error: ${error} ${errorInfo}`);
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '50px'
            }}
          >
            <div>
              <h3>Something went wrong.</h3>
              <Button style={{ margin: '20px' }}>Try Reloading the page</Button>
              <br />
              <Button style={{ margin: '20px' }}>Contact Support</Button>
            </div>
          </div>
          <details style={{ marginLeft: '300px' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
