import {
  ContentCode,
  GroupbyCode,
  DisplayCode,
  DaterangeCode
} from '../Functional/Strings';
// export const DashboardTransform = res => {
//   let widgetdata = res.widgetData;
//   console.log('dashboardresponse', res);
//   let ChartContent = widgetdata[1].viewPointData.data;
//   let PopularContent = widgetdata[2].viewPointData.data;
//   let ActiveReader = widgetdata[3].viewPointData.data;
//   let ActiveContri = widgetdata[4].viewPointData.data;
//   //let PageViews=widgetdata[5].viewPointData.data
//   //let User=widgetdata[6].viewPointData.data

//   return { ChartContent, PopularContent, ActiveContri, ActiveReader };
// };

// let fakechartdata = {
//   viewPointData: {
//     keys: ['2019-09-18', '2019-10-03'],
//     legends: ['created', 'viewed'],
//     data: [
//       { key: '2019-09-18', values: { viewed: '10.00666' } },
//       { key: '2019-09-20', values: { viewed: '5.00' } },
//       { key: '2019-09-23', values: { viewed: '1.00' } },
//       { key: '2019-09-24', values: { created: '1.00', viewed: '5.00' } },
//       { key: '2019-09-25', values: { viewed: '2.00' } },
//       { key: '2019-09-26', values: { viewed: '1.00' } },
//       { key: '2019-09-27', values: { viewed: '1.00' } },
//       { key: '2019-09-30', values: { viewed: '1.00' } },
//       { key: '2019-10-01', values: { viewed: '1.00' } }
//     ],
//     type: '',
//     yMagnitude: 'Issue Count'
//   }
// };

// const ChartplusConvert = data => {
//   console.log('alldata', data);
//   let Series = data.legends.map(legend => {
//     console.log('alllegends', legend);
//     let filtereddata = data.data.filter(filteritem => {
//       if (legend in filteritem.values) return filteritem;
//     });
//     let formatteddata = filtereddata.map(item => {
//       return { x: item.key, y: Math.trunc(item.values[legend]) };
//     });
//     console.log('allformatted', formatteddata);
//     console.log('allseriesdata', {
//       name: legend,
//       color: 'green',
//       data: formatteddata
//     });
//     return { name: legend, color: 'green', data: formatteddata };
//   });
//   console.log('allseries', { series: Series });

//   const syntax = {
//     container: 'container',
//     type: 'line',
//     percentage: true,
//     stack: true,
//     xAxis: {
//       type: 'date',
//       startDate: data.keys[0],
//       endDate: data.keys[1]
//     },
//     yAxis: {},
//     legend: {
//       align: 'right-top',
//       layout: 'horizontal'
//     },
//     series: Series
//   };

//   console.log('allSyntax', syntax);
// };

// export const OverviewTransform = res => {
//   let ChartContent = ChartplusConvert(fakechartdata);
//   let PopularContent = res[2].viewPointData.data;
//   let ActiveReader = res[3].viewPointData.data;
//   let ActiveContri = res[4].viewPointData.data;

//   return { ChartContent, PopularContent, ActiveContri, ActiveReader };
// };

// export const PageviewTransform = res => {
//   let PageviewTable = res[5].viewPointData.data;
//   return PageviewTable;
// };
// export const UserTransform = res => {
//   let UserTable = res[6].viewPointData.data;
//   return UserTable;
// };
// const ChartplusConvert = data => {
//   console.log('alldata', data);
//   let Series = data.legends.map(legend => {
//     console.log('alllegends', legend);
//     let filtereddata = data.data.filter(filteritem => {
//       if (legend in filteritem.values) return filteritem;
//     });
//     let formatteddata = filtereddata.map(item => {
//       return { x: item.key, y: item.values[legend] };
//     });
//     console.log('allformatted', formatteddata);
//     console.log('allseriesdata', {
//       name: legend,
//       color: 'green',
//       data: formatteddata
//     });
//     return { name: legend, color: 'green', data: formatteddata };
//   });
//   console.log('allseries', { series: Series });

//   const syntax = {
//     container: 'container',
//     type: 'line',
//     percentage: true,
//     stack: true,
//     xAxis: {
//       type: 'date',
//       startDate: data.keys[0],
//       endDate: data.keys[1]
//     },
//     yAxis: {},
//     legend: {
//       align: 'right-top',
//       layout: 'horizontal'
//     },
//     series: Series
//   };

//   console.log('allSyntax', syntax);
// };

// export const OverviewTransform = res => {
//   let ChartContent = ChartplusConvert(fakechartdata);
//   let PopularContent = res[2].viewPointData.data;
//   let ActiveReader = res[3].viewPointData.data;
//   let ActiveContri = res[4].viewPointData.data;

//   return { ChartContent, PopularContent, ActiveContri, ActiveReader };
// // };
// export const PageviewTransform = res => {
//   let PageviewTable = res[5].viewPointData.data;
//   return PageviewTable;
// };
// export const UserTransform = res => {
//   let UserTable = res[6].viewPointData.data;
//   return UserTable;
// };

export const FilterTransform = (res, value) => {
  if (value === 0) {
    let Chart = res[1].viewPointData;
    // console.log('chartdata', Chart);
    let PopularContent = { data: res[2].viewPointData.data, loading: false };

    let ActiveReaderId = '';
    res[3].viewPointData.header.forEach(val => {
      if (val.type === 'user') {
        ActiveReaderId = val.id;
      }
    });
    let ActiveReader = {
      data: res[3].viewPointData.data,
      userHeaderId: ActiveReaderId,
      loading: false
    };

    let ActiveContriId = '';
    res[4].viewPointData.header.forEach(val => {
      if (val.type === 'user') {
        ActiveContriId = val.id;
      }
    });
    let ActiveContri = {
      data: res[4].viewPointData.data,
      userHeaderId: ActiveContriId,
      loading: false
    };
    return { Chart, PopularContent, ActiveReader, ActiveContri };
  } else if (value === 1) {
    let pageviewTableId = '';
    res[5].viewPointData.header.forEach(val => {
      if (val.type === 'user') {
        pageviewTableId = val.id;
      }
    });
    let Table = res[5].viewPointData.data;
    return { Table, pageviewTableId };
  } else if (value === 2) {
    let UserTableId = '';
    res[6].viewPointData.header.forEach(val => {
      if (val.type === 'user') {
        UserTableId = val.id;
      }
    });
    let UserTable = res[6].viewPointData.data;
    return { UserTable, UserTableId };
  }
};
export const Filtervalues = data => {
  let startdate = data.selected[DaterangeCode].value[0];
  let enddate = data.selected[DaterangeCode].value[1];
  let GroupBy = data.selected[GroupbyCode].value[0];
  let Content = data.selected[ContentCode].value[0];
  let Display = data.selected[DisplayCode].value[0];
  // console.log('date', startdate);
  // console.log('date', enddate);
  // console.log('Groupby', GroupBy);
  // console.log('display', Display);
  // console.log('content', Content);
  return { startdate, enddate, Content, GroupBy, Display };
};

export const PageviewDialogtransform = res => {
  let PageDialogTable = res.data;
  // console.log(PageDialogTable);
  return PageDialogTable;
};
export const UserDialogtransform = res => {
  let UserDialogTable = res.data;
  // console.log(UserDialogTable);
  return UserDialogTable;
};
