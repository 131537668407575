import axiosInstance from './axiosInstance';

export async function applyViewFilter(viewId, reqBody, JWT) {
  try {
    if (process.env.NODE_ENV === 'development') {
      const response = await axiosInstance.get(
        `app/mock/applyfiltermock${viewId}.json`
      );
      return response;
    }
    const response = await axiosInstance.post(
      `/dashboard/pagefilter?viewID=${viewId}`,
      reqBody,
      {
        headers: {
          authorization: JWT
        }
      }
    );
    // const response2 = await axiosInstance.get(
    //   `/dashboard/pagefilter?viewID=${viewId}`,
    //   {
    //     headers: {
    //       authorization: JWT
    //     }
    //   }
    // );
    // console.log("response of get", response2)
    return response;
  } catch (err) {
    console.error(`Error while applying filters viewID:${viewId}`, err);
    return {
      data: {},
      headers: {}
    };
  }
}
export async function getViewFilter(viewId, JWT) {
  try {
    if (process.env.NODE_ENV === 'development') {
      const response = await axiosInstance.get(
        `/mock/applyfiltermock${viewId}.json`
      );
      return response;
    }
    const response = await axiosInstance.get(
      `/dashboard/pagefilter?viewID=${viewId}`,
      {
        headers: {
          authorization: JWT
        }
      }
    );
    // console.log('response of get', response);
    return response;
  } catch (err) {
    console.error(`Error while applying filters viewID:${viewId}`, err);
    return {
      data: {},
      headers: {}
    };
  }
}
export async function saveViewFilter(filters, JWT) {
  try {
    if (process.env.NODE_ENV === 'development') {
      console.log('fill the mock for later');
    }
    const response = await axiosInstance.post(
      `/dashboard/pagefilter/save`,
      { ...filters },
      {
        headers: {
          authorization: JWT
        }
      }
    );
    // console.log('response of get', response);
    return response;
  } catch (err) {
    console.error(`Error while saving filters`, err);
    return {
      data: {},
      headers: {}
    };
  }
}
