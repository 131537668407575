import React, {
  Fragment,
  useEffect,
  Suspense,
  useState,
  useCallback
} from 'react';
import {
  ContentCode,
  DisplayCode,
  DaterangeCode,
  GroupbyCode
} from '../../Functional/Strings';
import DynamicTable from '@atlaskit/dynamic-table';
import AccountAtom from '../../Recoil/Atoms/AccountAtom';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import { useRecoilState } from 'recoil';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import { FullDataviewExcelsheet } from '../../Functional/Excelsheetconverter';
import { fetchDataView } from '../../API/dataViewApis';
import Avatar from '@atlaskit/avatar';
// import AvatarItem from "@atlaskit/avatar/dist/cjs/components/AvatarItem";
import moment from 'moment';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/editor/search';
import Button from '@atlaskit/button';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import { getUserDetails } from '../ApCalls';

function FullDataView({ data }) {
  const [accountData, setAccountData] = useRecoilState(AccountAtom);
  const [filterData, setFilterData] = useRecoilState(FilterAtom);
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);

  const [searchkeyword, setSearchKeyword] = useState('');
  const [tableData, setTableData] = useState({
    loading: true,
    headers: [],
    rows: [],
    userHeaderId: '',
    users: []
  });

  const JWT = accountData.JWT;
  const StartDate = filterData.start_date;
  const EndDate = filterData.end_date;
  const Contentvalue = filterData.content_value;
  const Displayvalue = filterData.display_value;
  const Groupbyvalue = filterData.group_by_value;
  const General_page_flag = viewData.space_setting;
  const Spacevalues = filterData.space_value;
  const Sourcevalue = filterData.source_value;
  async function chartDrillDownDataFetch() {
    let response;
    let reqQuery = `id=1&rowID=${data.xValue}&columnID=${data.yValue}`;
    let reqpayload = General_page_flag
      ? {
          selected: {
            [DaterangeCode]: {
              label: 'Date Range',
              type: 'range',
              value: [StartDate, EndDate, 'Custom']
            },
            [ContentCode]: {
              label: 'Content',
              type: 'single',
              value: [Contentvalue]
            },
            [DisplayCode]: {
              label: 'Display',
              type: 'single',
              value: [Displayvalue]
            },
            [GroupbyCode]: {
              label: 'Group By',
              type: 'single',
              value: [Groupbyvalue]
            },
            ['-27']: {
              label: 'Spaces',
              type: 'multilist',
              value: [...Spacevalues]
            },

            '-29': {
              label: 'Source',
              type: 'single',
              value: [Sourcevalue]
            }
          }
        }
      : {
          selected: {
            [DaterangeCode]: {
              label: 'Date Range',
              type: 'range',
              value: [StartDate, EndDate, 'Custom']
            },
            [ContentCode]: {
              label: 'Content',
              type: 'single',
              value: [Contentvalue]
            },
            [DisplayCode]: {
              label: 'Display',
              type: 'single',
              value: [Displayvalue]
            },
            [GroupbyCode]: {
              label: 'Group By',
              type: 'single',
              value: [Groupbyvalue]
            },

            '-29': {
              label: 'Source',
              type: 'single',
              value: [Sourcevalue]
            }
          }
        };
    response = await fetchDataView(reqQuery, reqpayload, JWT);
    // console.log('chartdrilldown fetch in fulldataview useeffect', response);
    let id = '';
    response.data.header.forEach(head => {
      if (head.type === 'user') {
        id = head.id;
      }
    });

    let array = [];
    response.data.data.forEach(data => {
      let flag = true;
      array.forEach(e => {
        if (e === data[id]) {
          flag = false;
        }
      });
      if (flag) {
        array.push(data[id]);
      }
    });

    setTableData(prev => {
      return {
        ...prev,
        headers: [...response.data.header],
        rows: [...response.data.data],
        loading: false,
        userHeaderId: id,
        users: [...array]
      };
    });

    setAccountData(prev => ({
      ...prev,
      JWT: response.headers['authorization']
    }));
  }
  useEffect(() => {
    chartDrillDownDataFetch();
  }, []);

  useEffect(() => {
    tableData.users.forEach(user => {
      if (user !== '0' && Sourcevalue !== 'JSM Customer Portal') {
        fetchUserDetails(user);
      } else if (user === '0') {
        setUserDetails(prev => ({
          [user]: {
            imageURL: '',
            displayName: 'Anonymous'
          },
          ...prev
        }));
      }
    });
  }, [tableData.loading]);

  async function fetchUserDetails(userId) {
    const resp = await getUserDetails(userId);
    const baseURL = resp?._links?.base.slice(0, -5);
    const pathURL = resp?.profilePicture?.path;
    const imageURL = baseURL + pathURL;
    const displayName = resp?.displayName;
    // userDetails[userId] = imageURL
    setUserDetails(prev => ({
      [userId]: {
        imageURL: imageURL,
        displayName: displayName
      },
      ...prev
    }));
  }

  const TableRowFormatting = (heads, rs) => {
    let theaders = [];
    heads.forEach((elem, i) => {
      if (elem.id !== '2') {
        theaders.push({
          key: elem.id,
          content: elem.label,
          isSortable: true,
          setColor: false,
          type: 'string'
        });
      }
    });
    let trows = [];
    trows = rs.map((elem, i) => {
      let id = elem[tableData.userHeaderId];
      let cell = theaders.map((item, j) => {
        if (item.key === '3') {
          return {
            key: elem[item.key],
            content: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  appearance="circle"
                  src={userDetails[id]?.imageURL}
                  size="medium"
                  name={userDetails[id]?.displayName}
                />
                <span style={{ marginLeft: '8px' }}>
                  {userDetails[id]?.displayName}
                </span>
              </div>
            )
          };
        }
        if (item.key === '4') {
          return {
            key: elem[item.key]
              ? // ? moment(elem[item.key]).format('DD/MM/YYYY, h:mm:ss a')
                moment(elem[item.key]).fromNow()
              : '',
            content: elem[item.key] ? (
              <div className="tooltip">
                <div className="tooltippointer"></div>
                <div className="tooltiptext">
                  {moment(item[4]).format('DD/MM/YYYY')}
                </div>
                {moment(item[4]).fromNow()}
              </div>
            ) : (
              ''
            )
          };
        }
        return {
          key: elem[item.key],
          content: elem[item.key]
        };
      });
      return { cells: cell };
    });
    return { header: theaders, rows: trows };
  };
  const TableRowFormattingforDownload = (heads, rs) => {
    let theaders = [];
    heads.forEach((elem, i) => {
      if (elem.id !== '2') {
        theaders.push({
          key: elem.id,
          content: elem.label,
          isSortable: false,
          setColor: false,
          type: 'string'
        });
      }
    });
    let trows = [];
    // console.log('theader is', theaders);
    trows = rs.map((elem, i) => {
      let id = elem[tableData.userHeaderId];
      let displayName = userDetails[id]?.displayName;
      let cell = theaders.map((item, j) => {
        if (item.key === '3') {
          return {
            key: displayName,
            content: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  appearance="circle"
                  src={userDetails[id]?.imageURL}
                  size="medium"
                  name={userDetails[id]?.displayName}
                />
                <span style={{ marginLeft: '8px' }}>
                  {userDetails[id]?.displayName}
                </span>
              </div>
            )
          };
        }
        if (item.key === '4') {
          return {
            key: elem[item.key]
              ? moment(elem[item.key]).format('DD/MM/YYYY, h:mm:ss a')
              : '',
            content: elem[item.key]
              ? moment(elem[item.key]).format('DD/MM/YYYY, h:mm:ss a')
              : ''
          };
        }
        return {
          key: elem[item.key],
          content: elem[item.key]
        };
      });
      return { cells: cell };
    });
    return { header: theaders, rows: trows };
  };
  const Filterfunction = (obj, filterValue) => {
    let raw_array = [];
    let regex = new RegExp(filterValue, 'i');
    raw_array = [];
    // console.log('object is', obj);
    obj.forEach((item, val) => {
      if (
        item['0']?.match(regex) ||
        item?.['1'].match(regex) ||
        item?.['3'].match(regex)
      ) {
        raw_array.push(item);
      }
    });
    return raw_array;
  };
  let FilteredTablerows = Filterfunction(tableData.rows, searchkeyword);
  let formatteddata = TableRowFormatting(tableData.headers, FilteredTablerows);
  const clearSearch = () => setSearchKeyword('');

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '10px',
            alignItems: 'center'
          }}
        >
          <Textfield
            name="search"
            placeholder="Contains texts"
            width="xlarge"
            autoFocus={true}
            elemAfterInput={
              <div
                onClick={clearSearch}
                style={{
                  padding: '0 6px',
                  lineHeight: '100%',
                  cursor: 'pointer'
                }}
              >
                {searchkeyword.trim() && (
                  <CrossCircleIcon primaryColor="grey" />
                )}
              </div>
            }
            elemBeforeInput={
              <div style={{ paddingLeft: '6px', lineHeight: '100%' }}>
                <SearchIcon />
              </div>
            }
            value={searchkeyword}
            onChange={e => setSearchKeyword(e.target.value)}
          />
          <Button
            onClick={() => {
              let temp = TableRowFormattingforDownload(
                tableData.headers,
                FilteredTablerows
              );
              let formattedrows = temp.rows.map(elem => {
                let cell = elem.cells.map(item => {
                  return {
                    key: item.key,
                    content: item.key
                  };
                });
                return { cells: cell };
              });
              FullDataviewExcelsheet(
                formattedrows,
                formatteddata.header,
                'TimeLine'
              );
            }}
            appearance="default"
            iconBefore={<ArrowDownIcon />}
          ></Button>
        </div>
        <DynamicTable
          head={{ cells: formatteddata.header }}
          rows={formatteddata.rows}
          rowsPerPage={10}
          isLoading={tableData.loading}
          emptyView={<h6>No matching records found</h6>}
        />
      </div>
    </>
  );
}

export default FullDataView;
