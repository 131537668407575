export async function getIframeBaseUrl() {
  return new Promise(resolve => {
    try {
      window.AP?.getLocation(location => {
        if (location) {
          resolve(new URL(location));
        } else {
          resolve();
        }
      });
    } catch (err) {
      console.error('Error while checking base url', { err });
      resolve();
    }
  });
}
