import { saveAs } from 'file-saver';
import moment from 'moment';

//pageview table excel file creation
export const pageview_xlsx_array_create = async (raw, userDetails) => {
  ////////////Don't delete the below comment
  //don't remove the below comment section, its for webpack to enable prefetch of excel.js chunk///////////
  const Excel = await import(
    /* webpackPrefetch: true */ 'exceljs/dist/exceljs.bare.min.js'
  );
  ///////////////////
  let xlsxarray = [];

  xlsxarray = raw.map(item => {
    let id = item[1];
    const displayName = userDetails[id]?.displayName;
    return {
      Title: item[0],
      Creator: displayName,
      Created: item[2] ? moment(item[2]).format('DD/MM/YYYY, h:mm:ss a') : '',
      Last_Modified: item[3]
        ? moment(item[3]).format('DD/MM/YYYY, h:mm:ss a')
        : '',
      Last_Viewed: item[4]
        ? moment(item[4]).format('DD/MM/YYYY, h:mm:ss a')
        : '',
      User_Viewed: item[5],
      Total_Viewed: item[6]
    };
  });

  var workbook = new Excel.Workbook();
  workbook.Creator = 'Insights';
  workbook.properties.date1904 = true;
  var sheet = workbook.addWorksheet('Pageview');

  sheet.columns = [
    { header: 'Title', key: 'Title', width: 10 },
    { header: 'Creator', key: 'Creator', width: 32 },
    { header: 'Created', key: 'Created', width: 32 },
    { header: 'Last_Modified', key: 'Last_Modified', width: 32 },
    { header: 'Last_Viewed', key: 'Last_Viewed', width: 32 },
    { header: 'User_Viewed', key: 'User_Viewed', width: 32 },
    { header: 'Total_Viewed', key: 'Total_Viewed', width: 32 }
  ];

  sheet.addRows(xlsxarray);

  workbook.xlsx.writeBuffer().then(buf => {
    saveAs(new Blob([buf]), `Pageview.xlsx`);
    // other stuffs
  });
};

//user table excel file creation
export const user_xlsx_array_create = async (raw, userDetails) => {
  ////////////Don't delete the below comment
  //don't remove the below comment section, its for webpack to enable prefetch of excel.js chunk/////////////
  const Excel = await import(
    /* webpackPrefetch: true */ 'exceljs/dist/exceljs.bare.min.js'
  );
  ////////////
  let xlsxarray = [];

  xlsxarray = raw.map(item => {
    let id = item[0];
    const displayName = userDetails[id]?.displayName;
    return {
      Name: displayName,
      Created: item[3],
      Updated: item[4],
      Viewed: item[5]
    };
  });

  var workbook = new Excel.Workbook();
  workbook.Creator = 'Insights';
  workbook.properties.date1904 = true;
  var sheet = workbook.addWorksheet('Pageview');

  sheet.columns = [
    { header: 'Name', key: 'Name', width: 10 },
    { header: 'Created', key: 'Created', width: 32 },
    { header: 'Updated', key: 'Updated', width: 32 },
    { header: 'Viewed', key: 'Viewed', width: 32 }
  ];

  sheet.addRows(xlsxarray);

  workbook.xlsx.writeBuffer().then(buf => {
    saveAs(new Blob([buf]), `Pageview.xlsx`);
    // other stuffs
  });
};

//dialog box excel file creation
export const dialog_xlsx_array_create = async (
  raw,
  head,
  headervalue,
  userDetails,
  userHeaderId
) => {
  ////////////Don't delete the below comment
  //don't remove the below comment section, its for webpack to enable prefetch of excel.js chunk/////////////
  const Excel = await import(
    /* webpackPrefetch: true */ 'exceljs/dist/exceljs.bare.min.js'
  );
  ///////////////////
  let xlsxarray = [];
  var workbook = new Excel.Workbook();
  workbook.Creator = 'Insights';
  workbook.properties.date1904 = true;
  var sheet = workbook.addWorksheet('Pageview');
  if (head === 1) {
    sheet.columns = [
      { header: 'User', key: 'User', width: 10 },
      { header: headervalue, key: headervalue, width: 32 }
    ];
    // console.log('raw', raw);
    xlsxarray = raw.map(item => {
      let id = item[userHeaderId];
      const displayName = userDetails[id]?.displayName;
      return {
        User: displayName,
        [headervalue]: moment(item[2]).format('DD/MM/YYYY, h:mm:ss a')
      };
    });
  } else {
    sheet.columns = [
      { header: 'Pages', key: 'Pages', width: 10 },
      { header: headervalue, key: headervalue, width: 32 }
    ];
    // console.log('raw', raw);
    xlsxarray = raw.map(item => {
      return {
        Pages: item[0],
        [headervalue]: moment(item[1]).format('DD/MM/YYYY, h:mm:ss a')
      };
    });
  }
  sheet.addRows(xlsxarray);
  workbook.xlsx.writeBuffer().then(buf => {
    saveAs(new Blob([buf]), `Pageview.xlsx`);
    // other stuffs
  });
  /*let ws;
  let xlsxarray = [];
  if (head === 1) {
    ws = XLSX.utils.json_to_sheet(
      [
        {
          Users: 'Users',
          Viewed: 'Viewed'
        }
      ],
      {
        header: ['Users', 'Viewed'],
        skipHeader: true
      }
    );
    //creating the xlxs array from raw in the format required
    xlsxarray = raw.map(item => {
      return {
        Users: item[1],
        Viewed: item[2]
      };
    });
    console.log('xlxarray', xlsxarray);
    //Creating the Worksheet
    XLSX.utils.sheet_add_json(ws, xlsxarray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    //saving the file
    XLSX.writeFile(wb, 'Analytics.xlsx');
  } else {
    ws = XLSX.utils.json_to_sheet(
      [
        {
          Pages: 'Pages',
          LastViewed: 'LastViewed'
        }
      ],
      {
        header: ['Pages', 'LastViewed'],
        skipHeader: true
      }
    );
    //creating the xlxs array from raw in the format required
    xlsxarray = raw.map(item => {
      return {
        Pages: item[0],
        LastViewed: item[1]
      };
    });
    console.log('xlxarray', xlsxarray);
    //Creating the Worksheet
    XLSX.utils.sheet_add_json(ws, xlsxarray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    //saving the file
    XLSX.writeFile(wb, 'Analytics.xlsx');
  }*/
  //Setting the header
};
