import React from 'react';
import Drawer from '@atlaskit/drawer';
import Button from '@atlaskit/button';
import LightbulbFilledIcon from '@atlaskit/icon/glyph/lightbulb-filled';
import DocumentFilledIcon from '@atlaskit/icon/glyph/document-filled';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';

export const DrawerComponent = () => {
  //right side drawer component
  //redux flag
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const DrawerFlag = viewData.drawer;

  return (
    <Drawer
      isOpen={DrawerFlag}
      onClose={() => setViewData(prev => ({ ...prev, drawer: false }))}
      onCloseComplete={() => setViewData(prev => ({ ...prev, drawer: false }))}
      width="narrow"
    >
      <div style={{ marginTop: '50px' }}></div>
      <img
        src="/app/favicon.svg"
        style={{ width: '20px', height: '20px' }}
        alt="View26 logo"
      />
      <h2>Insights</h2>
      <p>Version : {process.env.REACT_APP_VERSION}</p>
      <div style={{ marginTop: '50px' }}></div>
      <Button
        style={{ marginTop: '20px', width: '250px' }}
        appearance="default"
        onClick={() =>
          setViewData(prev => ({ ...prev, onboarding: 1, drawer: false }))
        }
        iconBefore={<LightbulbFilledIcon />}
      >
        Getting Started
      </Button>
      <Button
        style={{ marginTop: '20px', width: '250px' }}
        appearance="default"
        iconBefore={<DocumentFilledIcon />}
        onClick={() => window.open('https://help.view26.com/pifc/', '_blank')}
      >
        Documentation
      </Button>
      <div style={{ paddingTop: '20px' }}></div>
      <Button
        style={{ marginTop: '20px', width: '250px' }}
        appearance="primary"
        onClick={() =>
          window.open(
            'https://view26.atlassian.net/servicedesk/customer/portal/2',
            '_blank'
          )
        }
        iconBefore={<PeopleIcon />}
      >
        Contact Us
      </Button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px',
          alignContent: 'flex-end'
        }}
      >
        <img
          alt="view"
          src="/app/view.svg"
          style={{ alignContent: 'flex-end' }}
        />
      </div>
    </Drawer>
  );
};
