import { saveAs } from 'file-saver';
import moment from 'moment';

export async function FullDataviewExcelsheet(
  rows,
  header,
  widgetName,
  callback = () => {}
) {
  //creating an Excel Workbook
  import('exceljs/dist/exceljs.bare.min.js' /*webpackPrefetch: true */).then(
    exceljs => {
      const workbook = new exceljs.Workbook();
      workbook.Creator = 'Insights';
      const worksheet = workbook.addWorksheet('Full Data view');

      let columns = header.map(item => {
        return { header: item.content, key: item.key, width: 30.52 };
      });

      //adding Excel columns
      worksheet.columns = columns;

      for (const item in rows) {
        let pass = rows[item].cells.map(inneritem => {
          return inneritem.content;
        });
        worksheet.addRow(pass); //adding rows to excel sheet
      }

      workbook.xlsx.writeBuffer().then(buf => {
        saveAs(
          new Blob([buf]),
          `${widgetName.replace(/ /g, '-')}_${moment().format(
            'MMM-DD-YYYY_hh-mm-A'
          )}.xlsx`
        );
      }); //downloading the file
      callback();
    }
  );
}
